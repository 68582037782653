import React from "react";
import { Header } from "../components/UI";
import { CartList } from "../components/Cart/CartList";
import { WithCommonTranslations, WithUser } from "../components/hocs/";
import { TranslationMixin } from "tds_shared_ui";
import _ from "lodash";

export const ShoppingCartPage = WithUser(
  WithCommonTranslations(
    class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);
        this.state = {
          cart: [],
          loading: "",
          alert: {
            display: false,
            type: "default",
            message: "",
          },
        };
        this.handleEmpty = this.handleEmpty.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
      }

      componentDidMount() {
        if (this.props.cart) {
          this.setupCart(this.props.cart.slice());
        }
      }

      componentDidUpdate(prevProps) {
        const prevCart = prevProps.cart;
        const { cart } = this.props;
        if ((!prevCart || prevCart.length === 0) && cart && cart.length > 0) {
          this.setupCart(cart);
        }
      }

      setupCart(cart) {
        let cloneCart = _.cloneDeep(cart);
        this.setState({
          cart: cloneCart,
        });
      }

      handleRemove(skuid) {
        return this.props
          .removeFromCart(skuid)
          .then((cart) => this.setupCart(cart)).catch((e) => {
            //Adding empty catch to prevent webpack unhandled exception msg
          });
      }

      handleSave(item) {
        this.props.updateCart(item);
        return this.props.saveCart().then((data) => this.setupCart(data)).catch((e) => {
          //Adding empty catch to prevent webpack unhandled exception msg
        });
      }

      handleEmpty() {
        return this.props.emptyCart().then((cart) => {
          this.setupCart(cart);
        }).catch((e) => {
          //Adding empty catch to prevent webpack unhandled exception msg
        });
      }

      handleUpdate(updateItem) {
        let newCart = this.state.cart.slice();
        for (let i = 0; i < newCart.length; i++) {
          let item = newCart[i];

          if (item.skuid === updateItem.skuid) {
            item.quantity = updateItem.quantity;
            break;
          }
        }
        this.setState({
          cart: newCart,
        });
      }

      render() {
        return (
          <div className="shopping-cart-page">
            <Header level={1}>{this.getTranslation("HEADER_CART")}</Header>
            <Header level={3} className="itemHeader">
              {this.getTranslation("HEADER_CART_SUMMARY")}
            </Header>
            <CartList
              items={this.state.cart}
              locale={this.props.locale}
              onEmptyCart={this.handleEmpty}
              onRemove={this.handleRemove}
              onUpdate={this.handleUpdate}
              onSave={this.handleSave}
              readOnly={false}
            />
          </div>
        );
      }
    }
  )
);
