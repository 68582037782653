import React from "react";

import { Header } from "../UI";
import {
  WithKeyCodeTranslations,
  WithCommonTranslations,
  WithRegion,
  WithUser,
} from "../hocs/";
import { HelperUtils, KeycodesService } from "../../services";
import { TranslationMixin } from "tds_shared_ui";
import { AlertMixin } from "tds_shared_ui";
import { VehicleInformation } from "./VehicleInformation";
import { BillingAndPayment } from "./BillingAndPayment";
import { ActivateKeycode } from "./ActivateKeycode";
import { Confirmation } from "./Confirmation";

import "./PurchaseKeycodesWizard.css";

const steps = [
  { name: "HEADER_VEHICLE_INFORMATION", component: VehicleInformation },
  { name: "HEADER_BILLING_AND_PAYMENT", component: BillingAndPayment },
  { name: "HEADER_PLACE_ORDER", component: Confirmation },
  { name: "HEADER_ACTIVATE_KEYCODE", component: ActivateKeycode },
];

export const PurchaseKeycodesWizard = WithUser(
  WithRegion(
    WithCommonTranslations(
      WithKeyCodeTranslations(
        class extends AlertMixin(TranslationMixin(React.Component)) {
          constructor(props) {
            super(props);

            this.stepRef = React.createRef();
            this.originalCheckoutSummary = null;

            this.state = {
              currentStep: 0,
              states: [],
              countries: [],
              paymentInfo: null,
              checkoutSummary: null,
              orderConfirmation: null,
              formValues: {
                vin: "",
                vehicleOwnerName: "",
                licensePlate: "",
                city: "",
                stateRegistration: "",
                country: "",
                promoCode: "",
                billingAddressLine1: "",
                billingFirstName: "",
                billingLastName: "",
                billingZipCode: "",
                billingCity: "",
                billingState: "",
                billingCountry: "",
                billingPhone: "",
              },
            };

            this.handleStepSubmit = this.handleStepSubmit.bind(this);
            this.handleStepBack = this.handleStepBack.bind(this);
            this.setPaymentInfo = this.setPaymentInfo.bind(this);
            this.prepareCheckout = this.prepareCheckout.bind(this);
            this.placeOrder = this.placeOrder.bind(this);
          }

          componentDidMount() {
            this.prefillUser(this.props.user);
            this.getSummary();
          }

          prefillUser(user) {
            if (user) {
              let { address } = user;
              if (address == null) {
                address = {};
              }

              this.setState((prevState) => {
                return {
                  formValues: {
                    ...prevState.formValues,

                    country: address.country || "",
                    billingFirstName: user.firstName || "",
                    billingLastName: user.lastName || "",
                    billingAddressLine1: address.addressLine1 || "",
                    billingCity: address.city || "",
                    billingState: address.state || "",
                    billingCountry: address.country || "",
                    billingZipCode: address.zipCode || "",
                    billingPhone: user.phone || "",
                  },
                };
              });
            }
          }

          getSummary() {
            const me = this;
            KeycodesService.getSummary().then((data) => {
              me.setState({
                checkoutSummary: data,
              });
              this.originalCheckoutSummary = data;
            }).catch((error) => {
              //Adding empty catch to prevent webpack unhandled exception msg
            });
          }

          prepareCheckout() {
            return new Promise((resolve, reject) => {
              let form = this.state.formValues;
              let checkoutData = {
                billingAddress: {
                  addressLine1: form.billingAddressLine1,
                  zipCode: form.billingZipCode,
                  city: form.billingCity,
                  state: form.billingState,
                  country: form.billingCountry,
                },
                currencyCode: this.state.checkoutSummary.currencyCode,
                billingFirstName: form.billingFirstName,
                billingLastName: form.billingLastName,
              };

              if (form.promoCode) {
                checkoutData.promoCodes = [
                  {
                    promoCode: form.promoCode.toUpperCase(),
                  },
                ];
              }
              KeycodesService.prepareCheckout(checkoutData)
                .then((data) => {
                  this.setState(
                    {
                      checkoutSummary: data,
                    },
                    resolve(data)
                  );
                })
                .catch((error) => {
                  //reset summary in case bad promo code or something else
                  this.setState(
                    {
                      checkoutSummary: this.originalCheckoutSummary,
                    },
                    reject(error)
                  );
                });
            });
          }

          placeOrder(passcode, captchaData, paymentInfo) {
            const { formValues, checkoutSummary } = this.state;

            let order = Object.assign({}, checkoutSummary, captchaData);

            order.vin = formValues.vin;
            order.vehicleOwnerName = formValues.vehicleOwnerName;
            order.licensePlate = formValues.licensePlate;
            order.city = formValues.city;
            order.stateRegistration = formValues.stateRegistration;
            order.country = formValues.country;
            order.billingFirstName = formValues.billingFirstName;
            order.billingLastName = formValues.billingLastName;
            order.billingPhone = formValues.billingPhone;
            order.sessionId = 0;
            order.passcode = passcode;
            // Get the users time zone and pass it as a part of the order object
            order.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (formValues.promoCode) {
              order.promoCodes = [
                {
                  promoCode: formValues.promoCode,
                },
              ];
            }

            if (
              checkoutSummary.ccRequired &&
              paymentInfo &&
              paymentInfo.sessionId
            ) {
              order.sessionId = paymentInfo.sessionId;
            }

            return KeycodesService.purchase(order).then((data) => {
              this.setState({ orderConfirmation: data });
            });
          }

          setPaymentInfo(paymentData) {
            this.setState({
              paymentInfo: paymentData,
            });
          }

          handleStepSubmit() {
            this.setState((prevState) => {
              return { currentStep: prevState.currentStep + 1 };
            }, this.scrollToStep);
          }

          handleStepBack() {
            this.setState((prevState) => {
              return {
                alert: {
                  display: false,
                },
                currentStep: prevState.currentStep - 1,
              };
            });
          }

          handleChange = (name, value, callback) => {
            this.setState((prevState) => {
              let newState = {};
              let formValues = Object.assign({}, prevState.formValues);
              formValues[name] = value;
              newState.formValues = formValues;
              if (
                name === "country" &&
                prevState.formValues.country !== value
              ) {
                //reset state if country is changed
                newState.formValues.stateRegistration = "";
              } else if (
                name === "billingCountry" &&
                prevState.formValues.billingCountry !== value
              ) {
                //reset billing state if billing country is changed
                newState.formValues.billingState = "";
              }
              return newState;
            }, callback);
          };

          scrollToStep() {
            HelperUtils.scrollToDomRef(this.stepRef);
          }

          scrollToAlert() {
            HelperUtils.scrollToAlert(this.alertRef);
          }

          render() {
            const StepComponent = steps[this.state.currentStep].component;
            return (
              <div className="purchaseKeycodesWizard">
                <Header level={1}>
                  {this.getTranslation("HEADER_PURCHASE_KEYCODES")}
                </Header>
                <div className="wizard-progress" ref={this.stepRef}>
                  {steps.map((step, i) => (
                    <span key={i} className="progressWrapper">
                      {i >= this.state.currentStep && (
                        <span
                          className={
                            "fa-layers fa-fw fa-2x " +
                            (this.state.currentStep === i ? "active" : "")
                          }
                        >
                          <i className="fas fa-circle"></i>
                          <span className="fa-layers-text fa-inverse">
                            {i + 1}
                          </span>
                        </span>
                      )}
                      {i < this.state.currentStep && (
                        <i className="fas fa-check-circle fa-fw fa-2x"></i>
                      )}

                      <span className="stepName">
                        {this.getTranslation(step.name)}
                      </span>

                      {i !== steps.length - 1 && (
                        <span className="shortLine"></span>
                      )}
                    </span>
                  ))}
                </div>
                <div>
                  <StepComponent
                    formValues={this.state.formValues}
                    countryStateMap={this.props.countryStateMap}
                    countries={this.props.countries}
                    paymentInfo={this.state.paymentInfo}
                    setPaymentInfo={this.setPaymentInfo}
                    checkoutSummary={this.state.checkoutSummary}
                    orderConfirmation={this.state.orderConfirmation}
                    onPrepareCheckout={this.prepareCheckout}
                    onPlaceOrder={this.placeOrder}
                    onChange={this.handleChange}
                    onSubmit={this.handleStepSubmit}
                    onBack={this.handleStepBack}
                    onCancel={this.props.onCancel}
                  />
                </div>
              </div>
            );
          }
        }
      )
    )
  )
);
