import React from "react";
import ReactDOM from "react-dom";

import {
  Header,
  Button,
  FormInput,
  Select,
  LoadingIcon,
  OrderTotal,
} from "../UI";
import {
  WithRegion,
  WithFormValidation,
  WithKeyCodeTranslations,
  WithCommonTranslations,
} from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import { Validators } from "tds_shared_ui";

import "./BillingAndPaymentForm.css";

export const BillingAndPaymentForm = WithRegion(
  WithFormValidation(
    WithCommonTranslations(
      WithKeyCodeTranslations(
        class extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);

            this.promoRef = React.createRef();

            this.state = {
              loadingPromo: false,
              promoError: "",
              states: [],
            };

            this.applyPromo = this.applyPromo.bind(this);
            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleSelectChange = this.handleSelectChange.bind(this);
            this.onlyNumbersOnKeyPress =
              Validators.onlyNumbersOnKeyPress.bind(this);
          }

          static getDerivedStateFromProps(props) {
            let country = props.formValues
              ? props.formValues.billingCountry
              : null;
            let states = [];

            if (country && props.countryStateMap) {
              states = props.countryStateMap[country];
            }

            return {
              states: states,
            };
          }

          handleSelectChange = (name) => (value) => {
            this.props.onChange(name, value);
          };

          handleInputChange = (event) => {
            const target = event.target;
            const value =
              target.type === "checkbox" ? target.checked : target.value;
            const name = target.name;

            this.props.onChange(name, value);
          };

          applyPromo() {
            this.setState({
              loadingPromo: true,
            });
            this.props.onApplyPromoCode().finally(() => {
              this.setState({
                loadingPromo: false,
              });
            });
          }

          getInput(ref) {
            const dom = ReactDOM.findDOMNode(ref.current);
            return dom.querySelector("input");
          }

          render() {
            let stateReq = false;
            if (this.props.regionData) {
              let { regionConfigs } = this.props.regionData;
              stateReq = regionConfigs.stateRequired === "true";
            }

            return (
              <div className="billing-form">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-5">
                      <div className="border-bottom mb-3">
                        <div className="row">
                          <Header level={2} className="col-8">
                            {this.getTranslation("HEADER_BILLING_AND_PAYMENT")}
                          </Header>
                        </div>
                      </div>

                      <div className="form-inputs">
                        {/*Billing and Payment*/}
                        <FormInput
                          label={this.getTranslation("LABEL_FIRST_NAME")}
                          name="billingFirstName"
                          id="billingFirstName"
                          maxLength={100}
                          required={true}
                          displayError={this.props.isValidated}
                          data-pattern-message={this.getTranslation(
                            "MESSAGE_INVALID_FIRSTNAME"
                          )}
                          pattern="^[A-Za-zÀ-ÿ\-' ]{0,100}"
                          autoComplete="off"
                          value={this.props.formValues.billingFirstName}
                          onChange={this.handleInputChange}
                        />

                        <FormInput
                          label={this.getTranslation("LABEL_LAST_NAME")}
                          name="billingLastName"
                          id="billingLastName"
                          maxLength={100}
                          required={true}
                          displayError={this.props.isValidated}
                          data-pattern-message={this.getTranslation(
                            "MESSAGE_INVALID_LASTNAME"
                          )}
                          pattern="^[A-Za-zÀ-ÿ\-' ]{0,100}"
                          autoComplete="off"
                          value={this.props.formValues.billingLastName}
                          onChange={this.handleInputChange}
                        />

                        <FormInput
                          label={this.getTranslation("LABEL_ADDRESS1")}
                          name="billingAddressLine1"
                          id="billingAddressLine1"
                          maxLength={100}
                          required={true}
                          displayError={this.props.isValidated}
                          data-pattern-message={this.getTranslation(
                            "MESSAGE_INVALID_ADDRESS1"
                          )}
                          autoComplete="off"
                          value={this.props.formValues.billingAddressLine1}
                          onChange={this.handleInputChange}
                        />

                        <FormInput
                          label={this.getTranslation("LABEL_CITY")}
                          name="billingCity"
                          id="billingCity"
                          maxLength={20}
                          required={true}
                          displayError={this.props.isValidated}
                          data-pattern-message={this.getTranslation(
                            "MESSAGE_INVALID_CITY"
                          )}
                          autoComplete="off"
                          value={this.props.formValues.billingCity}
                          onChange={this.handleInputChange}
                        />

                        <Select
                          label={this.getTranslation("LABEL_STATE")}
                          name="billingState"
                          id="billingState"
                          autoComplete="state"
                          required={stateReq}
                          options={this.state.states}
                          value={this.props.formValues.billingState}
                          readOnly={this.props.readOnly}
                          error={
                            this.props.isValidated &&
                            !this.props.formValues.billingState
                          }
                          errorMessage={this.getTranslation("ERROR_REQUIRED")}
                          onChange={this.handleSelectChange("billingState")}
                        />

                        <Select
                          label={this.getTranslation("LABEL_COUNTRY")}
                          name="billingCountry"
                          id="billingCountry"
                          autoComplete="country"
                          required={true}
                          options={this.props.countries}
                          value={this.props.formValues.billingCountry}
                          readOnly={this.props.readOnly}
                          error={
                            this.props.isValidated &&
                            !this.props.formValues.billingCountry
                          }
                          errorMessage={this.getTranslation("ERROR_REQUIRED")}
                          onChange={this.handleSelectChange("billingCountry")}
                        />

                        <FormInput
                          label={this.getTranslation("LABEL_ZIPCODE")}
                          name="billingZipCode"
                          id="billingZipCode"
                          maxLength={20}
                          required={true}
                          displayError={this.props.isValidated}
                          autoComplete="off"
                          value={this.props.formValues.billingZipCode}
                          onChange={this.handleInputChange}
                        />

                        <FormInput
                          label={this.getTranslation("LABEL_PHONE")}
                          name="billingPhone"
                          id="billingPhone"
                          maxLength={15}
                          required={false}
                          onKeyPress={this.onlyNumbersOnKeyPress}
                          displayError={this.props.isValidated}
                          autoComplete="off"
                          pattern="\d+"
                          value={this.props.formValues.billingPhone}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>

                    {/*Promo Code*/}
                    <div className="mb-5">
                      <div className="border-bottom mb-3">
                        <Header level={2}>
                          {this.getTranslation("HEADER_PROMO_CODE")}
                        </Header>
                      </div>

                      <div className="d-flex flex-row">
                        <div className="flex-grow-1">
                          <FormInput
                            label={this.getTranslation("LABEL_PROMO_CODE")}
                            name="promoCode"
                            id="promoCode"
                            ref={this.promoRef}
                            className="mr-4"
                            maxLength={20}
                            displayError={true}
                            customError={this.props.promoError}
                            value={this.props.formValues.promoCode.toUpperCase()}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="mt-2">
                          <Button
                            type="button"
                            variant="secondary"
                            className="float-right"
                            disabled={
                              this.state.loadingPromo || this.props.loading
                            }
                            onClick={this.applyPromo}
                          >
                            <LoadingIcon display={this.state.loadingPromo} />
                            {this.getTranslation("BUTTON_APPLY")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    {this.props.checkoutSummary && (
                      <OrderTotal
                        className="order-summary sticky-top"
                        checkoutSummary={this.props.checkoutSummary}
                      />
                    )}
                  </div>
                </div>

                <div>
                  <Button
                    variant="outline-secondary"
                    type="button"
                    onClick={this.props.onCancel}
                  >
                    {this.getTranslation("BUTTON_CANCEL")}
                  </Button>

                  <div className="float-right">
                    <Button
                      variant="link"
                      type="button"
                      onClick={this.props.onBack}
                    >
                      {this.getTranslation("BUTTON_BACK")}
                    </Button>

                    <Button
                      variant="primary"
                      type="submit"
                      disabled={this.props.loading || this.state.loadingPromo}
                    >
                      <LoadingIcon display={this.props.loading} />
                      {this.getTranslation("BUTTON_NEXT")}
                    </Button>
                  </div>
                </div>
              </div>
            );
          }
        }
      )
    )
  )
);
