import React from "react";

import { TranslationService } from "../services";

const Context = React.createContext();

export class ContactTranslationsProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactTranslations: null,
    };
  }

  componentDidMount() {
    this.fetchTranslations(this.props.locale);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.fetchTranslations(this.props.locale);
    }
  }

  fetchTranslations(locale) {
    TranslationService.getBundle("contact", locale).then((data) => {
      this.setState({ contactTranslations: data });
    }).catch((e) => {
      //Adding empty catch to prevent webpack unhandled exception msg
    });
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const ContactTranslationsConsumer = Context.Consumer;
