import React from "react";
import { AlertMixin, TranslationMixin } from "tds_shared_ui";
import { WithCommonTranslations, WithRegion } from "../components/hocs";
import { TermsService } from "../services";

export const HelpPage = WithRegion(
  WithCommonTranslations(
    class HelpPage extends AlertMixin(TranslationMixin(React.Component)) {
      constructor(props) {
        super(props);

        this.state = {
          faqData: null,
        };
      }

      componentDidMount() {
        this.handleStaticContent();

        document
          .getElementById("helpPage")
          .addEventListener("click", this.handleClicks);
      }

      componentDidUpdate(prevProps) {
        if (prevProps.locale !== this.props.locale) {
          this.handleStaticContent();
        }
      }

      componentWillUnmount() {
        document
          .getElementById("helpPage")
          .removeEventListener("click", this.handleClicks);
      }

      //React router is preventing hash links from working
      //catch the onclick for the links and scroll to them manually
      handleClicks(e) {
        let node = e.target;
        let href = node.href;
        if (href) {
          if (href.indexOf("#") > -1) {
            e.preventDefault();
            let id = href.substring(href.indexOf("#") + 1);
            let elem = document.getElementById(id);
            if (elem) {
              elem.scrollIntoView();
            }
          }
        } else {
        }
      }

      handleStaticContent() {
        TermsService.getTerms("tdsfaq", this.props.locale).then((data) => {
          this.setState({
            faqData: data.content,
          });
        }).catch((e) => {
          //Adding empty catch to prevent webpack unhandled exception msg
        });
      }

      render() {
        return (
          <div id="helpPage">
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.faqData,
              }}
            ></div>
          </div>
        );
      }
    }
  )
);
