import React from 'react';

import {NimbusSelect} from "tds_shared_ui";
import './Select.css';

export class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autofill: '',
      selectedValues: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleRemoveAll = this.handleRemoveAll.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let values = prevState.selectedValues.map((elem) => {
      return elem.value;
    });
    if (nextProps.multi && nextProps.options) {
      if (nextProps.value !== values) {
        return {
          selectedValues: nextProps.options.filter(
              (elem) => nextProps.value.includes(elem.value)),
          autofill: '',
        };
      }
    } else if (nextProps.value && nextProps.options) {
      if (values.length === 0 || values[0] !== nextProps.value) {
        return {
          selectedValues: nextProps.options.filter(
              (elem) => nextProps.value === elem.value),
          autofill: '',
        };
      }
    } else if (values.length > 0) {
      return {selectedValues: [], autofill: ''};
    }
    return null;
  }

  handleChange(event, option) {
    let selectedValues = this.props.multi ?
        [...this.state.selectedValues, option] :
        [{...option}];

    this.triggerOnChange(selectedValues);
  }

  handleRemove(option) {
    this.triggerOnChange(this.state.selectedValues.filter(
        (selection) => selection !== option));
  }

  handleRemoveAll() {
    this.triggerOnChange([]);
  }

  handleAutofill = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let selected = this.props.options.filter((elem) => elem.value === value);

    this.setState((prevState) => {
      if (selected.length > 0) {
        return {
          autofill: value,
        };
      } else {
        return {autofill: ''};
      }
    }, () => this.triggerOnChange(selected));
  };

  triggerOnChange(selectedValues) {
    if (this.props.onChange) {
      this.props.onChange(this.getValue(selectedValues));
    }
  }

  getValue(selectedValues) {

    let values = selectedValues.map((elem) => {
      return elem.value;
    });
    if (this.props.multi) {
      return values;
    } else if (values.length > 0) {
      return values[0];
    } else {
      return null;
    }
  }

  render() {
    const {
      autoComplete,
      name,
      required,
      multi,
      label,
      message,
      hideSelected,
      searchable,
      placeholder,
      options,
      className,
      removable,
      error,
      errorMessage,
      selectItemComponent,
      readOnly,
    } = this.props;
    const {
      selectedValues,
    } = this.state;
    return (
        <div
            className={'tdsSelect ' + (required ? 'required-field ' : '') +
            (className || '')}>
          <input type="text" name={name} autoComplete={autoComplete}
                 tabIndex={-1}
                 style={{left: '-99999px', position: 'absolute'}}
                 value={this.state.autofill}
                 required={required && selectedValues.length === 0}
                 onChange={this.handleAutofill}/>
          <NimbusSelect
              className={(className || '')}
              searchable={searchable}
              options={options}
              value={selectedValues}
              disabled={readOnly}
              multi={multi}
              error={error}
              errorMessage={errorMessage}
              message={message}
              label={label}
              hideSelected={hideSelected}
              onSelect={this.handleChange}
              onRemove={this.handleRemove}
              onRemoveAll={this.handleRemoveAll}
              placeholder={placeholder}
              removable={removable}
              selectItemComponent={selectItemComponent}/>
        </div>
    );
  }
}