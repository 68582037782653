import React from 'react';
import {KlarnaPaymentOptions} from "../../klarna/KlarnaPaymentOptions";
import {CreditCardPayment} from "../creditcard/CreditCardPayment";
import {ErrorBoundary} from "../shared/ErrorBoundary";

export class PaymentOptions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitHandler: {},
            closeModalHandler: {},
            availableOptions: this.getAvailableOptions(props.options),
        };

        props.onRegisterSubmitHandler(this.handleSubmit.bind(this));
        this.handleChecked = this.handleChecked.bind(this);
        this.handleRegisterSubmitHandler = this.handleRegisterSubmitHandler.bind(
            this);

        props.onRegisterCloseModalHandler(this.handleModalClose.bind(this));
        this.handleRegisterCloseModalHandler = this.handleRegisterCloseModalHandler.bind(
            this);

        this.handleAvailableOptionsChange();

    }

    getAvailableOptions(options) {
        //Set initial available options based on options
        //Available options might change depending on initialization failures
        const {mastercardOptions, klarnaOptions} = options;
        let availableOptions = {};

        if (mastercardOptions) {
            availableOptions.credit = true;
        }
        if (klarnaOptions && klarnaOptions.session) {
            let pmc = klarnaOptions.session.payment_method_categories;
            if (pmc) {
                for (let i = 0; i < pmc.length; i++) {
                    availableOptions[pmc[i].identifier] = true;
                }
            }
        }

        return availableOptions;
    }

    handleChecked(e) {
        this.props.onPaymentTypeChange(e.currentTarget.value);
    }

    handleSubmit(paymentType) {
        if (paymentType) {
            return this.state.submitHandler[paymentType]();
        } else {
            return Promise.reject('no payment type selected');
        }
    }

    handleModalClose(closed) {
        if (closed && this.state.closeModalHandler[closed]) {
            return this.state.closeModalHandler[closed]();
        } else {
            console.log('Unable to close or find modal.');
        }
    }

    handleInitError = (type) => () => {
        //Remove type from availableOptions if there was an initialization error
        this.setState(prevState => {
            let options = Object.assign({}, prevState.availableOptions);
            delete options[type];
            return {
                availableOptions: options,
            };
        }, this.handleAvailableOptionsChange);
    };

    handleAvailableOptionsChange() {
        let keys = Object.keys(this.state.availableOptions);
        if (keys.length == 1) {
            this.props.onPaymentTypeChange(keys[0]);
        }
    }

    handleRegisterSubmitHandler(type, submitHandler) {
        this.setState(prevState => {
            let handler = {};
            handler[type] = submitHandler;
            return {
                submitHandler: {...handler, ...prevState.submitHandler},
            };
        });
    }

    handleRegisterCloseModalHandler(type, closeModalHandler) {
        this.setState(prevState => {
            let handler = {};
            handler[type] = closeModalHandler;
            return {
                closeModalHandler: {...handler, ...prevState.closeModalHandler},
            };
        });
    }

    renderCredit(mastercardOptions, showRadio) {
        const value = 'credit';
        const checked = this.props.paymentType === value || !showRadio;
        const show = this.state.submitHandler[value];
        if (mastercardOptions) {
            return (
                <div style={{display: show ? 'block' : 'none'}}>
                    <CreditCardPayment
                        show={checked}
                        showRadio={showRadio}
                        onChecked={this.handleChecked}
                        onRegisterSubmitHandler={(handler) => {
                            this.handleRegisterSubmitHandler(value, handler);
                        }}
                        onInitError={this.handleInitError('credit')}
                        options={this.props.options.mastercardOptions}
                        onErrorCallback={this.props.onErrorCallback}
                        onStatusCallback={this.props.onStatusCallback}
                        onSuccessCallback={this.props.onSuccessCallback}
                        onRegisterCloseModalHandler={(handler) => {
                            this.handleRegisterCloseModalHandler('closeModal', handler);
                        }}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    renderKlarna(klarnaOptions, numOptions) {
        return (
            <KlarnaPaymentOptions
                session={klarnaOptions.session}
                billingAddress={klarnaOptions.billingAddress}
                getTranslationsForId={klarnaOptions.getTranslationsForId}
                onChange={this.handleChecked}
                onRegisterSubmitHandler={this.handleRegisterSubmitHandler}
                onInitError={this.handleInitError}
                selectedPaymentOption={this.props.paymentType}
                numberPaymentOptions={numOptions}/>

        );
    }

    render() {
        const {mastercardOptions, klarnaOptions} = this.props.options;
        const {availableOptions} = this.state;
        let numOptions = Object.keys(this.state.availableOptions).length;

        return (
            <div>
                {mastercardOptions && availableOptions.credit &&
                    <ErrorBoundary onError={this.handleInitError('credit')}>
                        {this.renderCredit(mastercardOptions, numOptions > 1)}
                    </ErrorBoundary>}

                {klarnaOptions && klarnaOptions.session &&
                    <ErrorBoundary>
                        {this.renderKlarna(klarnaOptions, numOptions)}
                    </ErrorBoundary>}
            </div>
        );
    }
}
