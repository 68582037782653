import React from "react";
import { ContactTranslationsConsumer } from "../../context";

export const WithContactTranslations = (WrappedComponent) => {
  class WithContactTranslations extends React.Component {
    render() {
      return (
        <ContactTranslationsConsumer>
          {({ contactTranslations }) => (
            <WrappedComponent
              {...this.props}
              translations={{
                ...this.props.translations,
                ...contactTranslations,
              }}
            />
          )}
        </ContactTranslationsConsumer>
      );
    }
  }

  WithContactTranslations.displayName = `WithContactTranslations(${getDisplayName(
    WrappedComponent
  )})`;

  return WithContactTranslations;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}
