import React from "react";
import { LMSTranslationsConsumer } from "../../context";

export const WithLMSTranslations = (WrappedComponent) => {
  class WithLMSTranslations extends React.Component {
    render() {
      return (
        <LMSTranslationsConsumer>
          {({ lmsTranslations }) => (
            <WrappedComponent
              {...this.props}
              translations={{ ...this.props.translations, ...lmsTranslations }}
            />
          )}
        </LMSTranslationsConsumer>
      );
    }
  }

  WithLMSTranslations.displayName = `WithLMSTranslations(${getDisplayName(
    WrappedComponent
  )})`;

  return WithLMSTranslations;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}
