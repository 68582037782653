import React from "react";

import { Alert } from "../UI";
import { PlaceOrderForm } from "./PlaceOrderForm";
import { CaptchaService, HelperUtils } from "tds_shared_ui";
import { WithCommonTranslations, WithKeyCodeTranslations } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import { AlertMixin } from "tds_shared_ui";
import { LocksmithIDLoginModal } from "../UI";

export const Confirmation = WithCommonTranslations(
  WithKeyCodeTranslations(
    class extends AlertMixin(TranslationMixin(React.Component)) {
      constructor(props) {
        super(props);
        this.alertRef = React.createRef();
        this.captchaRef = React.createRef();

        this.state = {
          showLocksmithFields: false,
          hasPromo: true,
          loading: false,
          validationErrors: false,
          paymentWidget: null,
          alert: {
            display: false,
            type: "default",
            message: "",
          },
        };

        this.handleCancelModal = this.handleCancelModal.bind(this);
        this.handlePlaceOrder = this.handlePlaceOrder.bind(this);
        this.handleLocksmithSubmit = this.handleLocksmithSubmit.bind(this);
        this.onValidationError = this.onValidationError.bind(this);
        this.handlePaymentInit = this.handlePaymentInit.bind(this);
        this.handlePaymentSuccess = this.handlePaymentSuccess.bind(this);
        this.handlePaymentError = this.handlePaymentError.bind(this);
      }

      scrollToAlert() {
        HelperUtils.scrollToAlert(this.alertRef);
      }

      handlePlaceOrder() {
        let captchaData = CaptchaService.getCaptchaData(this.captchaRef);

        if (!captchaData.cText) {
          this.displayAlert(
            this.getTranslation("ERROR_CAPTCHA_MUST_BE_COMPLETED"),
            "error",
            this.scrollToAlert
          );
          return;
        }
        this.setState({
          showLocksmithFields: true,
        });
      }

      handleCancelModal() {
        this.setState({
          showLocksmithFields: false,
        });
      }

      handleLocksmithSubmit(passcode) {
        this.setState({
          showLocksmithFields: false,
          validationErrors: false,
          passcode: passcode,
          loading: true,
          alert: {
            display: false,
          },
        });
        if (this.props.checkoutSummary.ccRequired) {
          this.submitPaymentWidget();
        } else {
          this.placeOrder(passcode);
        }
        return Promise.resolve();
      }

      onValidationError() {
        this.setState({
          validationErrors: true,
        });
        this.showFormError();
        this.submitPaymentWidget();
      }

      handlePaymentInit(widget) {
        if (widget === null) {
          //TODO widget failed to load
          this.setState(
            {
              alert: {
                display: true,
                message: this.getTranslation("ERROR_PAYMENT_FORM_DOWN"),
                type: "error",
              },
            },
            this.scrollToAlert
          );
        }
        this.setState({
          paymentWidget: widget,
        });
      }

      handlePaymentSuccess(data) {
        if (data.response.sessionId) {
          this.props.setPaymentInfo(data.response);
        }

        if (!this.state.validationErrors) {
          this.placeOrder(this.state.passcode, data.response);
        } else {
          this.setState({
            loading: false,
          });
        }
      }

      placeOrder(passcode, paymentInfo) {
        let captchaData = CaptchaService.getCaptchaData(this.captchaRef);

        this.props
          .onPlaceOrder(passcode, captchaData, paymentInfo)
          .then(this.props.onSubmit)
          .catch((error) => {
            this.displayError(error, this.scrollToAlert);
            this.setState({
              loading: false,
            });
          });
      }

      handlePaymentError(error) {
        console.log(error);
        this.setState({
          loading: false,
        });

        this.showFormError();
      }

      showFormError() {
        this.setState(
          {
            alert: {
              display: true,
              message: this.getTranslation("ERROR_FORM_VALIDATION"),
              type: "error",
            },
          },
          this.scrollToAlert
        );
      }

      submitPaymentWidget() {
        let pay = this.state.paymentWidget;
        if (pay !== null) {
          this.state.paymentWidget.submit();
        } else {
          this.setState({
            loading: false,
          });
        }
      }

      render() {
        return (
          <div>
            {this.state.alert.display && (
              <Alert ref={this.alertRef} type={this.state.alert.type}>
                {this.state.alert.message}
              </Alert>
            )}

            <PlaceOrderForm
              submit={this.handlePlaceOrder}
              captchaRef={this.captchaRef}
              loading={this.state.loading}
              onValidationError={this.onValidationError}
              onPaymentInit={this.handlePaymentInit}
              onPaymentSuccess={this.handlePaymentSuccess}
              onPaymentError={this.handlePaymentError}
              onCancel={this.props.onCancel}
              {...this.props}
            />

            <LocksmithIDLoginModal
              submitButtonText={this.getTranslation("BUTTON_PLACE_ORDER")}
              onSubmit={this.handleLocksmithSubmit}
              open={this.state.showLocksmithFields}
              onCancel={this.handleCancelModal}
            />
          </div>
        );
      }
    }
  )
);
