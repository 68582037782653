import React from "react";
import { WithCommonTranslations, WithUser } from "../hocs";
import { TranslationMixin, Panel } from "tds_shared_ui";
import { NimbusLink as Link } from "tds_shared_ui";
import { NotificationList } from "./NotificationList";
import "./NotificationIcon.css";

export const NotificationIcon = WithCommonTranslations(
  WithUser(
    class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);

        this.node = React.createRef();
        this.state = {
          markAsRead: false,
          hidden: true,
        };

        this.hide = this.hide.bind(this);
        this.handleMarkAsReadClick = this.handleMarkAsReadClick.bind(this);
        this.togglePanel = this.togglePanel.bind(this);
      }

      componentDidMount() {
        this.toggleClickOutsideEvent(true);
      }

      componentWillUnmount() {
        this.toggleClickOutsideEvent(false);
      }

      toggleClickOutsideEvent(enabled) {
        if (enabled) {
          document.addEventListener("click", this.handleClickOutside);
        } else {
          document.removeEventListener("click", this.handleClickOutside);
        }
      }

      handleMarkAsReadClick() {
        this.setState({
          markAsRead: true,
        });
      }

      handleClickOutside = (event) => {
        if (this.props.showNotification) {
          const node = this.node.current;
          if (!node || !node.contains(event.target)) {
            this.hide();
          }
        }
      };

      hide() {
        this.props.onHide();
      }

      togglePanel() {
        this.props.setNotificationDisplay(!this.props.showNotification);
      }

      render() {
        const { className, notificationData } = this.props;
        const displayClass = (
          "cart-container " + (className ? className : "")
        ).trim();

        return (
          <div className={displayClass} ref={this.node}>
            <Link href="#" onClick={this.togglePanel}>
              <div>
                <i className={"fas fa-bell"}></i>
                {notificationData.length > 0 &&
                  this.state.markAsRead === false && (
                    <span className="badge badge-danger rounded-circle">
                      {notificationData.length}
                    </span>
                  )}
              </div>
            </Link>

            <Panel
              floating={true}
              hidden={!this.props.showNotification}
              className="cart-panel"
            >
              <NotificationList
                items={notificationData}
                onClick={this.handleMarkAsReadClick}
                onShow={this.handleShowMoreClick}
                markAsRead={this.state.markAsRead}
                readOnly={true}
              />
            </Panel>
          </div>
        );
      }
    }
  )
);
