import GenericAjaxService from './GenericAjaxService';
import SessionCacheService from './SessionCacheService';

export default class CacheableAjaxService extends GenericAjaxService {
  static getAPI(API, url, config) {
    //let key be the full url
    const key =API.defaults.baseURL + url;

    return new Promise((resolve, reject) => {
      let data = SessionCacheService.get(key);
      if (data !== null){
        resolve(JSON.parse(data));
      } else {
        super.getAPI(API, url, config).then((data)=>{
          SessionCacheService.set(key, JSON.stringify(data));
          resolve(data);
        }).catch((error) => {
          reject(error);
        })
      }
    });
  }

}