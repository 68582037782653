import React from "react";

import { Header, Table, LoadingIcon, Button, Alert } from "../UI";
import { TranslationMixin, Modal } from "tds_shared_ui";
import {
  WithSubscriptionsTranslations,
  WithCommonTranslations,
  WithRegion,
} from "../hocs";
import { SubscriptionService } from "../../services";
import { AlertMixin } from "tds_shared_ui";
import "./Subscriptions.css";

export const VinSubscriptions = WithRegion(
  WithSubscriptionsTranslations(
    WithCommonTranslations(
      class VinSubscriptions extends AlertMixin(
        TranslationMixin(React.Component)
      ) {
        constructor(props) {
          super(props);

          this.cancelToken = SubscriptionService.getCancelToken().source();

          this.state = {
            disabled: true,
            tableMessage: "",
            loading: false,
            myVinData: null,
            popUpBlocker: false,
            nextAvailableId: "",
            btnHideVin: true,
            showAllRows: false,
            alert: {
              display: false,
              type: "default",
              message: "",
            },
          };

          this.handleCancel = this.handleCancel.bind(this);
          this.handleVin = this.handleVin.bind(this);
          this.handleAddVin = this.handleAddVin.bind(this);
          this.toggleRows = this.toggleRows.bind(this);
        }

        componentWillUnmount() {
          if (this.cancelToken) {
            this.cancelToken.cancel({ unmounted: true });
          }
        }

        handleVin(vinData) {
          this.clearError();
          return SubscriptionService.getVinSubscriptionData(vinData.id, {
            cancelToken: this.cancelToken.token,
          })
            .then((html) => {
              let win = window.open("", "");
              if (win === null) {
                this.displayAlert(
                  this.getTranslation("ALERT_TURN_OFF_POPUP"),
                  "warning"
                );
              } else {
                win.document.write(html);
                win.document.redirectForm.submit();
              }
            })
            .catch((e) => !e.unmounted && this.displayError(e));
        }

        handleAddVin() {
          this.clearError();
          return SubscriptionService.getVinSubscriptionData(
            this.state.nextAvailableId
          )
            .then((html) => {
              let win = window.open("", "");
              if (win === null) {
                this.displayAlert(
                  this.getTranslation("ALERT_TURN_OFF_POPUP"),
                  "warning"
                );
              } else {
                win.document.write(html);
                win.document.redirectForm.submit();
              }
            })
            .catch((e) => this.displayError(e));
        }

        handleCancel() {
          this.setState({
            disabled: true,
            alert: {
              display: false,
            },
          });
          this.props.onCancel();
        }

        handleToggleHidden = (row) => () => {
          let hidden = !row.hidden;

          return SubscriptionService.setVinHidden(row.id, hidden)
            .then(() => {
              this.setState((prevState) => {
                let newData = prevState.myVinData.slice();
                newData.find((e) => e.id === row.id).hidden = hidden;
                return {
                  myVinData: newData,
                };
              });
            })
            .catch((e) => this.displayError(e));
        };

        toggleRows() {
          this.setState((prevState) => ({
            showAllRows: !prevState.showAllRows,
          }));
        }

        componentDidMount() {
          this.setState({ loading: true });
          SubscriptionService.getAvailableSlotsData({
            cancelToken: this.cancelToken.token,
          })
            .then((data) => {
              let { numberAvailable, slots, nextAvailableId, message } = data;
              if (message) {
                this.displayAlert(message, "warning");
              }
              this.setState({
                tableMessage:
                  numberAvailable +
                  " " +
                  this.getTranslation("MESSAGE_SLOT_AVAILABILITY"),
                loading: false,
                myVinData: slots,
                nextAvailableId: nextAvailableId,
              });
            })
            .catch((e) => {
              if (!e.unmounted) {
                this.displayError(e);
                this.setState({
                  loading: false,
                });
              }
            });
        }

        render() {
          const { myVinData, showAllRows } = this.state;
          let rows = myVinData;
          if (!showAllRows && myVinData) {
            rows = myVinData.filter((e) => !e.hidden);
          }

          const vinCols = [
            {
              header: this.getTranslation("LABEL_STATUS"),
              accessor: "displayStatus",
            },
            {
              header: this.getTranslation("LABEL_VIN"),
              accessor: "vin",
              render: (vinData) => {
                return (
                  <Button
                    variant="link"
                    type="button"
                    onClick={() => this.handleVin(vinData)}
                  >
                    {vinData.vin}
                  </Button>
                );
              },
            },
            {
              header: this.getTranslation("LABEL_ACTIONS"),
              accessor: "actions",
              render: (rowData) => {
                return (
                  <Button
                    variant="link"
                    onClick={this.handleToggleHidden(rowData)}
                  >
                    {rowData.hidden
                      ? this.getTranslation("BUTTON_REMOVE_FROM_HIDDEN")
                      : this.getTranslation("BUTTON_HIDE")}
                  </Button>
                );
              },
            },
          ];

          return (
            <Modal
              className="vin-subscriptions-modal"
              header={
                <Header level={1}>
                  {this.getTranslation("HEADER_VIN_SUBSCRIPTIONS")}
                </Header>
              }
              onClose={this.handleCancel}
              open={this.props.open}
              onBackdropClick={this.handleCancel}
            >
              <div className="container">
                {this.state.alert.display && (
                  <Alert
                    ref={this.alertRef}
                    type={this.state.alert.type}
                    className="mb-0"
                  >
                    {this.state.alert.message}
                  </Alert>
                )}

                {this.state.loading ? (
                  <LoadingIcon display={this.state.loading} />
                ) : (
                  <Table
                    columns={vinCols}
                    rows={rows}
                    filterId="vinSubsFilter"
                    tableMessage={this.state.tableMessage}
                    btnShowHide={this.state.btnHideVin}
                    btnText={this.getTranslation(
                      showAllRows ? "BUTTON_HIDE_HIDDEN" : "BUTTON_SHOW_HIDDEN"
                    )}
                    onClick={this.toggleRows}
                  />
                )}

                <div className="mt-3">
                  <Button
                    variant="link"
                    className="p-0"
                    onClick={this.handleAddVin}
                  >
                    {this.getTranslation("LINK_ADD_VIN")}
                  </Button>
                </div>
              </div>
            </Modal>
          );
        }
      }
    )
  )
);
