import { options} from "../../config";
import axios from "axios";
import React from "react";

const MC_VERSION = options.MC_VERSION;
const THREEDS_VERSION = options.THREEDS_VERSION;

// available card types
let CARD_TYPES = [];

// formats card type brand name for display
function formatCardName(name) {
    let formattedCardName = '';
    let cardTypes = CARD_TYPES && CARD_TYPES[0] ? CARD_TYPES[0] : '';
    if (name && cardTypes && cardTypes.length > 0) {
        for (let i = 0; i < cardTypes.length; i++) {
            let brand = cardTypes[i].brand;
            if (brand.toString().toUpperCase() === name.toString().toUpperCase()) {
                formattedCardName = cardTypes[i].displayName;
            }
        }
    }
    return formattedCardName
}

//Generate a random Order Id and Transaction Id
const getOrderId_TransId = () => {
    let cryptoObj = window.crypto || window.msCrypto;
    let randomNum = cryptoObj.getRandomValues(new Uint32Array(1));
    let orderID = new Date().valueOf() + 1 + randomNum[0];
    let transactionId = new Date().valueOf() + randomNum[0];
    let order_trans_obj = [];
    order_trans_obj[0] = orderID;
    order_trans_obj[1] = transactionId;

    return order_trans_obj;
};
//assign global value with instance of order Id and transaction id
export const ORDERID_TRANSID = getOrderId_TransId();
export const isNameOnCardErrorEnabled = [false];

export class CreditCardService extends React.Component {

    static getExpiryYears() {
        let year = new Date().getFullYear();
        let years = [];
        for (let i = 0; i < 20; i++) {
            years.push(year + i);
        }
        return years;
    }

    static getMasterCardUrl(merchantId, debug) {
        let prefix;
        let merchant = merchantId;
        let postfix = '';
        if (debug) {
            prefix = 'test-gateway';
            merchant = 'TEST' + merchant;
        } else {
            prefix = 'na-gateway';
        }

        return `https://${prefix}.mastercard.com/form/version/${MC_VERSION}/merchant/${merchant}/session.js${postfix}`;
    }

    static getThreeDSUrl(debug) {
        let prefix;
        if (debug) {
            prefix = 'test-gateway';
        } else {
            prefix = 'na-gateway';
        }
        return `https://${prefix}.mastercard.com/static/threeDS/${THREEDS_VERSION}/three-ds.min.js`;
    }

    static getMerchantId(merchantId, bac) {
        let id = '';
        if (merchantId != null) {
            id = merchantId;
        } else if (bac != null) {
            id = 'GM-' + bac;
        } else {
            throw 'ERROR: Properties \'merchantId\' and \'bac\' are null. Unable to initialize MasterCard services without 1 of these defined.';
        }
        return id;
    }

    static checkRequiredOptions(options) {
        let error = "";

        if (!options.hasOwnProperty("bac") && !options.hasOwnProperty("merchantId")) {
            error += "ERROR: Property 'bac' or 'merchantId' is required in options\n"
        }

        return error;
    }

    static getSuccessObject(response) {
        let successObj = {};
        let card = response.sourceOfFunds.provided.card;
        successObj.sessionId = response.session.id;
        successObj.payment = {
            brand: card.brand,
            brandDisplayName: formatCardName(card.brand) ? formatCardName(card.brand) : card.brand,
            expiry: {
                month: card.expiry.month,
                year: card.expiry.year,
            },
            number: card.number,
        };
        successObj.version = response.version;
        if (response.customer) {
            successObj.customer = {
                ipAddress: response.customer.ipAddress,
            };
        }

        return successObj;
    }

    static getCardImages(merchantId, epgSecurity, epgRestService) {
        let axiosConfig = {};
        if (epgSecurity && epgSecurity.authorization) {
            axiosConfig = {
                headers: {
                    'Authorization': epgSecurity.authorization
                }
            };
        }
        const url = `${epgRestService}/${merchantId}/cardtype`;

        return new Promise((resolve, reject) => {
            fetch(url, axiosConfig).then(response => {
                return response.json();
            }).then(json => {

                let cardTypes = json.cardTypes;
                CARD_TYPES.push(cardTypes)

                let cardImages = [];
                if (cardTypes && cardTypes.length > 0) {
                    for (let i = 0; i < cardTypes.length; i++) {
                        let imgUrl = cardTypes[i].logo;
                        if (imgUrl) {
                            cardImages.push(imgUrl);
                        }
                    }
                }
                resolve(cardImages);
            }).catch(error => {
                reject(error);
            });
        });
    }

    static updateSession = (sessionObjectId, merchantId3DS, orderId, transactionId, threeDS, threeDSSession, handleOpenModal, onErrorCallback, onStatusCallback, onSuccessCallback, epgSecurity, epgRestService) => {
        // STEP 1B - UPDATE SESSION -- only if a merchant Id to activate 3DS was given
        const orderObj =
            {
                order: {
                    id: orderId,
                    amount: threeDS.orderAmount,
                    currency: threeDS.currency
                },
                transaction: {
                    id: transactionId,
                    currency: threeDS.currency
                },
                authentication: {
                    channel: "PAYER_BROWSER",
                    purpose: "PAYMENT_TRANSACTION",
                    redirectResponseUrl: threeDS.challengeRedirectUrl
                }
            };

        let axiosConfig = {}
        if (epgSecurity && epgSecurity.authorization) {
            axiosConfig = {
                headers: {
                    'Authorization': epgSecurity.authorization
                }
            };
        }

        if (epgRestService) {
            axios.post(`${epgRestService}/${merchantId3DS}/3ds/session/${sessionObjectId}`, orderObj, axiosConfig).then(response => {
                if (response.status == 200) {
                    //Update session needs to be successful in order to initiateAuthentication with the same orderId/transactionId
                    //STEP 3 - INITATE AUTHENTICATION
                    CreditCardService.initAuth(orderId, transactionId, threeDSSession, sessionObjectId, merchantId3DS, threeDS, handleOpenModal, onErrorCallback, onStatusCallback, onSuccessCallback, epgSecurity, epgRestService);
                }
            }).catch(error => {
                let errorMsg = '';
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.data.message) {
                        errorMsg = error.response.data.message;
                    }
                    else {
                        errorMsg = "Session update failed";
                    }
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMsg = "Session update failed";
                }
                onErrorCallback({ paymentType: "3DS", error: errorMsg });
                onStatusCallback({ paymentType: "3DS", status: { loading: false } });
            });
        }
        else {
            console.debug("missing epgRestService property")
        }
    };

    /*
    static initAuth(orderID, transID, threeDSSession, sessionObjectId, merchantID, threeDS, handleOpenModal, onErrorCallback, onStatusCallback, onSuccessCallback) {

        const authPayerSetTimeout = threeDS.authPayerSetTimeout ? threeDS.authPayerSetTimeout : 1000;
        // STEP 3 - INITIATE AUTHENTICATION
        threeDSSession.initiateAuthentication(orderID, transID, function (data) {
            if (data && data.error) {
                var error = data.error;
                // Something bad happened,
                // the error value will match what is returned by the Authentication API
                onErrorCallback(error);
                onStatusCallback({ paymentType: "3DS", status: { loading: false } });
            } else {
                // data.response will contain information like gatewayRecommendation, authentication version, etc.
                // console.log("REST API raw response ", data.restApiResponse);
                // console.log("Correlation Id", data.correlationId);
                // console.log("Gateway Recommendation", data.gatewayRecommendation);
                // console.log("HTML Redirect Code", data.htmlRedirectCode);
                // console.log("Authentication Version", data.authenticationVersion);
                const gatewayRecommendation = data.gatewayRecommendation;

                switch (gatewayRecommendation) {
                    case "PROCEED":
                        //The value of gatewayCode is 'DECLINED' if credit card used is not 3DS enrolled
                        if (data.restApiResponse.response.gatewayCode != "DECLINED") {
                            setTimeout(function () {
                                CreditCardService.authPayer(orderID, transID, threeDSSession, sessionObjectId, merchantID, threeDS, handleOpenModal, onErrorCallback, onStatusCallback, onSuccessCallback)
                            }, authPayerSetTimeout);
                        }
                        else {
                            if (data.restApiResponse.response.gatewayCode === "DECLINED") {
                                onErrorCallback({ paymentType: "3DS", error: { gatewayCode: data.restApiResponse.response.gatewayCode } });
                            }
                            // We want to continue to payment if card used is not 3DS Enrolled - consuming app will use these details to continue to payment
                            onSuccessCallback({ paymentType: "3DS", response: { sessionId: sessionObjectId, orderId: orderID, transactionId: transID } });
                            onStatusCallback({ paymentType: "3DS", status: { loading: false } });
                        }
                        break;
                    case "DO_NOT_PROCEED":
                        // you can offer the payer the option to try another payment method.
                        onErrorCallback({ paymentType: "3DS", error: { gatewayRecommendation: gatewayRecommendation, data: data } })
                        onStatusCallback({ paymentType: "3DS", status: { loading: false } });
                        break;
                }
            }
        });

    }
    */

    static initAuth(orderID, transID, threeDSSession, sessionObjectId, merchantID, threeDS, handleOpenModal, onErrorCallback, onStatusCallback, onSuccessCallback, epgSecurity, epgRestService) {

        const authPayerSetTimeout = threeDS.authPayerSetTimeout ? threeDS.authPayerSetTimeout : 1000;
        // STEP 3 - INITIATE AUTHENTICATION

        const orderObj =
            {
                apiOperation: "INITIATE_AUTHENTICATION",
                authentication: {
                    channel: "PAYER_BROWSER"
                },
                session: {
                    id: sessionObjectId
                }
            };

        let axiosConfig = {}
        if (epgSecurity && epgSecurity.authorization) {
            axiosConfig = {
                headers: {
                    'Authorization': epgSecurity.authorization
                }
            };
        }

        if (epgRestService) {
            axios.post(`${epgRestService}/version/${MC_VERSION}/merchant/${merchantID}/order/${orderID}/transaction/${transID}`, orderObj, axiosConfig)
                .then(response => {
                    if (response.status === 200) {
                        const gatewayRecommendation = response.data.response.gatewayRecommendation;

                        switch (gatewayRecommendation) {
                            case "PROCEED":
                                //The value of gatewayCode is 'DECLINED' if credit card used is not 3DS enrolled
                                if (response.data.response.gatewayCode !== "DECLINED") {
                                    setTimeout(function () {
                                        CreditCardService.authPayer(orderID, transID, threeDSSession, sessionObjectId, merchantID, threeDS, handleOpenModal, onErrorCallback, onStatusCallback, onSuccessCallback, 1, epgSecurity, epgRestService)
                                    }, authPayerSetTimeout);
                                }
                                else {
                                    if (response.data.response.gatewayCode === "DECLINED") {
                                        onErrorCallback({ paymentType: "3DS", error: { gatewayCode: response.data.response.gatewayCode } });
                                    }
                                    // We want to continue to payment if card used is not 3DS Enrolled - consuming app will use these details to continue to payment
                                    onSuccessCallback({ paymentType: "3DS", response: { sessionId: sessionObjectId, orderId: orderID, transactionId: transID } });
                                    onStatusCallback({ paymentType: "3DS", status: { loading: false } });
                                }
                                break;
                            case "DO_NOT_PROCEED":
                                // you can offer the payer the option to try another payment method.
                                onErrorCallback({ paymentType: "3DS", error: { gatewayRecommendation: gatewayRecommendation, data: response.data } })
                                onStatusCallback({ paymentType: "3DS", status: { loading: false } });
                                break;
                        }
                    }
                }).catch(error => {
                        let errorMsg = '';
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            if (error.response.data.message) {
                                errorMsg = error.response.data.message;
                            }
                            else {
                                errorMsg = "Initiate Authentication failed";
                            }
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            errorMsg = "Initiate Authentication failed";
                        }
                        onErrorCallback({ paymentType: "3DS", error: errorMsg });
                        onStatusCallback({ paymentType: "3DS", status: { loading: false } });
                    });
        } else {
            console.debug("missing epgRestService property")
        }
    }

    /*
    static authPayer(orderID, transID, threeDSSession, sessionObjectId, merchantID, threeDS, handleOpenModal, onErrorCallback, onStatusCallback, onSuccessCallback, count = 1) {

        const authPayerRetryTimeout = threeDS.authPayerRetryTimeout ? threeDS.authPayerRetryTimeout : 2000;
        // STEP 4 - AUTHENTICATE PAYER
        threeDSSession.authenticatePayer(orderID, transID, function (data) {
            if (!data.error) {

                const gatewayRecommendation = data.gatewayRecommendation;

                switch (gatewayRecommendation) {
                    case "PROCEED":
                        // Show the modal
                        handleOpenModal();

                        if (document.getElementById("threeDSChallengeContainer")) {
                            document.getElementById("gmpayui-modal-challenge").removeChild(document.getElementById("threeDSChallengeContainer"));
                        }

                        var html = data.htmlRedirectCode;
                        var div = document.createElement("div");
                        div.setAttribute("id", "threeDSChallengeContainer");
                        div.innerHTML = html;

                    function isModalOpen() {
                        // Sometimes modal isn't in dom yet, need to check if it exists
                        const modalContainer = document.getElementById("gmpayui-modal-challenge");
                        if (!modalContainer) {
                            window.setTimeout(isModalOpen, 100); // this checks the flag every 100 milliseconds
                        } else {
                            // Append challenge frame into modal
                            document.getElementById("gmpayui-challenge-close-icon").style.display = "none";
                            document.getElementById("gmpayui-modal-challenge").appendChild(div);

                            var arr = div.getElementsByTagName('script');
                            // Execute the script(s) inside of the given redirectCode since they won't execute when appended
                            for (var n = 0; n < arr.length; n++) {
                                eval(arr[n].innerHTML) //run script inside div
                            }
                            onStatusCallback({ paymentType: "3DS", status: { loading: false } });
                            onSuccessCallback({ paymentType: "3DS", response: { sessionId: sessionObjectId, orderId: orderID, transactionId: transID } });
                        }
                    }
                        isModalOpen();
                        break;
                    case "DO_NOT_PROCEED":
                        // Unsuccessful Auth Payer
                        onErrorCallback({ paymentType: "3DS", error: { gatewayRecommendation: gatewayRecommendation } })
                        onStatusCallback({ paymentType: "3DS", status: { loading: false } });
                        break;
                }
            }
            else if (data.error.cause === 'SERVER_BUSY') {
                // Created a recursive function in the case that SERVER_BUSY is the MC response. If so, we will timeout for a longer period of time...
                // ...each time that's the response. On the 5th try it will have exceeded the limit of tries and user must refresh page.
                const timeout = count !== 4 ? authPayerRetryTimeout * count : 1000;
                setTimeout(function () {
                    CreditCardService.authPayer(orderID, transID, threeDSSession, sessionObjectId, merchantID, threeDS, handleOpenModal, onErrorCallback, onStatusCallback, onSuccessCallback, count + 1)
                }, timeout);
            }
            else {
                onErrorCallback({ paymentType: "3DS", error: data.error })
                onStatusCallback({ paymentType: "3DS", status: { loading: false } });
            }
        });
    }
    */

    static authPayer(orderID, transID, threeDSSession, sessionObjectId, merchantID, threeDS, handleOpenModal, onErrorCallback, onStatusCallback, onSuccessCallback, count = 1, epgSecurity, epgRestService) {

        const authPayerRetryTimeout = threeDS.authPayerRetryTimeout ? threeDS.authPayerRetryTimeout : 2000;
        // STEP 4 - AUTHENTICATE PAYER
        var t;
        const orderObj =
            {
                apiOperation: "AUTHENTICATE_PAYER",
                session: {
                    id: sessionObjectId
                },
                transaction: { acquirer: {} },
                device: {
                    browser: navigator.userAgent,
                    browserDetails: {
                        screenHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
                        screenWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
                        language: (t = navigator.language, (null != t && t.length > 8 ? t.substring(0, t.indexOf("-")) : t) || "en-US"),
                        colorDepth: screen.colorDepth,
                        javaEnabled: String(navigator.javaEnabled()),
                        timeZone: (new Date).getTimezoneOffset(),
                        acceptHeaders: "application/json",
                        "3DSecureChallengeWindowSize": "FULL_SCREEN"
                    }
                }
            };

        let axiosConfig = {}
        if (epgSecurity && epgSecurity.authorization) {
            axiosConfig = {
                headers: {
                    'Authorization': epgSecurity.authorization
                }
            };
        }

        if (epgRestService) {
            axios.post(`${epgRestService}/version/${MC_VERSION}/merchant/${merchantID}/order/${orderID}/transaction/${transID}`, orderObj, axiosConfig)
                .then(response => {
                    if (response.status === 200) {
                        const gatewayRecommendation = response.data.response.gatewayRecommendation;

                        switch (gatewayRecommendation) {
                            case "PROCEED":
                                // Show the modal
                                handleOpenModal();

                                if (document.getElementById("threeDSChallengeContainer")) {
                                    document.getElementById("gmpayui-modal-challenge").removeChild(document.getElementById("threeDSChallengeContainer"));
                                }

                                var html = response.data.authentication.redirectHtml || response.data.authentication.redirect.html;
                                var div = document.createElement("div");
                                div.setAttribute("id", "threeDSChallengeContainer");
                                div.innerHTML = html;

                                function isModalOpen() {
                                    // Sometimes modal isn't in dom yet, need to check if it exists
                                    const modalContainer = document.getElementById("gmpayui-modal-challenge");
                                    if (!modalContainer) {
                                        window.setTimeout(isModalOpen, 100); // this checks the flag every 100 milliseconds
                                    } else {
                                        // Append challenge frame into modal
                                        document.getElementById("gmpayui-challenge-close-icon").style.display = "none";
                                        document.getElementById("gmpayui-modal-challenge").appendChild(div);

                                        var arr = div.getElementsByTagName('script');
                                        // Execute the script(s) inside of the given redirectCode since they won't execute when appended
                                        for (var n = 0; n < arr.length; n++) {
                                            eval(arr[n].innerHTML) //run script inside div
                                        }
                                        onStatusCallback({ paymentType: "3DS", status: { loading: false } });
                                        onSuccessCallback({ paymentType: "3DS", response: { sessionId: sessionObjectId, orderId: orderID, transactionId: transID } });
                                    }
                                }
                                isModalOpen();
                                break;
                            case "DO_NOT_PROCEED":
                                // Unsuccessful Auth Payer
                                onErrorCallback({ paymentType: "3DS", error: { gatewayRecommendation: gatewayRecommendation } })
                                onStatusCallback({ paymentType: "3DS", status: { loading: false } });
                                break;
                        }
                    }
                }).catch(error => {
                    if (error.cause === 'SERVER_BUSY') {
                        // Created a recursive function in the case that SERVER_BUSY is the MC response. If so, we will timeout for a longer period of time...
                        // ...each time that's the response. On the 5th try it will have exceeded the limit of tries and user must refresh page.
                        const timeout = count !== 4 ? authPayerRetryTimeout * count : 1000;
                        setTimeout(function () {
                            CreditCardService.authPayer(orderID, transID, threeDSSession, sessionObjectId, merchantID, threeDS, handleOpenModal, onErrorCallback, onStatusCallback, onSuccessCallback, count + 1, epgSecurity, epgRestService)
                        }, timeout);
                    }
                    else {
                        let errorMsg = '';
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            if (error.response.data.message) {
                                errorMsg = error.response.data.message;
                            }
                            else {
                                errorMsg = "Authenticate Payer failed";
                            }
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            errorMsg = "Authenticate Payer failed";
                        }
                        onErrorCallback({ paymentType: "3DS", error: errorMsg })
                        onStatusCallback({ paymentType: "3DS", status: { loading: false } });
                    }
                });
        }
    }

    static configureMasterCard(threeDS, merchantId3DS,
                               sessionObjectId, mcPaymentSession, cardFields, threeDSSession, handleOpenModal, handleThreeDSError, handleThreeDSStatus, handleThreeDSSuccess, epgSecurity, epgRestService, initCallback, onSuccessCallback,
                               onErrorCallback, onBlurCallback, onChangeCallback, onFocusCallback) {
        if (mcPaymentSession) {
            mcPaymentSession.configure({
                fields: {
                    // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
                    card: cardFields,
                },
                formatCard: true,
                session: sessionObjectId,
                interaction: {
                    displayControl: {

                        // format the field with the same character spacing as embossed on a card
                        formatCard: "EMBOSSED"
                    }
                },
                //SPECIFY YOUR MITIGATION OPTION HERE
                frameEmbeddingMitigation: ['javascript'],
                callbacks: {
                    initialized: function (response) {
                        if (response.status === 'system_error') {
                            throw `GMPaymentUI not initialized properly`;
                        }
                        else {
                            console.log('initialized', response);
                        }
                    },
                    sessionCreatedOrValidated: function (response) {
                        console.log('sessionCreatedOrValidated', response);
                    },
                    formSessionUpdate: async function (response) {
                        // HANDLE RESPONSE FOR UPDATE SESSION
                        console.log('formSessionUpdate', response);

                        //check if security code was provided
                        //TODO make sure 3 or 4 digits provided based on card brand?
                        if (response.sourceOfFunds &&
                            !(response.sourceOfFunds.provided.card.securityCode)) {
                            if (onErrorCallback) {
                                onErrorCallback({
                                    securityCode: 'missing',
                                });
                            }
                        } else if (response.status) {
                            if ('ok' == response.status) {
                                console.log('Session updated with data: ' + response.session.id);
                                if (response.sourceOfFunds.provided.card.securityCode) {
                                    onSuccessCallback(CreditCardService.getSuccessObject(response));

                                    if (merchantId3DS && !isNameOnCardErrorEnabled[0]) {
                                        handleThreeDSStatus({ paymentType: "3DS", status: { loading: true } });

                                        //this is for users who need to update there payment info after already submitting it,
                                        // this will remove the old payment button to avoid user confusion,
                                        // also removes old OrderId/TransactionId associated to old payment button
                                        if (document.getElementById("payButton")) {
                                            document.getElementById("payButton").remove();
                                        }
                                        let order_trans_obj = getOrderId_TransId();

                                        ORDERID_TRANSID[0] = order_trans_obj[0];
                                        ORDERID_TRANSID[1] = order_trans_obj[1];

                                        // // Create payload for the update call, and transaction and order IDs needed for the payload.
                                        await CreditCardService.updateSession(sessionObjectId, merchantId3DS, ORDERID_TRANSID[0], ORDERID_TRANSID[1], threeDS, threeDSSession, handleOpenModal, handleThreeDSError, handleThreeDSStatus, handleThreeDSSuccess, epgSecurity, epgRestService);
                                    }
                                } else {
                                    onErrorCallback({
                                        securityCode: 'missing',
                                    });
                                    handleThreeDSStatus({ paymentType: "3DS", status: { loading: false } });

                                }

                            } else if ('fields_in_error' == response.status) {
                                console.log('response.errors => ', response.errors);

                                onErrorCallback(response.errors);

                            } else if ('request_timeout' == response.status) {
                                console.log('Session update failed with request timeout: ' +
                                    response.errors.message);
                                onErrorCallback({
                                    error: 'request_timeout',
                                });

                            } else if ('system_error' == response.status) {
                                console.log('Session update failed with system error: ' +
                                    response.errors.message);
                                onErrorCallback({
                                    error: 'system_error',
                                });

                            }
                        } else {
                            console.log('Session update failed: ', response);
                            onErrorCallback({
                                error: response,
                            });

                        }
                    },
                },
            });

            if (onBlurCallback) {
                mcPaymentSession.onBlur(['card.number', 'card.securityCode'],
                    onBlurCallback);
            }
            if (onChangeCallback) {
                mcPaymentSession.onChange(['card.number', 'card.securityCode'],
                    onChangeCallback);
            }
            if (onFocusCallback) {
                mcPaymentSession.onFocus(['card.number', 'card.securityCode'],
                    onFocusCallback);
            }
        } else {
            console.error('Unable to load MasterCard services.');
            if (onErrorCallback) {
                onErrorCallback({
                    error: 'loading_error',
                });
            }
        }
    }
}
