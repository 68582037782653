import React from 'react';

import {
  Button,
  FormInput,
  Header,
  TranslationMixin,
  WithCommonTranslations,
  Modal,
} from 'tds_shared_ui';

import './VerificationCodeModal.css';

export const VerificationCodeModal =
    WithCommonTranslations(
        class VerificationCodeModal extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);

            this.state = {
              verificationCode: '',
              showError: false,
              showHelp: false,
            };

            this.handleSubmit = this.handleSubmit.bind(this);
            this.handleClose = this.handleClose.bind(this);
            this.toggleHelp = this.toggleHelp.bind(this);
          }

          componentDidUpdate(prevProps) {
            if (prevProps.open && !this.props.open) {
              this.setState({
                verificationCode: '',
                showError: false,
                showHelp: false,
              });
            }
          }

          handleChange = event => {
            this.setState({[event.target.id]: event.target.value});
          };

          handleSubmit() {
            let code = this.state.verificationCode;
            if (code.length > 0) {
              this.setState({
                showError: false,
              });
              return this.props.onSuccess(code);
            } else {
              this.setState({
                showError: true,
              });
            }
          }

          handleClose() {
            this.state = {
              verificationCode: '',
              showError: false,
            };
            this.props.onCancel();
          }

          toggleHelp() {
            this.setState(prevState => ({
              showHelp: !prevState.showHelp,
            }));

          }

          render() {
            const {open} = this.props;
            const {showHelp, showError, verificationCode} = this.state;

            return (
                <Modal header={<Header level={1}>
                  {this.getTranslation('TITLE_EMAIL_VERIFICATION')}
                </Header>}
                       onClose={this.handleClose}
                       open={open}>
                  <div className="modal-container verification-code-modal">
                    <p>{this.getTranslation(
                        'MESSAGE_EMAIL_VERIFICATION_INSTR')}</p>
                    <FormInput id='verificationCode' name="verificationCode"
                               required={true}
                               displayError={showError}
                               maxLength={6}
                               label={this.getTranslation(
                                   'LABEL_VERIFICATION_CODE')}
                               value={verificationCode}
                               onChange={this.handleChange}/>

                    <Button type="button"
                            variant="link"
                            className="pl-0"
                            onClick={this.toggleHelp}>
                      {this.getTranslation('LABEL_DIDNT_RECEIVE_CODE')}</Button>
                    {showHelp &&
                    <p>{this.getTranslation(
                        'MESSAGE_DIDNT_RECEIVE_CODE_INSTR')}</p>
                    }


                    <div>
                      <div className="float-right">
                        <Button type="button"
                                variant="link"
                                onClick={this.handleClose}>
                          {this.getTranslation('BUTTON_CANCEL')}</Button>

                        <Button type="submit"
                                variant="primary"
                                onClick={this.handleSubmit}>
                          {this.getTranslation('BUTTON_SUBMIT')}</Button>
                      </div>
                    </div>
                  </div>
                </Modal>
            );
          }
        });