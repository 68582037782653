import React from "react";
import { Alert, Header, LoadingIcon, Select, Button } from "../UI";
import { TranslationMixin, Modal } from "tds_shared_ui";
import { WithCommonTranslations, WithSubscriptionsTranslations } from "../hocs";

import "./Subscriptions.css";

export const RenewSubscriptionModal = WithSubscriptionsTranslations(
  WithCommonTranslations(
    class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);

        this.state = {
          selectedValue: null,
          loading: false,
          alert: {
            display: false,
            type: "default",
            message: "",
          },
        };

        this.handleSelect = this.handleSelect.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.clear = this.clear.bind(this);
      }

      componentDidUpdate(prevProps) {
        let { subscriptionInfo } = this.props;
        if (prevProps.open === false && this.props.open) {
          this.clear();
        }

        if (
          subscriptionInfo &&
          (prevProps.subscriptionInfo === null ||
            subscriptionInfo.id !== prevProps.subscriptionInfo.id)
        ) {
          if (subscriptionInfo.skus.length === 1) {
            this.setState({
              selectedValue: subscriptionInfo.skus[0].id,
            });
          }
        }
      }

      handleSelect(value) {
        this.setState({
          selectedValue: value,
        });
      }

      handleClick() {
        if (!this.state.selectedValue) {
          this.setState({
            alert: {
              display: true,
              type: "error",
              message: this.getTranslation("ERROR_SELECT_SUBSCRIPTION_LENGTH"),
            },
          });
        } else {
          this.setState({
            loading: true,
            alert: {
              display: false,
            },
          });
          this.props
            .onPurchaseClick(
              this.state.selectedValue,
              this.props.subscriptionInfo.id
            )
            .catch(this.clear);
        }
      }

      handleClose() {
        this.clear();
        this.props.onClose();
      }

      clear() {
        this.setState({
          loading: false,
          selectedValue: null,
        });
      }

      render() {
        const { subscriptionInfo } = this.props;
        let selectOptions = subscriptionInfo
          ? subscriptionInfo.skus.map((sku) => {
              return { label: sku.name, value: sku.id };
            })
          : null;

        return (
          <Modal
            className="renew-subs-modal"
            header={
              <Header level={1}>{this.getTranslation("MESSAGE_RENEW")}</Header>
            }
            onClose={this.props.onClose}
            open={this.props.open}
            onBackdropClick={this.props.onClose}
          >
            <div className="container pr-0 pl-0">
              {this.state.alert.display && (
                <Alert ref={this.alertRef} type={this.state.alert.type}>
                  {this.state.alert.message}
                </Alert>
              )}

              <Header level={2}>
                {subscriptionInfo && subscriptionInfo.name}
              </Header>
              <div className="row mt-3">
                <div className="col-sm-6 align-self-center">
                  {selectOptions && selectOptions.length > 1 ? (
                    <Select
                      label={this.getTranslation("LABEL_SELECT")}
                      options={selectOptions}
                      removable
                      value={this.state.selectedValue}
                      onChange={this.handleSelect}
                    />
                  ) : (
                    <span className="float-right">
                      {selectOptions && selectOptions[0].label}
                    </span>
                  )}
                </div>
                <div className="col-sm-6 align-self-center">
                  <Button
                    type="button"
                    variant="primary"
                    className="w-100"
                    onClick={this.handleClick}
                    disabled={
                      this.state.loading ||
                      (subscriptionInfo &&
                        subscriptionInfo.purchasable === false)
                    }
                  >
                    <LoadingIcon display={this.state.loading} />
                    {this.getTranslation("BUTTON_ADD_TO_CART")}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        );
      }
    }
  )
);
