import React from "react";
import { Header, Alert, CountDownTimer } from "../UI";
import { WithKeyCodeTranslations, WithCommonTranslations } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import KeycodesService from "../../services/KeycodesService";
import "./Keycodes.css";

export const KeycodesDisplay = WithCommonTranslations(
  WithKeyCodeTranslations(
    class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);

        this.timerAlertRef = React.createRef();
        this.alertRef = React.createRef();

        this.state = {
          loading: false,
          alert: {
            display: false,
            type: "info",
            message: "",
          },
          timerExpired: false,
        };

        this.handleTimeExpired = this.handleTimeExpired.bind(this);
      }

      componentDidMount() {
        this.setState({
          loading: true,
        });
        KeycodesService.getKeycodes(this.props.keycodeRecordId, Intl.DateTimeFormat().resolvedOptions().timeZone)
          .then((data) => {
            this.setState({
              keycodeData: data,
              alert: {
                display: false,
              },
            });
          })
          .catch((error) => {
            this.setState({
              alert: {
                display: true,
                type: "error",
                message: error.translatedMessage,
              },
            });
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      }

      handleTimeExpired() {
        this.setState({
          showKeycodes: false,
          timerExpired: true,
        });
      }

      render() {
        return (
          <div className="row">
            <div className="col">
              <Alert
                type="warning"
                className="countdown-message"
                ref={this.timerAlertRef}
              >
                <div className="overflow-hidden">
                  {this.getTranslation("WARNING_KEYCODES_TIMER_RUNNING")}&nbsp;
                  <b>
                    {this.getTranslation("LABEL_TIME_REMAINING")}&nbsp;
                    <CountDownTimer
                      secondsRemaining="60"
                      onTimeExpired={this.handleTimeExpired}
                    />
                  </b>
                </div>
              </Alert>

              <div className="border-bottom mb-3">
                <div className="row">
                  <Header level={2} className="col-8">
                    {this.getTranslation("HEADER_ACTIVATE_KEYCODE")}
                  </Header>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  )
);
