import React from "react";
import { WithUser } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import "./LanguageSelect.css";

export const LanguageSelect = WithUser(
  class extends TranslationMixin(React.Component) {
    constructor(props) {
      super(props);
      this.state = {
        listVisible: false,
        selected: "",
      };
      this.select = this.select.bind(this);
      this.show = this.show.bind(this);
      this.hide = this.hide.bind(this);
    }

    static getDerivedStateFromProps(props) {
      const { locale, list } = props;
      const lang = locale.split(/[-_]+/)[0];
      let selected = "";

      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        if (item.languageCode === lang) {
          selected = item.name;
          break;
        }
      }
      return {
        selected: selected,
      };
    }

    select(item) {
      this.setState({ selected: item.name });
      this.props.onSelect(item.languageCode);
    }

    show() {
      event.stopPropagation();
      this.setState({ listVisible: true });
      document.addEventListener("click", this.hide);
    }

    hide() {
      this.setState({ listVisible: false });
      document.removeEventListener("click", this.hide);
    }

    render() {
      return (
        <div
          className={
            "dropdown-container" + (this.state.listVisible ? " show" : "")
          }
        >
          <div
            className={
              "dropdown-display" + (this.state.listVisible ? " clicked" : "")
            }
            onClick={this.show}
          >
            <span>
              {this.state.selected} <i className="fas fa-caret-down"></i>
            </span>
          </div>
          <div className="dropdown-list">
            <div>{this.renderListItems()}</div>
          </div>
        </div>
      );
    }

    renderListItems() {
      if (this.props.list) {
        return this.props.list.map((item) => (
          <div onClick={this.select.bind(null, item)} key={item.languageCode}>
            <span>{item.name}</span>
          </div>
        ));
      }
    }
  }
);
