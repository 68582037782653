import React from "react";

import { WithKeyCodeTranslations, WithCommonTranslations } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import "./Keycodes.css";

export const ViewKeycode = WithCommonTranslations(
  WithKeyCodeTranslations(
    class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);

        this.state = {};
      }

      render() {
        let { dataKeycode } = this.props;

        return (
          <div>
            <div className="pb-4">
              <p className="font-weight-bold">
                {this.getTranslation("LABEL_VIN")}
              </p>
              <p>{dataKeycode && dataKeycode.vin}</p>
            </div>

            <div className="pb-4">
              <p className="font-weight-bold">
                {this.getTranslation("LABEL_LICENSE_PLATE")}
              </p>
              <p>{dataKeycode && dataKeycode.licensePlate}</p>
            </div>

            <div className="pb-4">
              <p className="font-weight-bold">
                {this.getTranslation("LABEL_STATE")}
              </p>
              <p>{dataKeycode && dataKeycode.state}</p>
            </div>

            {dataKeycode &&
              dataKeycode.keycodes.map((keycode) => (
                <div className="pb-4" key={keycode.name}>
                  <p className="font-weight-bold">{keycode.name}</p>
                  <p>{keycode.code}</p>
                </div>
              ))}
          </div>
        );
      }
    }
  )
);
