import React from "react";
import { KeyCodeTranslationsConsumer } from "../../context";

export const WithKeyCodeTranslations = (WrappedComponent) => {
  class WithKeyCodeTranslations extends React.Component {
    render() {
      return (
        <KeyCodeTranslationsConsumer>
          {({ keycodeTranslations }) => (
            <WrappedComponent
              {...this.props}
              translations={{
                ...this.props.translations,
                ...keycodeTranslations,
              }}
            />
          )}
        </KeyCodeTranslationsConsumer>
      );
    }
  }

  WithKeyCodeTranslations.displayName = `WithKeyCodeTranslations(${getDisplayName(
    WrappedComponent
  )})`;

  return WithKeyCodeTranslations;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}
