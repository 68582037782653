import React from "react";
import { Button, TermsAndConditions, Alert } from "../UI";
import { KeycodesTable } from "../Keycodes";
import {
  WithKeyCodeTranslations,
  WithCommonTranslations,
  WithUser,
  WithRegion,
} from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import KeycodesService from "../../services/KeycodesService";
import { TermsService } from "../../services";

export const PurchaseKeycodes = WithRegion(
  WithUser(
    WithKeyCodeTranslations(
      WithCommonTranslations(
        class extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);
            this.state = {
              disabled: false,
              showTerms: false,
              alert: {
                display: false,
                message: "default",
                type: "",
              },
            };

            this.handlePurchaseClick = this.handlePurchaseClick.bind(this);
            this.handleTermsSuccess = this.handleTermsSuccess.bind(this);
            this.handleCancel = this.handleCancel.bind(this);
          }

          componentDidMount() {
            this.fetchData();

            this.handleStaticContent();
          }

          componentDidUpdate(prevProps) {
            if (prevProps.locale !== this.props.locale) {
              this.fetchData();
              this.handleStaticContent();
            }
          }

          fetchData() {
            KeycodesService.remainingPurchases()
              .then((message) => {
                this.setState({
                  loading: false,
                  alert: {
                    display: true,
                    message: message,
                    type: "info",
                  },
                });
              })
              .catch((error) => {
                this.setState(
                  {
                    disabled: true,
                    loading: false,
                    alert: {
                      display: true,
                      message: error.translatedMessage,
                      type: "error",
                    },
                  },
                  this.scrollToAlert
                );
              });
          }

          handlePurchaseClick() {
            this.setState({ showTerms: true });
          }

          handleTermsSuccess() {
            this.setState({
              showTerms: false,
            });
            this.props.onVerificationSuccess();
          }

          handleCancel() {
            this.setState({
              showTerms: false,
            });
          }

          handleStaticContent() {
            const tdslsid =
              this.props.regionData.regionCode.toUpperCase() === "AU"
                ? "tdslsidau"
                : "tdslsid";
            TermsService.getTerms(tdslsid, this.props.locale).then((data) => {
              this.setState({
                termsData: data.content,
              });
            }).catch((e) => {
              //Adding empty catch to prevent webpack unhandled exception msg
            });
          }

          render() {
            return (
              <div>
                <div>
                  {this.state.alert.display && (
                    <Alert ref={this.alertRef} type={this.state.alert.type}>
                      {this.state.alert.message}
                    </Alert>
                  )}
                </div>
                <div>
                  <p>
                    {this.getTranslation("MESSAGE_KEYCODE_DESC")}
                    <span className="float-md-right">
                      <Button
                        type="submit"
                        disabled={this.state.disabled}
                        variant="primary"
                        onClick={this.handlePurchaseClick}
                      >
                        {this.getTranslation("BUTTON_KEYCODE_BUTTON")}
                      </Button>
                    </span>
                  </p>

                  <KeycodesTable />

                  <TermsAndConditions
                    onSuccess={this.handleTermsSuccess}
                    open={this.state.showTerms}
                    onCancel={this.handleCancel}
                    text={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.termsData,
                        }}
                      ></div>
                    }
                  />
                </div>
              </div>
            );
          }
        }
      )
    )
  )
);
