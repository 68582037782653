import React from "react";
import { Link } from "react-router-dom";

import {
  AlertMixin,
  AuthService,
  LoadingIcon,
  ProfileForm,
  TranslationMixin,
  withRouter,
} from "tds_shared_ui";
import { HelperUtils, UpdateUserService } from "../services";
import { Alert, VerificationCodeModal } from "../components/UI";
import {
  WithCommonTranslations,
  WithRegion,
  WithUserTranslations,
} from "../components/hocs";

export const EmailUpdateProfilePage = withRouter(
  WithRegion(
    WithCommonTranslations(
      WithUserTranslations(
        class extends AlertMixin(TranslationMixin(React.Component)) {
          constructor(props) {
            super(props);

            this.alertRef = React.createRef();

            this.state = {
              states: [],
              legacyUserKey: null,
              loading: false,
              loadingData: false,
              showPageError: false,
              originalEmail: "",
              showVerification: false,
              alert: {
                display: false,
                type: "default",
                message: "",
              },
              formValues: {
                username: "",
                password: "",
                confirmPassword: "",
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                phone: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                zipCode: "",
                country: "",
                locksmithID: "",
                locksmithPasscode: "",
                acdelcoAcctNum: "",
                companyEmail: "",
                vat: "",
                preferredLanguage: "",
              },
            };
            this.handleVerification = this.handleVerification.bind(this);
            this.handleHideVerification =
              this.handleHideVerification.bind(this);
          }

          componentDidMount() {
            let location = this.props.location;
            let showError = false;
            if (location.state) {
              let { legacyUserKey, username } = location.state;
              if (legacyUserKey) {
                this.setState({
                  legacyUserKey: legacyUserKey,
                  loadingData: true,
                });

                UpdateUserService.getUpdateUser(username, legacyUserKey)
                  .then((data) => {
                    this.initializeUser(data);
                  })
                  .catch((e) => this.displayError(e))
                  .finally(() => this.setState({ loadingData: false }));
              } else {
                showError = true;
              }
            } else {
              showError = true;
            }

            if (showError) {
              this.setState({
                readOnly: true,
                showPageError: true,
              });
            }
          }

          handleVerification(verificationCode) {
            return this.updateUser(verificationCode).then((d) => {
              this.setState({
                showVerification: false,
              });
            }).catch((e) => {
              //Adding empty catch to prevent webpack unhandled exception msg
            });
          }

          handleHideVerification() {
            this.setState({
              showVerification: false,
            });
          }

          initializeUser(user) {
            let states = [];
            if (user === null) {
              user = {};
            }
            let address = user.address;
            if (address == null) {
              address = {};
            } else {
              if (address.country && this.props.countryStateMap) {
                states = this.props.countryStateMap[address.country];
              }
            }

            this.setState({
              user: user,
              originalEmail: user.email,
              states: states,
              formValues: {
                username: user.username || "",
                password: "",
                confirmPassword: "",
                firstName: user.firstName || "",
                middleName: user.middleName || "",
                lastName: user.lastName || "",
                email: user.email || "",
                phone: user.phone || "",
                addressLine1: address.addressLine1 || "",
                addressLine2: address.addressLine2 || "",
                city: address.city || "",
                state: address.state || "",
                zipCode: address.zipCode || "",
                country: address.country || "",
                locksmithID: user.locksmithID || "",
                locksmithPasscode: "",
                acdelcoAcctNum: user.acdelcoAcctNum || "",
                companyEmail: user.companyEmail || "",
                vat: user.vat || "",
                preferredLanguage: user.preferredLanguage || "",
              },
            });
          }

          handleChange = (name, value, callback) => {
            this.setState((prevState) => {
              let newState = {};
              let formValues = Object.assign({}, prevState.formValues);
              formValues[name] = value;
              newState.formValues = formValues;
              if (
                name === "country" &&
                prevState.formValues.country !== value
              ) {
                //reset state if country is changed
                newState.formValues.state = "";
                if (value != null) {
                  newState.states = this.props.countryStateMap[value];
                } else {
                  newState.states = [];
                }
              }
              return newState;
            }, callback);
          };

          handleCancel = () => {
            this.props.navigate(-1);
          };

          scrollToAlert() {
            HelperUtils.scrollToAlert(this.alertRef);
          }

          getUserObject(formValues) {
            let {
              addressLine1,
              addressLine2,
              city,
              state,
              zipCode,
              country,
              confirmPassword,
              locksmithID,
              locksmithPasscode,
              ...otherProps
            } = formValues;

            let userUpdate = Object.assign({}, this.state.user, otherProps);

            //Password isn't updated through Edit Profile but appears on the
            //user object from props, remove it
            delete userUpdate.password;

            userUpdate.locksmithID = locksmithID;
            userUpdate.passcode = locksmithPasscode;
            let address = {
              addressLine1: addressLine1,
              addressLine2: addressLine2,
              city: city,
              state: state,
              zipCode: zipCode,
              country: country,
            };
            userUpdate.address = Object.assign(userUpdate.address, address);
            return userUpdate;
          }

          onValidationError = () => {
            this.setState(
              {
                alert: {
                  display: true,
                  message: this.getTranslation("ERROR_FORM_VALIDATION"),
                  type: "error",
                },
              },
              this.scrollToAlert
            );
          };

          handleSubmit = () => {
            this.setState({
              loading: true,
              alert: {
                display: false,
              },
            });

            //verify email
            //check if email address has changed
            if (this.state.originalEmail !== this.state.formValues.email) {
              //request verification code to verify new email address
              AuthService.requestVerificationCodeForUpdate(
                this.state.user.username,
                this.state.formValues.email,
                this.state.formValues.preferredLanguage
              )
                .then((d) => {
                  this.setState({
                    verificationToken: d.verificationToken,
                    showVerification: true,
                    loading: false,
                  });
                })
                .catch((e) => {
                  //they already have a token, allow them to enter it
                  if (
                    e.code === "1009" &&
                    e.category === "USER" &&
                    this.state.verificationToken
                  ) {
                    this.setState({
                      loading: false,
                      showVerification: true,
                    });
                  } else {
                    this.displayError(e, this.scrollToAlert);
                    this.setState({
                      loading: false,
                    });
                  }
                });
            } else {
              this.updateUser();
            }
          };

          updateUser(verificationCode) {
            let user = this.getUserObject(this.state.formValues);
            if (verificationCode) {
              user.verificationCode = verificationCode;
              user.verificationToken = this.state.verificationToken;
            } else {
              delete user.verificationCode;
              delete user.verificationToken;
            }
            return UpdateUserService.save(
              this.state.legacyUserKey,
              user,
              this.props.locale
            )
              .then((data) => {
                this.setState(
                  {
                    loading: false,
                    readOnly: true,
                    alert: {
                      display: true,
                      message: (
                        <React.Fragment>
                          {this.getTranslation(
                            "MESSAGE_SUCCESS_UPDATE_PROFILE"
                          )}{" "}
                          <Link
                            to={{
                              pathname: "/login",
                              state: { redirect: "/" },
                            }}
                          >
                            {this.getTranslation("MESSAGE_LOGIN")}
                          </Link>
                        </React.Fragment>
                      ),
                      type: "success",
                    },
                  },
                  this.scrollToAlert
                );
                this.initializeUser(data);
              })
              .catch((error) => {
                this.setState({
                  loading: false,
                });
                this.displayError(error, this.scrollToAlert);
              });
          }

          render() {
            if (this.state.showPageError) {
              return (
                <Alert type="error">
                  {this.getTranslation("ERROR_NO_USER")}
                </Alert>
              );
            } else {
              let showVATField = false;
              let showLocksmithFields = false;
              let requireState = false;
              let requireVAT = false;
              let taxIdLabelId = "";
              let regionCode = this.props.regionData.regionCode;
              if (this.props.regionData) {
                let { regionConfigs } = this.props.regionData;
                showVATField = regionConfigs.showVATField === "true";
                requireState = regionConfigs.stateRequired === "true";
                showLocksmithFields =
                  regionConfigs.showLocksmithFields === "true";
                requireVAT = regionConfigs.requireVAT === "true";
                taxIdLabelId = regionConfigs.taxIdLabelId;
              }
              return (
                <div>
                  {this.state.alert.display && (
                    <Alert ref={this.alertRef} type={this.state.alert.type}>
                      {this.state.alert.message}
                    </Alert>
                  )}

                  {!this.state.readOnly && (
                    <Alert type="info">
                      {this.getTranslation("MESSAGE_EMAIL_UPDATE_YOUR_PROFILE")}
                    </Alert>
                  )}

                  <LoadingIcon
                    display={this.state.loadingData}
                    text={this.getTranslation("MESSAGE_GETTING_USER_DATA")}
                  />

                  {this.props.countries && (
                    <ProfileForm
                      submit={this.handleSubmit}
                      onValidationError={this.onValidationError}
                      readOnly={this.state.readOnly}
                      loading={this.state.loading}
                      regionCode={regionCode}
                      formValues={this.state.formValues}
                      countries={this.props.countries}
                      states={this.state.states}
                      requireState={requireState}
                      languages={this.props.languageOptions}
                      onChange={this.handleChange}
                      onCancel={this.handleCancel}
                      showTerms={false}
                      showLogin={false}
                      showVATField={showVATField}
                      taxIdLabelId={taxIdLabelId}
                      requireVAT={requireVAT}
                      showLocksmithFields={showLocksmithFields}
                    />
                  )}

                  <VerificationCodeModal
                    open={this.state.showVerification}
                    onCancel={this.handleHideVerification}
                    onSuccess={this.handleVerification}
                  />
                </div>
              );
            }
          }
        }
      )
    )
  )
);
