import React from 'react';
import {Button} from './';
import './Dropdown.css';

export class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      content: []
    };
    this.showDropdown = this.showDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
  }

  showDropdown(event) {
    event.preventDefault();
    this.setState({ showDropdown: true }, () => {
      document.addEventListener('click', this.closeDropdown);
    });
  }

  closeDropdown() {
    this.setState({showDropdown: false}, () => {
      document.removeEventListener('click', this.closeDropdown);
    });
  }

  render() {
    return (
    <div className="dropdown">
      <Button variant="link" type="button"
              className={"dropdown-button" + (this.state.showDropdown ? " show" : "")}
              onClick={this.showDropdown}>
        Select <i className="fas fa-chevron-down"></i>
      </Button>
      {this.state.showDropdown ? (
      <div className="dropdown-menu">
        <div className="dropdown-item">Extend Subscription</div>
        <div className="dropdown-item">Cancel Subscription</div>
      </div>) : null}

    </div>
    )
  }
}