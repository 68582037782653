import GenericAjaxService from './GenericAjaxService';
import {publicAPI} from './api';

export default class LinkService extends GenericAjaxService {

  static getLinks(locale) {
    let config = null;
    if (locale){
      config = {headers: {'Accept-Language': locale.replace("_", "-")}};
    }
    return super.getAPI(publicAPI, `links`,config);
  }

}