import React from "react";

import { WithCommonTranslations, TranslationMixin } from "tds_shared_ui";
import { ACDelcoLogo, Alert } from "tds_shared_ui";

import "./ErrorPage.css";

export const ErrorPage = WithCommonTranslations(
  class ErrorPage extends TranslationMixin(React.Component) {
    render() {
      return (
        <div className="errorPage">
          <div className="tdsNavbar">
            <ACDelcoLogo imageAlt={this.getTranslation("LABEL_LOGO")} />
          </div>
          <div className="container">
            <Alert type="error">{this.props.error}</Alert>
          </div>
        </div>
      );
    }
  }
);
