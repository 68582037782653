import React from 'react';

import {
    TranslationMixin,
    WithCommonTranslations,
    Modal, Captcha, Header,
    CaptchaService, Alert, AlertMixin,
} from 'tds_shared_ui';

import {Button, LoadingIcon} from "./index";
import {RpoLookupService} from "../../services";

export const CaptchaModal =
    WithCommonTranslations(
        class CaptchaModal extends AlertMixin(TranslationMixin(React.Component)) {
          constructor(props) {
            super(props);

            this.captchaRef = React.createRef();
            this.alertRef = React.createRef();

            this.state = {
              loading: false,
              alert: {
                  display: false,
                  type: "default",
                  message: "",
              },
            };

            this.handleClose = this.handleClose.bind(this);
            this.handleCaptchaSubmit = this.handleCaptchaSubmit.bind(this);
          }


          handleClose() {
              this.setState({
                  alert: {
                      display: false,
                  },
              });
            this.props.onCancel();
            CaptchaService.refreshCaptcha(this.captchaRef);
          }

          handleCaptchaSubmit() {
              let captchaData = CaptchaService.getCaptchaData(this.captchaRef);
              const captchaDataObject = captchaData;
              if (!captchaData.cText) {
                  this.displayAlert(
                      this.getTranslation("ERROR_CAPTCHA_MUST_BE_COMPLETED"),
                      "error",
                      this.scrollToAlert
                  );
                  return;
              }
              this.setState({
                  loading: true,
                  alert: {
                      display: false,
                  },
              });
              RpoLookupService.checkCaptcha(captchaDataObject)
                  .then((response) => {
                      this.setState({
                          loading: false,
                      }, () => {
                          this.props.onSuccess();
                      });
                  }).catch((e) => {
                      this.displayError(e, this.scrollToAlert);
                      this.setState({
                          loading: false,
                      });
          });

          }

          render() {
            const {open} = this.props;
              return (
                  <Modal header={<Header level={1}>
                  {this.getTranslation('TITLE_CAPTCHA_VERIFICATION')}
                </Header>}
                       onClose={this.handleClose}
                       open={open}>
                  <div className="modal-container captcha-modal" style={{width: '100%', height: '100%'}}>
                      {this.state.alert.display && (
                          <Alert ref={this.alertRef} type={this.state.alert.type}>
                              {this.state.alert.message}
                          </Alert>
                      )}
                    <Captcha captchaRef={this.captchaRef} />
                      <div className="mt-3">
                          <Button
                              type="submit"
                              variant="primary"
                              disabled={this.state.loading}
                              onClick={this.handleCaptchaSubmit}
                          >
                              <LoadingIcon display={this.state.loading} />
                              {this.getTranslation("BUTTON_SUBMIT")}
                          </Button>

                          <Button
                              variant="link"
                              type="button"
                              onClick={this.handleClose}
                          >
                              {this.getTranslation("BUTTON_CANCEL")}
                          </Button>
                      </div>
                  </div>
                </Modal>
            );
          }
        });