import React from 'react';
import { FormField } from '../shared/FormField';
import { FormSelect } from '../shared/FormSelect';
import { UtilitiesService } from '../shared/UtilitiesService';
import {CreditCardService, isNameOnCardErrorEnabled} from './CreditCardService';
import { PaymentOption } from '../container/PaymentOption';
import{ResponsiveModal} from '../shared/Modal';
import axios from 'axios';

const MONTHS = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12'];


const CARD_FIELDS = {
    number: 'gmpayui-card-number',
    securityCode: 'gmpayui-security-code',
    expiryMonth: 'gmpayui-expiry-month',
    expiryYear: 'gmpayui-expiry-year',
};

const LABELS = {
    nameOnCard: {
        label: 'Name on Card',
        requiredError: 'Required',
        invalidError: 'Invalid',
    },
    creditCard: {
        label: 'Credit Card',
        placeholder: 'Enter Card Number',
        requiredError: 'Required',
        invalidError: 'Invalid card number',

    },
    expiryMonth: {
        label: 'Month',
        requiredError: 'Required',
        invalidError: 'Invalid month',

    },
    expiryYear: {
        label: 'Year',
        requiredError: 'Required',
        invalidError: 'Invalid year',

    },
    securityCode: {
        label: 'CVV',
        requiredError: 'Required',
        invalidError: 'Invalid CVV',
    },
    creditCardOption: {
        label: 'Credit Card',
        subtext: ''
    }
};

export class CreditCardPayment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sessionId: null,
            showModal: false,
            years: CreditCardService.getExpiryYears(),
            labels: this.getLabels(props.options.translations),
            merchantId: null,
            mcPaymentSession: null,
            submitResolve: null,
            submitReject: null,
            errors: {},
            cardImages: null,
            merchantId3DS: null,
            testMId: null,
            orderId: null,
            transactionId: null
        };

        this.handleErrors = this.handleErrors.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.getErrorMessage = this.getErrorMessage.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);

        this.initialize();
    }

    handleCloseModal() {
        this.setState({
            showModal: false
        });
    }

    handleOpenModal() {
        this.setState({
            showModal: true
        });
    }

    handleChangeName(e) {
        this.setState({
            nameOnCard: e.target.value,
        });
        if (this.props.options.clearErrorOnChange) {
            this.clearError('nameOnCard');
            isNameOnCardErrorEnabled[0] = false;
        }
    }

    handleSubmit() {
        this.setState({
            errors: {},
        });
        let promise = new Promise((resolve, reject) => {
            this.state.mcPaymentSession.updateSessionFromForm('card');
            this.setState({
                submitResolve: resolve,
                submitReject: reject,
            });
        });

        return promise;
    }

    verifyOptions(options) {
        let error = CreditCardService.checkRequiredOptions(options);
        if (error) {
            throw `mastercardOptions not initialized properly: ${error}`;
        }
    }

    initialize = async () => {
        this.verifyOptions(this.props.options);

        window.addEventListener('message', this.receiveMessage.bind(this), false);
        const { clearErrorOnBlur, clearErrorOnFocus, clearErrorOnChange } = this.props.options;

        // Check if using 3DS
        this.state.merchantId3DS = this.props.options.threeDS && this.props.options.threeDS.enable3DS ? this.props.options.merchantId : '';
        let merchantId = this.state.merchantId3DS;

        // if (!this.state.merchantId3DS) {
        if (!merchantId) {
            merchantId = CreditCardService.getMerchantId(this.props.options.merchantId, this.props.options.bac);
        }

        // STEP 1A - CREATE SESSION -- only if a merchant Id to activate 3DS was given
        let sessionObjectId = '';

        // Create the session.
        if (this.state.merchantId3DS) {
            let axiosConfig = {}
            if (this.props.options.security && this.props.options.security.authorization) {
                axiosConfig = {
                    headers: {
                        'Authorization': this.props.options.security.authorization
                    }
                };
            }
            if (this.props.options.epgRestService) {
                await axios.get(`${this.props.options.epgRestService}/${this.state.merchantId3DS}/session`, axiosConfig).then(response => {
                    if (response.status === 200) {
                        sessionObjectId = response.data.session.id;
                        this.setState({
                            testMId: response.data.merchant
                        });
                    }
                })
                    .catch(error => {
                        // Prevent widget from loading if this call fails
                        console.debug("Failed to update the session. Make sure that the merchant id is configured for 3DS or disable 3DS")
                        this.props.onInitError("Failed to update the session. Make sure that the merchant id is configured for 3DS or disable 3DS", error);
                    });
            }
            else {
                console.debug("missing epgRestService property")
            }


        }

        const mastercardUrl = CreditCardService.getMasterCardUrl(merchantId, this.props.options.useTestServers);

        if (this.props.options.showCardImages) {
            CreditCardService.getCardImages(this.props.options.merchantId,this.props.options.security, this.props.options.epgRestService)
                .then(images => this.setState({ cardImages: images }));
        }

        //Load mastercard JS file
        UtilitiesService.loadJS(mastercardUrl, () => {
            //local PaymentSession
            let mcPaymentSession = window.PaymentSession;
            if (mcPaymentSession) {
                this.setState({
                    merchantId: merchantId,
                    mcPaymentSession: mcPaymentSession,
                });
                window.PaymentSession = null;

                let cardFields = Object.assign({}, CARD_FIELDS);
                for (let key in cardFields) {
                    cardFields[key] = '#' + cardFields[key];
                }

                let me = this;

                if (this.state.merchantId3DS) {
                    const threeDSUrl = CreditCardService.getThreeDSUrl(this.props.options.useTestServers);
                    const handleThreeDSError = this.props.onErrorCallback;
                    const handleThreeDSStatus = this.props.onStatusCallback;
                    const handleThreeDSSuccess = this.props.onSuccessCallback;
                    const epgSecurity = this.props.options.security ? this.props.options.security : '';
                    const epgRestService = this.props.options.epgRestService ? this.props.options.epgRestService : '';

                    //Load mastercard JS file
                    UtilitiesService.loadJS(threeDSUrl, () => {
                        // STEP 2 - Configure the 3DS object.
                        let threeDSSession = window.ThreeDS;
                        if (threeDSSession) {
                            CreditCardService.configureMasterCard(this.props.options.threeDS, this.state.merchantId3DS, sessionObjectId, mcPaymentSession,
                                cardFields, threeDSSession, this.handleOpenModal, handleThreeDSError, handleThreeDSStatus, handleThreeDSSuccess, epgSecurity, epgRestService,
                                () => {
                                    //frameContainer.style.visibility = "visible";
                                }, me.handleSuccess, me.handleErrors,
                                me.clearErrorCallback(clearErrorOnBlur),
                                me.clearErrorCallback(clearErrorOnFocus),
                                me.clearErrorCallback(clearErrorOnChange));
                            me.props.onRegisterCloseModalHandler(this.handleCloseModal.bind(this));
                        }
                        else {
                            console.debug("error with 3DSSession")
                            this.props.onInitError("error with 3DSSession");
                        }
                    }, error => {
                        console.debug("error loading 3DS script");
                        this.props.onInitError("error loading 3DS script", error);
                    });

                } else {
                    CreditCardService.configureMasterCard('', '', '', mcPaymentSession,
                        cardFields, '', '', '', '', '', '', '',
                        () => {
                        }, me.handleSuccess, me.handleErrors,
                        me.clearErrorCallback(clearErrorOnBlur),
                        me.clearErrorCallback(clearErrorOnFocus),
                        me.clearErrorCallback(clearErrorOnChange));
                }

                me.props.onRegisterSubmitHandler(this.handleSubmit.bind(this));
            } else {
                console.debug("error with PaymentSession")
                this.props.onInitError("error with PaymentSession");
            }

        }, error => {
            console.debug("error loading mastercard script");
            this.props.onInitError("error loading mastercard script");
        });
    }

    clearErrorCallback(bool) {
        if (bool) {
            return (selector) => {
                if (selector === `#${CARD_FIELDS.number}`) {
                    this.clearError('cardNumber');
                } else if (selector == `#${CARD_FIELDS.securityCode}`) {
                    this.clearError('securityCode');
                }
            };
        } else {
            return null;
        }
    }

    getLabels(translations) {
        if (translations) {
            let labels = {};
            for (let field in LABELS) {
                labels[field] = (typeof translations[field] === 'object') ?
                    translations[field] :
                    LABELS[field];
            }
            return labels;
        } else {
            return LABELS;
        }
    }

    receiveMessage(e) {

        const regex = /^https:\/\/[a-zA-Z0-9-]+\.mastercard\.com$/;
        if (regex.test(e.origin)) {
            let msgObj = JSON.parse(e.data);
            if (msgObj.type == 'sessionCreatedOrValidated') {
                let sessionId = msgObj.payload.session.id;
                this.setState({
                    sessionId: sessionId,
                });

                // STEP 2 - Configure the 3DS object.
                let threeDSSession = window.ThreeDS;
                if (threeDSSession) {
                    threeDSSession.configure({
                        merchantId: this.state.testMId,
                        sessionId: sessionId,
                        containerId: "3DSUI",
                        callback: function () {
                            if (threeDSSession.isConfigured()) {
                                console.log("3DS session configured");
                            }
                        },
                        configuration: {
                            userLanguage: this.props.options.threeDS.userLanguage ? this.props.options.threeDS.userLanguage : '', //Optional parameter
                            wsVersion: 57
                        }
                    });
                }


            }
        }
    }

    handleSuccess(data) {
        //Validate nameOnCard and add error if appropriate
        //Not successful if nameOnCard is invalid
        let nameOnCardError = this.getNameOnCardError();
        if (nameOnCardError) {
            this.handleErrors({});
        } else {
            if (this.state.submitResolve) {
                //Add nameOnCard since MasterCard doesn't handle it
                let successObj = Object.assign({}, data);
                successObj.payment.nameOnCard = this.state.nameOnCard;

                this.state.submitResolve(successObj);
            }
        }
    }

    handleErrors(errors) {
        //Validate nameOnCard and add error if appropriate
        let nameOnCardError = this.getNameOnCardError();
        if (nameOnCardError) {
            errors.nameOnCard = nameOnCardError;
            isNameOnCardErrorEnabled[0] = true;
        }
        this.setState({
            errors: errors,
        });
        if (this.state.submitReject) {
            this.state.submitReject(errors);
        }
    }

    getErrorMessage(translations, errorKey) {
        let errorMessage = '';
        let apiError = this.state.errors[errorKey];
        if (apiError) {
            switch (apiError) {
                case 'missing':
                    errorMessage = translations.requiredError;
                    break;
                case 'invalid':
                default:
                    errorMessage = translations.invalidError;
            }
        }
        return errorMessage;
    }

    getNameOnCardError() {
        if (!this.state.nameOnCard) {
            return 'missing';
        } else {
            return null;
        }
    }

    handleEvent(field, bool) {
        if (bool) {
            return () => this.clearError(field);
        } else {
            return null;
        }
    };

    clearError(field) {
        this.setState(prevState => {
            let newErrors = { ...prevState.errors };
            delete newErrors[field];
            return {
                errors: newErrors,
            };
        });
    }

    render() {
        const display = this.props.show ? 'block' : 'none';
        const { labels, years, cardImages } = this.state;
        const { clearErrorOnBlur, clearErrorOnFocus, clearErrorOnChange, cvvIcon, closeIcon } = this.props.options;
        const { showRadio, onChecked, show } = this.props;

        return (

            <React.Fragment>
                {showRadio &&
                    <PaymentOption
                        id="credit" value="credit"
                        label={labels.creditCardOption.label}
                        subtext={labels.creditCardOption.subtext}
                        onChange={onChecked}
                        checked={show}
                        imgSrc={cardImages}
                    />
                }

                <div style={{ display: display }}>
                    <input id="sessionId" name="sessionId" type="hidden" readOnly
                           value={this.state.sessionId || ''} />

                    {!showRadio &&
                        <span id="gmpayui-card-type-images">
              {cardImages && cardImages.map(image => (
                  <img src={image} key={image}
                       className="gmpayui-card-type-image" />
              ))}
            </span>
                    }

                    <ResponsiveModal
                        open={this.state.showModal}
                        //open={true}
                        classNames={{ 'overlay': 'gmpayui-modal-challenge-overlay', 'modal': 'gmpayui-modal-challenge-container' }}
                        closeIconId={'gmpayui-challenge-close-icon'}
                        onClose={()=> this.handleCloseModal()}
                        center>
                        <div id="gmpayui-modal-challenge">
                            {/* challenge code goes here */}
                            {/*  CLICK ME EEEEEEEEEEEEEEEEEEEEEEEEEE*/}
                            {/*  <button>HELLOOOO</button>*/}
                        </div>
                    </ResponsiveModal>

                    <div className="form-row">
                        <FormField
                            id="gmpayui-name"
                            name="name"
                            fieldName="name"
                            label={labels.nameOnCard.label}
                            placeholder={labels.nameOnCard.placeholder}
                            value={this.state.nameOnCard || ''}
                            onChange={this.handleChangeName}
                            required={true}
                            onBlur={this.handleEvent('nameOnCard', clearErrorOnBlur)}
                            onFocus={this.handleEvent(
                                ('nameOnCard', clearErrorOnFocus))}
                            error={this.getErrorMessage(labels.nameOnCard,
                                'nameOnCard')} />

                        <FormField
                            id={CARD_FIELDS.number}
                            fieldName="creditcard"
                            label={labels.creditCard.label}
                            placeholder={labels.creditCard.placeholder}
                            readOnly
                            required={true}
                            error={this.getErrorMessage(labels.creditCard, 'cardNumber')} />

                    </div>
                    <div className="form-row">

                        <FormSelect
                            id={CARD_FIELDS.expiryMonth}
                            fieldName="expiry-month"
                            label={labels.expiryMonth.label} required={true}
                            onBlur={this.handleEvent('expiryMonth', clearErrorOnBlur)}
                            onFocus={this.handleEvent(('expiryMonth', clearErrorOnFocus))}
                            onChange={this.handleEvent(('expiryMonth', clearErrorOnChange))}
                            readOnly
                            error={this.getErrorMessage(labels.expiryMonth, 'expiryMonth')}>
                            <option value="" default>{labels.expiryMonth.placeholder}</option>
                            {MONTHS.map(m => <option key={m}>{m}</option>)}
                        </FormSelect>

                        <FormSelect
                            id={CARD_FIELDS.expiryYear}
                            fieldName="expiry-year"
                            label={labels.expiryYear.label} required={true}
                            onBlur={this.handleEvent('expiryYear', clearErrorOnBlur)}
                            onFocus={this.handleEvent(('expiryYear', clearErrorOnFocus))}
                            onChange={this.handleEvent(('expiryYear', clearErrorOnChange))}
                            readOnly
                            error={this.getErrorMessage(labels.expiryYear, 'expiryYear')}>
                            <option value="" default>{labels.expiryYear.placeholder}</option>
                            {years.map(y => <option key={y}>{y}</option>)}
                        </FormSelect>

                    </div>
                    <FormField
                        id={CARD_FIELDS.securityCode}
                        fieldName="cvv"
                        maxLength="4" size="4"
                        readOnly
                        label={labels.securityCode.label}
                        placeholder={labels.securityCode.placeholder}
                        required={true}
                        error={this.getErrorMessage(labels.securityCode, 'securityCode')}
                        showToolTip={true}
                        toolTipLabels={labels.securityCode.toolTip}
                        cvvIcon={cvvIcon}
                        closeIcon={closeIcon} />


                </div>
            </React.Fragment>

        );
    }
}
