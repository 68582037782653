import GenericAjaxService from './GenericAjaxService';
import {privateAPI} from './api';

export default class CartService extends GenericAjaxService {

  static updateCart(items) {
    return super.putAPI(privateAPI, `cart`, {items: items});
  }

  static getCart() {
    return super.getAPI(privateAPI, `cart`);
  }

  static prepareCartCheckout(data) {
    return super.postAPI(privateAPI, `checkout/prepareCheckout`, data);
  }

  static placeCartOrder(data) {
    return super.postAPI(privateAPI, `checkout/placeOrder`, data);
  }

  static getSummary() {
    return new Promise((resolve, reject) => {
      CartService.getCart().then(data => {

        let cartItems = [];
        for (let i = 0; i < data.items.length; i++) {
          let test = data.items[i];
          cartItems.push({
            id: test.id,
            name: test.name,
            skuDesc: test.skuDesc,
            itemTotal: test.price,
            originalTotal: test.price,
            quantity: test.quantity,
          })
        }

        let summary = {
          items: cartItems,
          totalAmount: data.subTotal,
          currencyCode: data.currency
        };
        resolve(summary);
        reject("invalid cart");
      }).catch(error => {
        reject(error);
      })
    })
  }
}