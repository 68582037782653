import React from "react";

import { Button, Header, ScrollToAccept } from "../UI";
import { WithCommonTranslations, WithUser } from "../hocs";
import { TranslationMixin, Modal } from "tds_shared_ui";

import "./TermsAndConditions.css";

export const EulaUI = WithUser(
    WithCommonTranslations(
      class extends TranslationMixin(React.Component) {
        constructor(props) {
          super(props);

          this.state = {
            disabled: true,
          };

          this.handleCancel = this.handleCancel.bind(this);
          this.handleSuccess = this.handleSuccess.bind(this);
        }

        handleCancel() {
          this.setState({
            disabled: true,
          });
          this.props.onCancel();
        }

      handleSuccess() {
        this.setState({
          disabled: true,
        });
        this.props.onSuccess();
      }

        handleCheckValue = (e) => {
          this.setState({ disabled: !e });
        };

        render() {
          return (
            <Modal
              header={
                <Header level={1}>{this.getTranslation("LABEL_EULA")}</Header>
              }
              onClose={this.handleCancel}
              open={this.props.open}
              onBackdropClick={this.handleCancel}
            >
              <div>
                <div>
                <span className="font-weight-bold">
                  {this.getTranslation("MESSAGE_TDS_TERMS_EULA")}
                </span>
                  <div>
                  <span className="font-italic">
                    {this.getTranslation("MESSAGE_PLEASE_SCROLL_TERMS_EULA")}
                  </span>
                  </div>
                </div>

                {this.props.text && (
                  <ScrollToAccept
                    className="terms-scroll-text"
                    checkboxLabel={this.getTranslation(
                      "LABEL_REGISTRATION_AGREE_TERMS_EULA"
                    )}
                    onChange={this.handleCheckValue}
                  >
                    <div>{this.props.text}</div>
                  </ScrollToAccept>
                )}

                <div className="float-right">
                <span className="pr-2">
                  <Button
                    type="button"
                    variant="link"
                    onClick={this.handleCancel}
                  >
                    {this.getTranslation("BUTTON_CANCEL")}
                  </Button>
                </span>

                  <span className="pl-2">
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={this.state.disabled || this.props.readOnly}
                    onClick={this.handleSuccess}
                  >
                    {this.getTranslation("BUTTON_AGREE")}
                  </Button>
                </span>
                </div>
              </div>
            </Modal>
          );
        }
      }
  )
);
