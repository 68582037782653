import React from "react";

import { Button, Header } from "../UI";
import { WithCommonTranslations, WithUser } from "../hocs";
import { TranslationMixin, Modal, TCCheckbox } from "tds_shared_ui";

import "./ConsentForm.css";

export const ConsentForm = WithUser(
  WithCommonTranslations(
    class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);

        this.state = {
          disabled: true,
          checkedItems: new Map(),
          checkedTests: [],
          isChecked: [],
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.enableSubmit = this.enableSubmit.bind(this);
      }

      componentDidUpdate(prevProps, prevState) {
        if (this.state.isChecked !== prevState.isChecked) {
          this.enableSubmit();
        }

        if (this.props.hideChecks !== prevProps.hideChecks) {
          if (this.props.hideChecks) {
            this.state.checkedItems.clear();
            this.state.isChecked.length = 0;
          }
        }
      }

      handleCancel() {
        this.setState({
          disabled: true,
        });
        this.props.onCancel();
        this.state.checkedItems.clear();
        this.state.isChecked.length = 0;
      }

      handleSuccess() {
        this.setState({
          disabled: true,
        });
        this.state.checkedItems.clear();
        this.state.isChecked.length = 0;
        this.props.onSuccess();
      }

      handleCheckValue = (e) => {
        this.setState({ disabled: !e });
      };

      handleChange(e) {
        const item = e.target.name;
        const checked = e.target.checked;
        this.setState((prevState) => ({
          checkedItems: prevState.checkedItems.set(item, checked),
        }));
        if (checked) {
          this.setState((prevState) => ({
            isChecked: [...this.state.isChecked, [item, checked]],
          }));
        } else {
          const array = [...this.state.isChecked];
          const index = array.indexOf(item);
          array.splice(index, 1);
          this.setState({ isChecked: array });
        }
      }

      enableSubmit() {
        const textLength = this.props.text.length - 1;
        const checkedLength = this.state.isChecked.length;
        if (textLength === checkedLength) {
          this.setState({ disabled: false });
        } else {
          this.setState({ disabled: true });
        }
      }

      render() {
        const text = this.props.text;
        return (
          <Modal
            header={
              <Header level={1}>
                {this.getTranslation("LABEL_CONSENT_AGREEMENT")}
              </Header>
            }
            onClose={this.handleCancel}
            open={this.props.open}
            onBackdropClick={this.handleCancel}
          >
            <div className="consentForm">
              {text &&
                text.map((data, index) => (
                  <React.Fragment key={"consentsect" + index}>
                    <div
                      className={
                        "consent-section" +
                        index +
                        " " +
                        (index === text.length - 1
                          ? "last-consent-section"
                          : "")
                      }
                    >
                      <div
                        className="consent-text"
                        dangerouslySetInnerHTML={{
                          __html: data,
                        }}
                      ></div>

                      {index !== 0 && (
                        <TCCheckbox
                          name={"checkbox" + index}
                          checked={this.state.checkedItems.get(
                            "checkbox" + index
                          )}
                          onChange={this.handleChange}
                        >
                          {this.getTranslation("LABEL_I_AGREE")}
                        </TCCheckbox>
                      )}
                    </div>
                  </React.Fragment>
                ))}

              <div className="float-right">
                <span className="pr-2">
                  <Button
                    type="button"
                    variant="link"
                    onClick={this.handleCancel}
                  >
                    {this.getTranslation("BUTTON_CANCEL")}
                  </Button>
                </span>

                <span className="pl-2">
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={this.state.disabled || this.props.readOnly}
                    onClick={this.handleSuccess}
                  >
                    {this.getTranslation("BUTTON_AGREE")}
                  </Button>
                </span>
              </div>
            </div>
          </Modal>
        );
      }
    }
  )
);
