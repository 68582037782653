import React from "react";
import { CommonTranslationsConsumer } from "tds_shared_ui";
import { Alert } from "tds_shared_ui";

export default () => (
  <CommonTranslationsConsumer>
    {({ commonTranslations }) => (
      <Alert type="error">
        {commonTranslations ? commonTranslations["ERROR_PAGE_NOT_FOUND"] : ""}
      </Alert>
    )}
  </CommonTranslationsConsumer>
);
