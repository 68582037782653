import React from 'react';
import ReactResponsiveModal from 'react-responsive-modal';

/**************************************************************/
//            ToolTip (used for CVV icon and text)
/**************************************************************/

// ToolTip Icon
// Using html string to easily add to cvv label
// Custom graphic can be passed in
export class ToolTipIcon extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showTip: false
        };

        this.toggleShowTip = this.toggleShowTip.bind(this);

    }

    toggleShowTip() {
        this.setState(prevState => ({
            showTip: !prevState.showTip
        }));
    }

    render() {
        const { toolTipLabels, cvvIcon, closeIcon } = this.props;

        return (
            <>
                {/* CVV Icon */}
                {cvvIcon ? cvvIcon :
                    <span id="gmpayui-security-icon" className="gmpayui-security-code-icon" onClick={() => this.toggleShowTip()}>
                        <svg aria-hidden="true" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z">
                            </path>
                        </svg>
                    </span>}

                {/* Modal with CVV info */}
                <ReactResponsiveModal
                    open={this.state.showTip}
                    onClose={() => this.toggleShowTip()}
                    center
                    closeIconSvgPath={closeIcon}>

                    <div className="gmpayui-tooltip-wrapper">
                        <h3 className="gmpayui-page-header">
                            {toolTipLabels && toolTipLabels.header ? toolTipLabels.header : ''}
                        </h3>
                        <div className="gmpayui-msg">
                            <p>
                                <span>{toolTipLabels && toolTipLabels.instructions1 ? toolTipLabels.instructions1 : ''}</span>
                            </p>
                            <p>
                                <span>{toolTipLabels && toolTipLabels.instructions2 ? toolTipLabels.instructions2 : ''}</span>
                            </p>
                            <p>
                                <span>{toolTipLabels && toolTipLabels.instructions3 ? toolTipLabels.instructions3 : ''}</span>
                            </p>
                            <p>
                                <span>{toolTipLabels && toolTipLabels.instructions4 ? toolTipLabels.instructions4 : ''}</span>
                            </p>
                        </div>
                        {/*Deprecated image URL no need to update or show anymore*/}
                        {/*<img className="gmpayui-tooltip-hint-img" src={`${_DAM_URL_}parts/images/CVV_Card_Icons.png`} alt="cvv tool tip" />*/}
                    </div>
                </ReactResponsiveModal>
            </>
        )
    }
}
