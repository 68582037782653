import "react-app-polyfill/ie11"; // For IE 11 support
// import 'babel-polyfill';
import "custom-event-polyfill";
// eslint-disable-next-line
import React from "react";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import {createRoot} from "react-dom/client";

const root = createRoot(document.getElementById("root"));
root.render(<App />);
unregister();
