import React from "react";
import { SubscriptionsTranslationsConsumer } from "../../context";

export const WithSubscriptionsTranslations = (WrappedComponent) => {
  class WithSubscriptionsTranslations extends React.Component {
    render() {
      return (
        <SubscriptionsTranslationsConsumer>
          {({ subscriptionsTranslations }) => (
            <WrappedComponent
              {...this.props}
              translations={{
                ...this.props.translations,
                ...subscriptionsTranslations,
              }}
            />
          )}
        </SubscriptionsTranslationsConsumer>
      );
    }
  }

  WithSubscriptionsTranslations.displayName = `WithSubscriptionsTranslations(${getDisplayName(
    WrappedComponent
  )})`;

  return WithSubscriptionsTranslations;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}
