export class UtilitiesService {

    static checkRequiredOptions(options) {
        const containerProp = "containerId";
        const onSuccessProp = "onSuccessCallback";
        let error = "";

        if (options.hasOwnProperty(containerProp)) {
            let selectors = document.querySelectorAll(`#${options[containerProp]}`);
            if (selectors.length == 0) {
                error += `ERROR: No element found specified by ${containerProp}\n`;
            } else if (selectors.length > 1) {
                error += `ERROR: More than 1 element specified by ${containerProp}\n`;
            }
        } else {
            error += `ERROR: Property '${containerProp}' required in options\n`;
        }

        if (!options.hasOwnProperty(onSuccessProp)){
            error += `ERROR: Property '${onSuccessProp}' required in options\n`;
        }

        return error;
    }

    static loadJS(file, onload, onerror) {
        let elem = document.createElement('script');
        elem.type = 'application/javascript';
        elem.src = file;
        if (onload) {
            elem.onload = onload;
        }
        if (onerror) {
            elem.onerror = onerror;
        }
        document.head.appendChild(elem);
    }

    static clickjackMitigation(){
        //JAVASCRIPT FRAME-BREAKER CODE TO PROVIDE PROTECTION AGAINST IFRAME CLICK-JACKING
        let style = document.createElement('style');
        style.id = 'antiClickjack';
        style.textContent = "body{display:none !important;}";
        document.getElementsByTagName('head')[0].appendChild(style);

        if (self === top){
            let antiClickjack = document.getElementById("antiClickjack");
            antiClickjack.parentNode.removeChild(antiClickjack);
        }
    }
}
