import React from "react";
import {
  AlertMixin,
  AuthService,
  ProfileForm,
  TranslationMixin,
  withRouter,
} from "tds_shared_ui";
import {
  CaptchaService,
  HelperUtils,
  TermsService,
  UserService,
} from "../services";
import { Alert, Captcha, VerificationCodeModal } from "../components/UI";
import {
  WithCommonTranslations,
  WithRegion,
  WithUserTranslations,
} from "../components/hocs";
import { ConsentForm } from "../components/UI/ConsentForm";

import "./RegistrationPage.css";

export const RegistrationPage = withRouter(
  WithRegion(
    WithCommonTranslations(
      WithUserTranslations(
        class RegistrationPage extends AlertMixin(
          TranslationMixin(React.Component)
        ) {
          constructor(props) {
            super(props);
            this.alertRef = React.createRef();
            this.captchaRef = React.createRef();

            this.state = {
              states: [],
              loading: false,
              termsData: null,
              showVerification: false,
              verificationToken: null,
              alert: {
                display: false,
                type: "default",
                message: "",
              },
              formValues: {
                username: "",
                password: "",
                confirmPassword: "",
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                phone: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                zipCode: "",
                country: "",
                locksmithID: "",
                locksmithPasscode: "",
                acdelcoAcctNum: "",
                companyEmail: "",
                vat: "",
                companyName: "",
                preferredLanguage: "",
              },
              consentData: null,
              showConsent: false,
              hideConsentChecks: false,
              captchaSuccess: false,
            };

            this.showConsentForm = this.showConsentForm.bind(this);
            this.hideTerms = this.hideTerms.bind(this);
            this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
            this.handleVerification = this.handleVerification.bind(this);
            this.handleHideVerification = this.handleHideVerification.bind(this);
          }

          componentDidMount() {
            let { countries, countryStateMap } = this.props;

            if (countries !== null) {
              if (countries.length === 1) {
                let country = countries[0].value;
                if (countryStateMap !== null) {
                  const states = countryStateMap[country];
                  this.setState((prevState) => ({
                    states: states,
                    formValues: {
                      ...prevState.formValues,
                      country: country,
                    },
                  }));
                }
              }
            }

            if (this.props.locale) {
              this.setState((prevState) => {
                let fv = { ...prevState.formValues };
                fv.preferredLanguage = this.props.locale.split("_")[0];
                return {
                  formValues: fv,
                };
              });
            }

            this.handleStaticContent();
          }

          componentDidUpdate(prevProps) {
            let { countries } = this.props;
            let isCountryEqual = prevProps.countries !== countries;

            if (prevProps.locale !== this.props.locale) {
              this.handleStaticContent();
            }

            if (
              (prevProps.countries === null && countries !== null) ||
              isCountryEqual
            ) {
              if (this.state.formValues.country === "" || isCountryEqual) {
                if (countries.length === 1) {
                  let country = countries[0].value;
                  if (this.props.countryStateMap !== null) {
                    const states = this.props.countryStateMap[country];
                    this.setState((prevState) => ({
                      states: states,
                      formValues: {
                        ...prevState.formValues,
                        country: country,
                      },
                    }));
                  }
                }
              }
            }
          }

          handleCaptchaChange(boolSuccess) {
            this.clearError();
            this.setState({
              captchaSuccess: boolSuccess,
            });
          }

          handleChange = (name, value, callback) => {
            this.setState((prevState) => {
              let newState = {};
              let formValues = Object.assign({}, prevState.formValues);
              formValues[name] = value;
              newState.formValues = formValues;
              if (name === "country" && prevState.formValues.country !== value) {
                //reset state if country is changed
                newState.formValues.state = "";
                if (value != null) {
                  newState.states = this.props.countryStateMap[value];
                } else {
                  newState.states = [];
                }
              }
              return newState;
            }, callback);
          };

          handleCancel = () => {
            this.props.navigate(-1);
          };

          scrollToAlert() {
            HelperUtils.scrollToAlert(this.alertRef);
          }

          getUserObject(formValues) {
            let {
              addressLine1,
              addressLine2,
              city,
              state,
              zipCode,
              country,
              confirmPassword,
              locksmithID,
              locksmithPasscode,
              ...user
            } = formValues;

            user.locksmithID = locksmithID;
            user.passcode = locksmithPasscode;
            user.address = {
              addressLine1: addressLine1,
              addressLine2: addressLine2,
              city: city,
              state: state,
              zipCode: zipCode,
              country: country,
            };
            return user;
          }

          onValidationError = () => {
            this.setState(
              {
                alert: {
                  display: true,
                  message: this.getTranslation("ERROR_FORM_VALIDATION"),
                  type: "error",
                },
              },
              this.scrollToAlert
            );
          };

          handleVerification(verificationCode) {
            return this.updateUser(verificationCode).then((d) => {
              this.setState({
                showVerification: false,
              });
            }).catch((e) => {
              //Adding empty catch to prevent webpack unhandled exception msg
            });
          }

          handleHideVerification() {
            this.setState({
              showVerification: false,
              hideConsentChecks: true,
            });
          }

          handleSubmit = () => {
            let captchaData = CaptchaService.getCaptchaData(this.captchaRef);
            this.setState({
              loading: true,
              alert: {
                display: false,
              },
              showConsent: false,
            });
            AuthService.requestVerificationCodeForRegistration(
              this.state.formValues.username,
              this.state.formValues.email,
              this.state.formValues.preferredLanguage,
              captchaData.cId,
              captchaData.cText,
              {
                headers: {
                  "Accept-Language": this.props.locale.replace("_", "-"),
                },
              }
            )
              .then((d) => {
                this.setState({
                  verificationToken: d.verificationToken,
                  showVerification: true,
                  loading: false,
                });
              })
              .catch((e) => {
                //they already have a token, allow them to enter it
                if (
                  e.code === "1009" &&
                  e.category === "USER" &&
                  this.state.verificationToken
                ) {
                  this.setState({
                    loading: false,
                    showVerification: true,
                  });
                } else {
                  this.displayError(e, this.scrollToAlert);
                  this.setState({
                    loading: false,
                    hideConsentChecks: true,
                  });
                }
              });
          };

          updateUser(verificationCode) {
            let captchaData = CaptchaService.getCaptchaData(this.captchaRef);
            let user = this.getUserObject(this.state.formValues);
            if (verificationCode) {
              user.verificationCode = verificationCode;
              user.verificationToken = this.state.verificationToken;
            } else {
              delete user.verificationCode;
              delete user.verificationToken;
            }
            user = Object.assign(user, captchaData);
            return UserService.createUser(user, {
              headers: { "Accept-Language": this.props.locale.replace("_", "-") },
            })
              .then((user) => {
                this.setState(
                  {
                    loading: false,
                    readOnly: true,
                    alert: {
                      display: true,
                      message: this.getTranslation(
                        "MESSAGE_SUCCESS_REGISTRATION"
                      ),
                      type: "success",
                    },
                  },
                  this.scrollToAlert
                );

                const address = user.address || {};
                this.setState({
                  formValues: {
                    username: user.username || "",
                    password: "",
                    confirmPassword: "",
                    firstName: user.firstName || "",
                    middleName: user.middleName || "",
                    lastName: user.lastName || "",
                    email: user.email || "",
                    phone: user.phone || "",
                    addressLine1: address.addressLine1 || "",
                    addressLine2: address.addressLine2 || "",
                    city: address.city || "",
                    state: address.state || "",
                    zipCode: address.zipCode || "",
                    country: address.country || "",
                    locksmithID: user.locksmithID || "",
                    locksmithPasscode: "",
                    acdelcoAcctNum: user.acdelcoAcctNum || "",
                    companyEmail: user.companyEmail || "",
                    vat: user.vat || "",
                    companyName: user.companyName || "",
                    preferredLanguage: user.preferredLanguage || "",
                  },
                });
              })
              .catch((e) => {
                this.displayError(e, this.scrollToAlert);
                this.setState({
                  loading: false,
                  readOnly: false,
                  hideConsentChecks: true,
                });
              });
          }

          showConsentForm() {
            let captchaData = CaptchaService.getCaptchaData(this.captchaRef);

            if (!captchaData.cText) {
              this.displayAlert(
                this.getTranslation("ERROR_CAPTCHA_MUST_BE_COMPLETED"),
                "error",
                this.scrollToAlert
              );
              return;
            }

            if (this.state.formValues.country === "KR") {
              this.setState({
                showConsent: true,
                hideConsentChecks: false,
              });
            } else {
              this.handleSubmit();
            }
          }

          hideTerms() {
            this.setState({
              showConsent: false,
            });
          }

          handleStaticContent() {
            const tdstermsconditions =
              this.props.regionData.regionCode.toUpperCase() === "AU"
                ? "tdstermsconditionsau"
                : "tdstermsconditions";
            TermsService.getTerms(tdstermsconditions, this.props.locale).then(
              (data) => {
                this.setState({
                  termsData: data.content,
                });
              }
            ).catch((e) => {
              //Adding empty catch to prevent webpack unhandled exception msg
            });

            TermsService.getTerms("krconsent", this.props.locale).then((data) => {
              this.setState({
                consentData: data.content,
              });
            }).catch((e) => {
              //Adding empty catch to prevent webpack unhandled exception msg
            });
          }

          render() {
            let showVATField = false;
            let requireState = false;
            let requireVAT = false;
            let renderCaptcha = null;
            let taxIdLabelId = "";
            let showLocksmithFields = false;
            let regionCode = this.props.regionData.regionCode;
            if (this.props.regionData) {
              let { regionConfigs } = this.props.regionData;
              showVATField = regionConfigs.showVATField === "true";
              requireState = regionConfigs.stateRequired === "true";
              showLocksmithFields = regionConfigs.showLocksmithFields === "true";
              requireVAT = regionConfigs.requireVAT === "true";
              taxIdLabelId = regionConfigs.taxIdLabelId;
            }

            if (!this.state.readOnly) {
              renderCaptcha = (props) => (
                <div className="pb-4">
                  <Captcha captchaRef={this.captchaRef} />
                </div>
              );
            }
            return (
              <div className="registrationPage">
                {this.state.alert.display && (
                  <Alert ref={this.alertRef} type={this.state.alert.type}>
                    {this.state.alert.message}
                  </Alert>
                )}

                {this.props.countries && (
                  <ProfileForm
                    showTerms={true}
                    showLogin={true}
                    showVATField={showVATField}
                    taxIdLabelId={taxIdLabelId}
                    requireVAT={requireVAT}
                    regionCode={regionCode}
                    showLocksmithFields={showLocksmithFields}
                    onCaptchaChange={this.handleCaptchaChange}
                    submit={this.showConsentForm}
                    loading={this.state.loading}
                    onValidationError={this.onValidationError}
                    readOnly={this.state.readOnly}
                    formValues={this.state.formValues}
                    countries={this.props.countries}
                    states={this.state.states}
                    requireState={requireState}
                    languages={this.props.languageOptions}
                    termsHtmlString={this.state.termsData}
                    renderCaptcha={renderCaptcha}
                    onChange={this.handleChange}
                    onCancel={this.handleCancel}
                  />
                )}
                <VerificationCodeModal
                  open={this.state.showVerification}
                  onCancel={this.handleHideVerification}
                  onSuccess={this.handleVerification}
                />

                <ConsentForm
                  onSuccess={this.handleSubmit}
                  open={this.state.showConsent}
                  onCancel={this.hideTerms}
                  text={this.state.consentData}
                  hideChecks={this.state.hideConsentChecks}
                />
              </div>
            );
          }
        }
      )
    )
  )
);
