import React from "react";

import { Header, Button, PaymentInputFields, LoadingIcon } from "../UI";
import { TranslationMixin } from "tds_shared_ui";
import { WithFormValidation } from "./../hocs";
import { OrderTotal } from "../UI";

import "./PlaceOrderForm.css";

const billingFields = [
  {
    label: "LABEL_FIRST_NAME",
    value: "billingFirstName",
  },
  {
    label: "LABEL_LAST_NAME",
    value: "billingLastName",
  },
  {
    label: "LABEL_ADDRESS1",
    value: "addressLine1",
    billingAddress: true,
  },
  {
    label: "LABEL_ADDRESS2",
    value: "addressLine2",
    billingAddress: true,
  },
  {
    label: "LABEL_CITY",
    value: "city",
    billingAddress: true,
  },
  {
    label: "LABEL_STATE",
    value: "state",
    billingAddress: true,
  },
  {
    label: "LABEL_ZIPCODE",
    value: "zipCode",
    billingAddress: true,
  },
  {
    label: "LABEL_PHONE",
    value: "billingPhoneNumber",
  },
  {
    label: "LABEL_EMAIL",
    value: "email",
  },
];

export const PlaceOrderForm = WithFormValidation(
  class PlaceOrderForm extends TranslationMixin(React.Component) {
    constructor(props) {
      super(props);

      this.state = {
        loading: false,
      };
    }

    renderItemInfo() {
      const { checkoutData } = this.props;
      return (
        <OrderTotal
          className="order-summary sticky-top"
          checkoutSummary={checkoutData}
        />
      );
    }

    render() {
      const { checkoutData } = this.props;
      return (
        <div className="place-order-form">
          <div className="row">
            <div className="col-sm-6 mb-5">
              <div className="mb-5">
                <div className="border-bottom mb-3">
                  <div className="row">
                    <Header level={2} className="col-8">
                      {this.getTranslation("HEADER_BILLING_INFO")}
                    </Header>
                  </div>
                </div>
                {billingFields.map((el) => (
                  <div className="row" key={el.label}>
                    <div className="col-sm-4 font-weight-bold">
                      {this.getTranslation(el.label)}
                    </div>
                    <div className="col-sm-8">
                      {el.billingAddress
                        ? checkoutData.billingAddress[el.value]
                        : checkoutData[el.value]}
                    </div>
                  </div>
                ))}
              </div>

              <div className="border-bottom mb-3">
                <Header level={2}>
                  {this.getTranslation("HEADER_PLACE_ORDER")}
                </Header>
              </div>

              <PaymentInputFields
                id="lms-payment"
                onInit={this.props.onPaymentInit}
                onPaymentSuccess={this.props.onPaymentSuccess}
                onPaymentError={this.props.onPaymentError}
                grandTotalAmount={this.props.checkoutData.totalAmount}
                currencyCode={this.props.checkoutData.currencyCode}
              />
            </div>
            <div className="col-sm-6">{this.renderItemInfo()}</div>
          </div>
          <div className="mt-5">
            <Button
              variant="primary"
              type="submit"
              className="float-right"
              disabled={this.props.loading || this.props.disabled}
            >
              <LoadingIcon display={this.props.loading} />
              {this.getTranslation("BUTTON_PLACE_ORDER")}
            </Button>
          </div>
        </div>
      );
    }
  }
);
