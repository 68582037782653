import React from "react";
import { CheckoutTranslationsConsumer } from "../../context";

export const WithCheckoutTranslations = (WrappedComponent) => {
  class WithCheckoutTranslations extends React.Component {
    render() {
      return (
        <CheckoutTranslationsConsumer>
          {({ checkoutTranslations }) => (
            <WrappedComponent
              {...this.props}
              translations={{
                ...this.props.translations,
                ...checkoutTranslations,
              }}
            />
          )}
        </CheckoutTranslationsConsumer>
      );
    }
  }

  WithCheckoutTranslations.displayName = `WithCheckoutTranslations(${getDisplayName(
    WrappedComponent
  )})`;

  return WithCheckoutTranslations;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}
