import React from "react";

import { Header, Button, FormInput, Select, LoadingIcon } from "../UI";
import { WithFormValidation, WithCommonTranslations } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import { Validators } from "../../services";
import { WithLMSTranslations } from "../hocs";

export const BillingForm = WithFormValidation(
  WithCommonTranslations(
    WithLMSTranslations(
      class extends TranslationMixin(React.Component) {
        constructor(props) {
          super(props);

          this.handleInputChange = this.handleInputChange.bind(this);
          this.handleSelectChange = this.handleSelectChange.bind(this);
          this.onlyNumbersOnKeyPress =
            Validators.onlyNumbersOnKeyPress.bind(this);
        }

        handleSelectChange = (name) => (value) => {
          this.props.onChange(name, value);
        };

        handleInputChange = (event) => {
          const target = event.target;
          const value =
            target.type === "checkbox" ? target.checked : target.value;
          const name = target.name;

          this.props.onChange(name, value);
        };

        render() {
          return (
            <div className="billing-form">
              <div className="row">
                <div className="col-sm-6">
                  <div className="mb-5">
                    <div className="border-bottom mb-3">
                      <div className="row">
                        <Header level={2} className="col-8">
                          {this.getTranslation("HEADER_BILLING_INFO")}
                        </Header>
                      </div>
                    </div>

                    <div className="form-inputs">
                      <FormInput
                        label={this.getTranslation("LABEL_FIRST_NAME")}
                        name="billingFirstName"
                        id="billingFirstName"
                        maxLength={100}
                        required={true}
                        readOnly={this.props.readOnly}
                        displayError={this.props.isValidated}
                        autoComplete="off"
                        value={this.props.formValues.billingFirstName}
                        onChange={this.handleInputChange}
                      />

                      <FormInput
                        label={this.getTranslation("LABEL_LAST_NAME")}
                        name="billingLastName"
                        id="billingLastName"
                        maxLength={100}
                        required={true}
                        readOnly={this.props.readOnly}
                        displayError={this.props.isValidated}
                        value={this.props.formValues.billingLastName}
                        onChange={this.handleInputChange}
                      />

                      <FormInput
                        label={this.getTranslation("LABEL_ADDRESS1")}
                        name="billingAddressLine1"
                        id="billingAddressLine1"
                        maxLength={100}
                        required={true}
                        readOnly={this.props.readOnly}
                        displayError={this.props.isValidated}
                        autoComplete="off"
                        value={this.props.formValues.billingAddressLine1}
                        onChange={this.handleInputChange}
                      />

                      <FormInput
                        label={this.getTranslation("LABEL_ADDRESS2")}
                        name="billingAddressLine2"
                        id="billingAddressLine2"
                        maxLength={100}
                        readOnly={this.props.readOnly}
                        displayError={this.props.isValidated}
                        autoComplete="off"
                        value={this.props.formValues.billingAddressLine2}
                        onChange={this.handleInputChange}
                      />

                      <FormInput
                        label={this.getTranslation("LABEL_CITY")}
                        name="billingCity"
                        id="billingCity"
                        maxLength={20}
                        required={true}
                        readOnly={this.props.readOnly}
                        displayError={this.props.isValidated}
                        autoComplete="off"
                        value={this.props.formValues.billingCity}
                        onChange={this.handleInputChange}
                      />

                      <Select
                        label={this.getTranslation("LABEL_STATE")}
                        name="billingState"
                        id="billingState"
                        autoComplete="state"
                        required={true}
                        readOnly={this.props.readOnly}
                        options={this.props.states}
                        value={this.props.formValues.billingState}
                        error={this.props.isValidated}
                        errorMessage={this.getTranslation("ERROR_REQUIRED")}
                        onChange={this.handleSelectChange("billingState")}
                      />

                      <FormInput
                        label={this.getTranslation("LABEL_ZIPCODE")}
                        name="billingZipCode"
                        id="billingZipCode"
                        maxLength={20}
                        required={true}
                        readOnly={this.props.readOnly}
                        displayError={this.props.isValidated}
                        autoComplete="off"
                        value={this.props.formValues.billingZipCode}
                        onChange={this.handleInputChange}
                      />

                      <FormInput
                        label={this.getTranslation("LABEL_PHONE")}
                        name="billingPhone"
                        id="billingPhone"
                        maxLength={15}
                        required={true}
                        readOnly={this.props.readOnly}
                        onKeyPress={this.onlyNumbersOnKeyPress}
                        displayError={this.props.isValidated}
                        autoComplete="off"
                        pattern="\d+"
                        value={this.props.formValues.billingPhone}
                        onChange={this.handleInputChange}
                      />

                      <FormInput
                        label={this.getTranslation("LABEL_EMAIL")}
                        id="email"
                        name="email"
                        required={true}
                        type="email"
                        maxLength={100}
                        autoComplete="email"
                        readOnly={this.props.readOnly}
                        displayError={this.props.isValidated}
                        value={this.props.formValues.email}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  {!this.props.readOnly && (
                    <div>
                      <Button
                        variant="primary"
                        className="float-right"
                        type="submit"
                        disabled={this.props.loading}
                      >
                        <LoadingIcon display={this.props.loading} />
                        {this.getTranslation("BUTTON_SUBMIT")}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        }
      }
    )
  )
);
