import React from "react";

import { Header } from "../components/UI";

import { WithRegion, WithCommonTranslations } from "../components/hocs/";
import { TranslationMixin } from "tds_shared_ui";

export const StorePage = WithRegion(
  WithCommonTranslations(
    class StorePage extends TranslationMixin(React.Component) {
      render() {
        let storeLink = "";
        if (this.props.regionData) {
          let { regionConfigs } = this.props.regionData;
          storeLink = regionConfigs.storeLink;
        }
        return (
          <div>
            <Header level={1}>{this.getTranslation("HEADER_STORE")}</Header>
            <div>
              {" "}
              {this.getTranslation("MESSAGE_STORE_PAGE")}{" "}
              <a href={storeLink} target="_blank" rel="noopener noreferrer">
                Amazon
              </a>
              .
            </div>
          </div>
        );
      }
    }
  )
);
