import React from "react";

import {
  Header,
  Button,
  OrderTotal,
  PaymentInputFields,
  FormInput,
  LoadingIcon,
} from "../UI";
import { TranslationMixin } from "tds_shared_ui";
import { WithUser, WithCommonTranslations, WithFormValidation } from "../hocs";

export const PlaceOrderForm = WithUser(
  WithCommonTranslations(
    WithFormValidation(
      class extends TranslationMixin(React.Component) {
        constructor(props) {
          super(props);
          this.state = {
            loading: false,
            onEnable3DS: false,
          };

          this.handleInputChange = this.handleInputChange.bind(this);
          this.addEmailInput = this.addEmailInput.bind(this);
        }

        handleAddEmail(i, event) {
          let values = [...this.props.formValues.additionalEmails];
          values[i] = event.target.value;
          this.props.onChange("additionalEmails", values);
        }

        removeClick(i) {
          let values = [...this.props.formValues.additionalEmails];
          values.splice(i, 1);
          this.props.onChange("additionalEmails", values);
        }

        handleInputChange = (event) => {
          const target = event.target;
          const value =
            target.type === "checkbox" ? target.checked : target.value;
          const name = target.name;

          this.props.onChange(name, value);
        };

        addEmailInput() {
          let values = [...this.props.formValues.additionalEmails];
          values.push("");
          this.props.onChange("additionalEmails", values);
        }

        makeAddEmailInput() {
          const elements = this.props.formValues.additionalEmails.map(
            (el, i) => (
              <div key={i} className="row">
                <div className="col-10">
                  <FormInput
                    label=""
                    id="email"
                    name="email"
                    required={false}
                    type="email"
                    maxLength={100}
                    autoComplete="email"
                    readOnly={this.props.readOnly}
                    displayError={this.props.isValidated}
                    value={el || ""}
                    onChange={this.handleAddEmail.bind(this, i)}
                  />
                </div>
                <div className="col-2 mt-2 text-center">
                  <Button
                    variant="link"
                    type="button"
                    className="pr-0 pl-0"
                    title={this.getTranslation("BUTTON_REMOVE")}
                    onClick={this.removeClick.bind(this, i)}
                  >
                    <i className="fas fa-times-circle"></i>
                  </Button>
                </div>
              </div>
            )
          );
          return elements;
        }

        render() {
          return (
            <div className="checkout-place-order-form row">
              <div className="col">
                <div className="mb-3">
                  <div className="border-bottom mb-3">
                    <Header level={2}>
                      {this.getTranslation("HEADER_PLACE_ORDER")}
                    </Header>
                  </div>

                  <div className="mb-5">
                    <OrderTotal
                      className="basic"
                      checkoutSummary={this.props.checkoutSummary}
                    />
                  </div>

                  {this.props.checkoutSummary.ccRequired && (
                    <div className="row">
                      <div className="col-sm-6 mb-5">
                        <PaymentInputFields
                          id="cart-payment"
                          onEnable3DS={this.props.onEnable3DS}
                          grandTotalAmount={
                            this.props.checkoutSummary.totalAmount
                          }
                          currencyCode={this.props.checkoutSummary.currencyCode}
                          onInit={this.props.onPaymentInit}
                          onPaymentSuccess={this.props.onPaymentSuccess}
                          onPaymentError={this.props.onPaymentError}
                        />
                      </div>
                    </div>
                  )}

                  <div className="mb-5">
                    <div className="border-bottom mb-3">
                      <Header level={2}>
                        {this.getTranslation("HEADER_EMAIL_FOR_ORDER")}
                      </Header>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <FormInput
                          label={this.getTranslation("LABEL_EMAIL")}
                          id="email"
                          name="email"
                          required={true}
                          type="email"
                          maxLength={100}
                          autoComplete="email"
                          readOnly={true}
                          displayError={this.props.isValidated}
                          value={this.props.formValues.email}
                          onChange={this.handleInputChange}
                        />

                        <p className="fa-sm mb-0">
                          <i>
                            {this.getTranslation("MESSAGE_EMAIL_ADD_LIMIT")}
                          </i>
                        </p>

                        {this.makeAddEmailInput().length <= 4 && (
                          <div>
                            <Button
                              variant="link"
                              className="p-0"
                              onClick={this.addEmailInput}
                              type="button"
                            >
                              {this.getTranslation("BUTTON_ADD_EMAIL")}
                            </Button>
                          </div>
                        )}

                        {this.makeAddEmailInput().length <= 5 &&
                          this.makeAddEmailInput().length >= 1 && (
                            <div>
                              <Header level={4} className="mt-4">
                                {this.getTranslation(
                                  "HEADER_ADDITIONAL_EMAILS"
                                )}
                              </Header>
                            </div>
                          )}

                        {this.makeAddEmailInput()}
                      </div>
                    </div>
                  </div>

                  <div>
                    <Button
                      variant="outline-secondary"
                      type="button"
                      onClick={this.props.onCancel}
                    >
                      {this.getTranslation("BUTTON_CANCEL")}
                    </Button>

                    <div className="float-right">
                      <Button
                        variant="link"
                        type="button"
                        onClick={this.props.onBack}
                        disabled={this.props.loading}
                      >
                        {this.getTranslation("BUTTON_BACK")}
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={
                          this.props.onEnable3DS ||
                          this.props.loading ||
                          this.props.disabled
                        }
                      >
                        <LoadingIcon display={this.props.loading} />
                        {this.getTranslation("BUTTON_PLACE_ORDER")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
    )
  )
);
