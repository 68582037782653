import React from "react";

import { MyOrders } from "../components/MyOrders/MyOrders";
import { Header } from "../components/UI";
import { TranslationMixin } from "tds_shared_ui";
import {
  WithUser,
  WithKeyCodeTranslations,
  WithCommonTranslations,
} from "../components/hocs";

export const MyOrdersPage = WithUser(
  WithKeyCodeTranslations(
    WithCommonTranslations(
      TranslationMixin(
        class extends React.Component {
          render() {
            return (
              <div>
                <Header level={1}>
                  {this.getTranslation("LABEL_MY_ORDERS")}
                </Header>
                <MyOrders />
              </div>
            );
          }
        }
      )
    )
  )
);
