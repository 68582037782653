import React from 'react';
import PropTypes from 'prop-types';
import "./Tooltip.css";

import {NimbusTooltip} from 'tds_shared_ui';

export class Tooltip extends React.Component {
  render(){
    return (
        <NimbusTooltip className='tds-tooltip'
                       text={this.props.text}
                       position={this.props.position}>
          <i className="fa fa-question-circle"></i>
        </NimbusTooltip>
    )
  }
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  position: PropTypes.oneOf([
    'top', 'top-start', 'top-end',
    'right', 'right-start', 'right-end',
    'bottom', 'bottom-start', 'bottom-end',
    'left', 'left-start', 'left-end',
  ])
};

Tooltip.defaultProps = {
  position: 'top'
};