import React from "react";
import { Button, Header } from "../UI";
import { NotificationItem } from "./NotificationItem";
import { TranslationMixin, withRouter } from "tds_shared_ui";
import { WithCommonTranslations, WithUser } from "../hocs";
import "./NotificationIcon.css";

export const NotificationList = withRouter(
  WithUser(
    WithCommonTranslations(
      class extends TranslationMixin(React.Component) {
        constructor(props) {
          super(props);

          this.handleClick = this.handleClick.bind(this);
          this.handleMarkAsRead = this.handleMarkAsRead.bind(this);
          this.handleShowMore = this.handleShowMore.bind(this);
        }

        handleShowMore() {
          this.props.onShow();
        }

        handleMarkAsRead() {
          this.props.onClick();
        }

        handleClick(link, newWindow) {
          if (newWindow) {
            window.open(link, "_blank");
          } else {
            this.props.navigate(link);
            this.props.setNotificationDisplay(false);
          }
        }

        render() {
          let notificationData = this.props.notificationData;
          let smallArray =
            notificationData.length > 0 ? notificationData.slice() : [];
          let RenderItem = this.props.readOnly && NotificationItem;
          let markAsRead = this.props.markAsRead;

          return (
            <div className="cart-list notification-list-page">
              <Header level={2}>
                {this.getTranslation("HEADER_NOTIFICATION_CENTER")}
              </Header>
              {markAsRead === false && notificationData.length > 0 && (
                <div className="clearfix">
                  <Button
                    className="btn-mark-read float-right"
                    onClick={this.handleMarkAsRead}
                    type="button"
                    variant="link"
                  >
                    {this.getTranslation("BUTTON_MARK_AS_READ")}
                  </Button>
                </div>
              )}

              {notificationData.length > 0 ? (
                <div className="notification-list">
                  {smallArray.map((notification, i) => (
                    <RenderItem item={notification} key={i} />
                  ))}
                </div>
              ) : (
                <strong className="text-center">
                  {this.getTranslation("MESSAGE_NO_NEW_NOTIFICATIONS")}
                </strong>
              )}
            </div>
          );
        }
      }
    )
  )
);
