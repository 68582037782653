import React from 'react';
import ReactResponsiveModal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

/**
 * Takes all the same props as react-responsive-modal
 */
export class ResponsiveModal extends React.Component {

    render() {
        const { children, classNames, closeIcon, ...otherProps } = this.props;

        return (
            <ReactResponsiveModal classNames={{ ...classNames }} closeIconSvgPath={closeIcon} {...otherProps}>
                {children}
            </ReactResponsiveModal>
        );

    };
}
