import React from 'react';

const Context = React.createContext();

export class RegionProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      regionData: null,
      countries: null,
      countryStateMap: null,
      languageOptions: null
    };
  }

  static getDerivedStateFromProps(props){
    return {
      regionData: props.regionData
    }
  }

  componentDidMount() {
    if (this.state.regionData){
      this.setupCountryData(this.state.regionData);
      this.setupLanguages(this.state.regionData.supportedLanguages);
    }
  }

  componentDidUpdate(prevProps){
    if ((!this.state.countries && this.state.regionData) || prevProps.locale !== this.props.locale){
      this.setupCountryData(this.state.regionData);
      this.setupLanguages(this.state.regionData.supportedLanguages);
    }
  }

  setupLanguages(languages){
    let languageOptions = []
    for(let i = 0; i < languages.length; i++){
      let lang = languages[i];
      languageOptions.push({label: lang.name, value: lang.languageCode});
    }
    this.setState({
      languageOptions: languageOptions
    })
  }

  setupCountryData(data) {
    let countries = [];
    let countryStateMap = {};
    for (let country of data.countries) {
      let code = country.countryCode;
      countries.push({label: country.name, value: code});
      countryStateMap[code] = this.getStates(country.states);
    }

    this.setState({
      regionData: data,
      countries: countries,
      countryStateMap: countryStateMap,
    });
  }

  getStates(data) {
    let states = [];
    for (let state of data) {
      states.push({label: state.name, value: state.stateCode});
    }
    return states.sort(function(a, b) {
      if (a.label < b.label) {
        return -1;
      }

      if (a.label > b.label) {
        return 1;
      }

      return 0;
    });
  }

  render() {
    return (
        <Context.Provider value={this.state}>
          {this.props.children}
        </Context.Provider>
    );
  }
}

export const RegionConsumer = Context.Consumer;