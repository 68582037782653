import React from "react";
import { Link } from "react-router-dom";
import { TranslationMixin } from "tds_shared_ui";
import { WithKeyCodeTranslations, WithCommonTranslations } from "../hocs";
import "./Keycodes.css";
import { WithRegion } from "../hocs";

export const KeycodesInformation = WithRegion(
  WithKeyCodeTranslations(
    WithCommonTranslations(
      class extends TranslationMixin(React.Component) {
        constructor(props) {
          super(props);

          this.node = React.createRef();
          this.state = {
            hidden: true,
          };
        }

        render() {
          const AUKeycodesEnabled =
            this.props.regionData.regionCode.toUpperCase() === "AU";

          return (
            <div className="keycodes">
              {AUKeycodesEnabled ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.getTranslation("MESSAGE_ABOUT_KEYCODES_AU"),
                  }}
                ></p>
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.getTranslation("MESSAGE_ABOUT_KEYCODES"),
                  }}
                ></p>
              )}

              {AUKeycodesEnabled ? (
                <p>
                  <a
                    className="link"
                    href={this.getTranslation("URL_AASRA")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.getTranslation("MESSAGE_VSC_REGISTER_AU")}
                  </a>
                </p>
              ) : (
                <p>
                  <a
                    className="link"
                    href={this.getTranslation("URL_NASTF")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.getTranslation("MESSAGE_VSC_REGISTER")}
                  </a>
                </p>
              )}

              {this.props.user ? (
                AUKeycodesEnabled ? (
                  <p>
                    {this.getTranslation("MESSAGE_VSC_PROFILE_AU")}{" "}
                    <Link to="/profile">
                      {this.getTranslation("MESSAGE_EDIT_PROFILE")}
                    </Link>
                  </p>
                ) : (
                  <p>
                    {this.getTranslation("MESSAGE_VSC_PROFILE")}{" "}
                    <Link to="/profile">
                      {this.getTranslation("MESSAGE_EDIT_PROFILE")}
                    </Link>
                  </p>
                )
              ) : (
                <p>
                  {this.getTranslation("MESSAGE_LOGIN_REGISTER_1")}&nbsp;
                  <Link
                    to={{
                      pathname: "/login",
                      state: { redirect: "/keycodes" },
                    }}
                  >
                    {this.getTranslation("MESSAGE_LOGIN_REGISTER_2_LOGIN_LINK")}
                  </Link>
                  &nbsp;
                  {this.getTranslation("MESSAGE_LOGIN_REGISTER_3_OR")}&nbsp;
                  <Link
                    to={{
                      pathname: "/registration",
                      state: { redirect: "/keycodes" },
                    }}
                  >
                    {this.getTranslation(
                      "MESSAGE_LOGIN_REGISTER_4_REGISTER_LINK"
                    )}
                  </Link>
                </p>
              )}
            </div>
          );
        }
      }
    )
  )
);
