import React from "react";

import { Alert } from "../UI";
import {
  WithKeyCodeTranslations,
  WithCommonTranslations,
  WithUser,
} from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import { HelperUtils, KeycodesService } from "../../services";
import { VehicleInformationForm } from "./VehicleInformationForm";

export const VehicleInformation = WithCommonTranslations(
  WithUser(
    WithKeyCodeTranslations(
      class extends TranslationMixin(React.Component) {
        constructor(props) {
          super(props);
          this.alertRef = React.createRef();

          this.state = {
            alert: {
              display: false,
              type: "default",
              message: "",
            },
          };

          this.handleSubmit = this.handleSubmit.bind(this);
          this.onValidationError = this.onValidationError.bind(this);
        }

        scrollToAlert() {
          HelperUtils.scrollToAlert(this.alertRef);
        }

        onValidationError() {
          this.setState(
            {
              alert: {
                display: true,
                message: this.getTranslation("ERROR_FORM_VALIDATION"),
                type: "error",
              },
            },
            this.scrollToAlert
          );
        }

        getVinFields(formValues) {
          return {
            vin: formValues.vin,
            vehicleOwnerName: formValues.vehicleOwnerName,
            licensePlate: formValues.licensePlate,
            city: formValues.city,
            stateRegistration: formValues.stateRegistration,
            country: formValues.country,
          };
        }

        handleSubmit() {
          this.setState({
            loading: true,
            alert: {
              display: false,
            },
          });
          KeycodesService.validateVin(this.getVinFields(this.props.formValues))
            .then(() => {
              this.setState({
                loading: false,
                alert: {
                  display: true,
                  message: this.getTranslation("MESSAGE_SUCCESS"),
                  type: "success",
                },
              });
              //success
              this.props.onSubmit();
            })
            .catch((error) => {
              this.setState(
                {
                  loading: false,
                  alert: {
                    display: true,
                    message: error.translatedMessage,
                    type: "error",
                  },
                },
                this.scrollToAlert
              );
            });
        }

        render() {
          return (
            <div>
              {this.state.alert.display && (
                <Alert ref={this.alertRef} type={this.state.alert.type}>
                  {this.state.alert.message}
                </Alert>
              )}
              <VehicleInformationForm
                submit={this.handleSubmit}
                loading={this.state.loading}
                onValidationError={this.onValidationError}
                onCancel={this.props.onCancel}
                {...this.props}
              />
            </div>
          );
        }
      }
    )
  )
);
