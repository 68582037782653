import React from "react";
import { WithCommonTranslations } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";

export const CartItem = WithCommonTranslations(
  class extends TranslationMixin(React.Component) {
    render() {
      let { item } = this.props;

      return (
        <React.Fragment>
          <div className="row pb-3">
            <div className="col-12">
              <span className="font-weight-bold">{item.name}</span> -{" "}
              {item.skuDesc}
            </div>
            <div className="float-right col-6">
              {this.getTranslation("LABEL_QUANTITY")}: {item.quantity}
            </div>
            <div className="float-right col-6 text-right">{item.price}</div>
          </div>
        </React.Fragment>
      );
    }
  }
);
