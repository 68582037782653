import React from 'react';
import ReactDOM from 'react-dom';
import {PaymentOptions} from "./v3/container/PaymentOptions";
import {UtilitiesService} from "./shared/UtilitiesService";

export class GMPaymentUI {
    constructor(options) {
        this.paymentType = null;
        this._options = options;

        this.verifyOptions(options);
        this.render(options);

        if (options.disableJavaScriptClickjackMitigation !== true){
            UtilitiesService.clickjackMitigation();
        }

        return {
            submit: this.submit.bind(this),
            closeModal: this.closeModal.bind(this)
        };
    }

    verifyOptions(options){
        let error = UtilitiesService.checkRequiredOptions(options);
        if (error){
            throw `GMPaymentUI not initialized properly: ${error}`;
        }
    }

    handlePaymentTypeChange(value) {
        this.paymentType = value;
        this.render(this._options);
    }

    handleRegisterSubmitHandler(submitHandler) {
        // in PaymentOptions.js
        this._submitHandler = submitHandler;
    }

    submit() {
        if (this._submitHandler) {
            Promise.resolve(this._submitHandler(this.paymentType))
                .then(response => this.handleSuccess(response))
                .catch(error => this.handleError(error));
        }
    }

    handleRegisterCloseModalHandler(closeModalHandler) {
        this._closeModalHandler = closeModalHandler;
    }

    closeModal() {
        if (this._closeModalHandler) {
            this._closeModalHandler('closeModal');
        }
    }

    handleSuccess(response) {
        const {onSuccessCallback} = this._options;
        if (onSuccessCallback) {
            onSuccessCallback({paymentType: this.paymentType, response: response});
        }
    }

    handleError(error) {
        const {onErrorCallback} = this._options;
        if (onErrorCallback) {
            onErrorCallback({paymentType: this.paymentType, error: error});
        }
    }

    handleStatus(status) {
        const {onStatusCallback} = this._options;
        if (onStatusCallback) {
            onStatusCallback({paymentType: this.paymentType, status: status});
        }
    }

    render(options) {
        ReactDOM.render(
            <PaymentOptions
                options={options}
                paymentType={this.paymentType}
                onRegisterSubmitHandler={this.handleRegisterSubmitHandler.bind(this)}
                onPaymentTypeChange={this.handlePaymentTypeChange.bind(this)}
                onErrorCallback={options.onErrorCallback}
                onStatusCallback={options.onStatusCallback}
                onSuccessCallback={options.onSuccessCallback}
                onRegisterCloseModalHandler={this.handleRegisterCloseModalHandler.bind(this)}
            />,
            document.getElementById(options.containerId),
        );
    }
}
