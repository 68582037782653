import React from "react";

import { WithCommonTranslations } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import "./OrderTotal.css";

export const PaymentMethod = WithCommonTranslations(
  class extends TranslationMixin(React.Component) {
    render() {
      let { cardBrand, cardLast4Digits } = this.props;

      if (!cardBrand && !cardLast4Digits) {
        return "";
      }

      return (
        <div>
          <p className="font-weight-bold mb-1">
            {this.getTranslation("LABEL_PAYMENT_METHOD")}
          </p>
          {cardBrand ? cardBrand : this.getTranslation("LABEL_CREDIT_CARD")}{" "}
          {cardLast4Digits &&
            this.getTranslation("LABEL_ENDING_IN") + " " + cardLast4Digits}
        </div>
      );
    }
  }
);
