import React from "react";

import { Alert } from "../UI";
import { HelperUtils } from "tds_shared_ui";
import { TranslationMixin } from "tds_shared_ui";
import { WithUser, WithCommonTranslations } from "../hocs";
import { PlaceOrderForm } from "./PlaceOrderForm";

export const PlaceOrder = WithUser(
  WithCommonTranslations(
    class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);
        this.alertRef = React.createRef();

        this.state = {
          loading: false,
          disabled: false,
          paymentWidget: null,
          validationErrors: false,
          alert: {
            display: false,
            type: "default",
            message: "",
          },
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onValidationError = this.onValidationError.bind(this);
        this.handlePaymentInit = this.handlePaymentInit.bind(this);
        this.handlePaymentSuccess = this.handlePaymentSuccess.bind(this);
        this.handlePaymentError = this.handlePaymentError.bind(this);
      }

      scrollToAlert() {
        HelperUtils.scrollToAlert(this.alertRef);
      }

      onValidationError() {
        this.setState({
          validationErrors: true,
        });
        this.showFormError();
        this.submitPaymentWidget();
      }

      handleSubmit() {
        this.setState({
          validationErrors: false,
          loading: true,
          alert: {
            display: false,
          },
        });
        if (this.props.checkoutSummary.ccRequired) {
          this.submitPaymentWidget();
        } else {
          this.placeOrder();
        }
      }

      handlePaymentInit(widget) {
        if (widget === null) {
          //TODO widget failed to load
          this.setState(
            {
              disabled: true,
              alert: {
                display: true,
                message: this.getTranslation("ERROR_PAYMENT_FORM_DOWN"),
                type: "error",
              },
            },
            this.scrollToAlert
          );
        }
        this.setState({
          paymentWidget: widget,
        });
      }

      handlePaymentSuccess(data) {
        if (data.response.sessionId) {
          this.props.setPaymentInfo(data.response);
        }
        if (data.paymentType === "3DS") {
          this.state.paymentWidget.closeModal();
        }
        if (!this.state.validationErrors) {
          this.placeOrder(data.response);
        } else {
          this.setState({
            loading: false,
          });
        }
      }

      placeOrder(paymentInfo) {
        this.props
          .onPlaceOrder(paymentInfo)
          .then(this.props.onSubmit)
          .catch((error) => {
            const msg =
              error.translatedMessage || this.getTranslation("ERROR_GENERIC");
            this.setState(
              {
                loading: false,
                alert: {
                  display: true,
                  type: "error",
                  message: msg,
                },
              },
              this.scrollToAlert
            );
          });
      }

      handlePaymentError(error) {
        if (error === "DO_NOT_PROCEED" || error === "Do Not Proceed") {
          this.state.paymentWidget.closeModal();
        } else if (
          !(error.error == null) &&
          error.error.explanation === "Session limit exceeded"
        ) {
          this.state.paymentWidget.closeModal();
        } else if (error === "Timeout") {
          this.state.paymentWidget.closeModal();
        }
        this.setState({
          loading: false,
        });
        this.showFormError(error);
      }

      showFormError(error) {
        if (error === "DO_NOT_PROCEED" || error === "Do Not Proceed") {
          this.setState(
            {
              alert: {
                display: true,
                message: this.getTranslation("ERROR_GATEWAY_RESPONSE"),
                type: "error",
              },
            },
            this.scrollToAlert
          );
        } else if (
          (!(error.error == null) &&
            error.error.explanation === "Session limit exceeded") ||
          error.explanation === "Session limit exceeded"
        ) {
          this.setState(
            {
              alert: {
                display: true,
                message: this.getTranslation("ERROR_SESSION_LIMIT_EXCEEDED"),
                type: "error",
              },
            },
            this.scrollToAlert
          );
        } else if (error === "Timeout") {
          this.setState(
            {
              alert: {
                display: true,
                message: this.getTranslation("ERROR_3DS_TIMEOUT"),
                type: "error",
              },
            },
            this.scrollToAlert
          );
        } else if (
          !(error.error == null) &&
          error.error.gatewayCode === "DECLINED"
        ) {
          this.setState(
            {
              alert: {
                display: true,
                message: this.getTranslation("ERROR_3DS_NOT_ENABLED"),
                type: "error",
              },
            },
            this.scrollToAlert
          );
        } else {
          this.setState(
            {
              alert: {
                display: true,
                message: this.getTranslation("ERROR_FORM_VALIDATION"),
                type: "error",
              },
            },
            this.scrollToAlert
          );
        }
      }

      submitPaymentWidget() {
        let pay = this.state.paymentWidget;
        if (pay !== null) {
          this.state.paymentWidget.submit();
        } else {
          this.setState({
            loading: false,
          });
        }
      }

      render() {
        return (
          <div className="checkout-place-order">
            {this.state.alert.display && (
              <Alert ref={this.alertRef} type={this.state.alert.type}>
                {this.state.alert.message}
              </Alert>
            )}

            <PlaceOrderForm
              submit={this.handleSubmit}
              loading={this.state.loading}
              disabled={this.state.disabled}
              onValidationError={this.onValidationError}
              onPaymentInit={this.handlePaymentInit}
              onPaymentSuccess={this.handlePaymentSuccess}
              onPaymentError={this.handlePaymentError}
              onCancel={this.props.onCancel}
              {...this.props}
            />
          </div>
        );
      }
    }
  )
);
