import React from "react";
import { Link } from "react-router-dom";
import { WithRegion, WithCommonTranslations } from "../../components/hocs";
import { TranslationMixin } from "tds_shared_ui";
import "./AppFooter.css";

export const AppFooter = WithRegion(
  WithCommonTranslations(
    class extends TranslationMixin(React.Component) {
      render() {
        let bizEntity = "";
        if (this.props.regionData) {
          let { regionConfigs } = this.props.regionData;
          bizEntity = regionConfigs.businessEntity;
        }

        return (
          <div className="appFooter">
            <Link to="/contactus">
              {this.getTranslation("LABEL_CONTACT_US")}
            </Link>

            {this.props.footerLinks &&
              this.props.footerLinks.map((link) => (
                <span key={link.id}>
                  <i className="fas fa-circle"></i>
                  <a target="_blank" rel="noopener noreferrer" href={link.url}>
                    {link.name}
                  </a>
                </span>
              ))}
            {bizEntity && (
              <span className="float-right business-entity">{bizEntity}</span>
            )}
          </div>
        );
      }
    }
  )
);
