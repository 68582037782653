import GenericAjaxService from './GenericAjaxService';
import {publicAPI} from './api';

export default class UpdateUserService extends GenericAjaxService {

  static getUpdateUser(username, legacyUserKey) {
    return super.getAPI(publicAPI,
        `user/update/${encodeURIComponent(username)}`,
        {headers: {'LegacyUserKey': legacyUserKey}});
  }

  static save(legacyUserKey, data, locale) {
    let headers = {'LegacyUserKey': legacyUserKey};
    if (locale){
      headers['Accept-Language'] = locale.replace("_", "-")
    }
    return super.postAPI(publicAPI, `user/update`, data,
        {headers: headers});
  }

}