import React from 'react';

import './NotificationIcon.css';

export const NotificationItem =
    class extends React.Component {

      render() {
        let {item} = this.props;
        let icon = item.type === 'info' ?
            <i className="fas fa-info-circle"></i> :
            <i className="fas fa-exclamation-triangle"></i>;

        return (
            <div className="notification-item">
              <div className="row">
                <div className="col-auto col-icon">{icon}</div>
                <div className="col col-icon">
                  <span className="font-weight-bold">{item.title}</span>
                  <div>{item.message}</div>
                </div>
              </div>
            </div>
        );
      }
    };