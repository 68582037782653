import React from "react";

import {
  AuthService,
  CartService,
  NotificationService,
  UserService,
  UtilService,
} from "../services";

const Context = React.createContext();

export class UserProvider extends React.Component {
  constructor(props) {
    super(props);

    this.setUser = (user) => {
      this.setState({
        user: user,
        cart: [],
        notificationData: [],
      });
      if (user) {
        this.props.onLocaleChange(user.preferredLanguage);
        CartService.getCart().then((data) => {
          this.setState({
            cart: data.items || [],
            cartSubTotal: data.subTotal || "",
            cartQuantity: data.quantityTotal || "",
          });
        }).catch((e) => {
          //Adding empty catch to prevent webpack unhandled exception msg
        });
      }
      this.fetchNotifications();
    };

    this.setNotificationDisplay = (bool) => {
      this.setState({
        displayNotification: bool,
      });
    };

    this.dismissNotification = (notification) => {
      let newArray = this.state.notificationData.slice();
      let length = newArray.length;

      for (let i = 0; i < length; i++) {
        let item = newArray[i];
        if (item.id === notification.id) {
          item.dismissed = true;
          this.setState({
            notificationData: newArray,
          });
        }
      }
    };

    this.addToCart = (item) => {
      try {
        let cart = this.state.cart.concat(item);
        return CartService.updateCart(cart).then((data) => {
          this.setState({
            cart: data.items,
            cartSubTotal: data.subTotal,
            cartQuantity: data.quantityTotal,
          });
        });
      } catch (e) {
        console.log(e);
      }
    };

    this.removeFromCart = (skuid) => {
      let newCart = this.state.cart.filter((item) => item.skuid !== skuid);

      return CartService.updateCart(newCart).then((data) => {
        this.setState({
          cart: data.items,
          cartSubTotal: data.subTotal,
          cartQuantity: data.quantityTotal,
        });
        return data.items;
      }).catch((e) => {
        //Adding empty catch to prevent webpack unhandled exception msg
      });
    };

    this.updateCart = (updateItem) => {
      let newCart = this.state.cart.slice();
      for (let i = 0; i < newCart.length; i++) {
        let item = newCart[i];

        if (item.skuid === updateItem.skuid) {
          item.quantity = updateItem.quantity;
          break;
        }
      }
      this.setState({
        cart: newCart,
      });
    };

    this.saveCart = (cart) => {
      return CartService.updateCart(cart || this.state.cart).then((data) => {
        this.setState({
          cart: data.items,
          cartSubTotal: data.subTotal,
          cartQuantity: data.quantityTotal,
        });
        return data.items;
      }).catch((e) => {
        //Adding empty catch to prevent webpack unhandled exception msg
      });
    };

    this.emptyCart = () => {
      return CartService.updateCart([]).then((data) => {
        this.setState({
          cart: data.items,
          cartSubTotal: data.subTotal,
          cartQuantity: data.quantityTotal,
        });
        return data.items;
      }).catch((e) => {
        //Adding empty catch to prevent webpack unhandled exception msg
      });
    };

    this.setCartDisplay = (bool) => {
      this.setState({
        displayCart: bool,
      });
    };

    this.state = {
      user: null,
      setUser: this.setUser,
      cart: [],
      cartSubTotal: "",
      cartQuantity: "",
      notificationData: [],
      addToCart: this.addToCart,
      removeFromCart: this.removeFromCart,
      displayCart: false,
      displayNotification: false,
      dismissNotification: this.dismissNotification,
      setNotificationDisplay: this.setNotificationDisplay,
      setCartDisplay: this.setCartDisplay,
      emptyCart: this.emptyCart,
      updateCart: this.updateCart,
      saveCart: this.saveCart,
    };
  }

  componentDidMount() {
    const token = AuthService.getAuthToken();

    if (token) {
      AuthService.addAuthTokenHeader(token);
      AuthService.addAuthTokenResponseInterceptor();

      UserService.getUser()
        .then((user) => {
          this.setUser(user);
        })
        .catch((e) => {
          this.setUser(null);
        });
    } else {
      this.fetchNotifications();
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.state;
    const { locale } = this.props;

    if (prevProps.locale !== locale) {
      if (user) {
        const { preferredLanguage } = user;
        const lang = locale.split(/[-_]+/)[0];
        if (lang !== preferredLanguage) {
          UtilService.setLanguage(lang).then((d) => {
            this.setUser(d);
          }).catch((e) => {
            //Adding empty catch to prevent webpack unhandled exception msg
          });
        }
      } else {
        this.fetchNotifications();
      }
    }
  }

  fetchNotifications() {
    NotificationService.getNotifications({
      headers: { "Accept-Language": this.props.locale.replace("_", "-") },
    }).then((d) => {
      this.setState({
        notificationData: d,
      });
    }).catch((e) => {
      //Adding empty catch to prevent webpack unhandled exception msg
    });
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const UserConsumer = Context.Consumer;
