import React from "react";

import {
  ACDelcoLogo,
  Alert,
  AlertMixin,
  TranslationMixin,
  WithCommonTranslations,
  UtilService,
  Header,
  HelperUtils,
} from "tds_shared_ui";
import { BillingInfo, PlaceOrder, Confirmation } from "../components/LMS";
import { WithLMSTranslations } from "../components/hocs";
import { LMSService } from "../services";

import "./LMSCheckoutPage.css";

const steps = [
  { component: BillingInfo },
  { component: PlaceOrder },
  { component: Confirmation },
];

export const LMSCheckoutPage = WithLMSTranslations(
  WithCommonTranslations(
    class LMSCheckoutPage extends AlertMixin(
      TranslationMixin(React.Component)
    ) {
      constructor(props) {
        super(props);

        this.topRef = React.createRef();
        this.alertRef = React.createRef();

        this.state = {
          currentStep: 0,
          paymentInfo: null,
          lmsData: null,
          checkoutData: null,
          confirmationData: null,
          loading: false,
          formValues: {
            email: "",
            billingAddressLine1: "",
            billingAddressLine2: "",
            billingFirstName: "",
            billingLastName: "",
            billingZipCode: "",
            billingCity: "",
            billingState: "",
            billingCountry: "US",
            billingPhone: "",
          },
          alert: {
            display: false,
            type: "default",
            message: "",
          },
        };

        this.handleNext = this.handleNext.bind(this);
        this.setPaymentInfo = this.setPaymentInfo.bind(this);
      }

      componentDidMount() {
        //hardcode to US states
        UtilService.getCountries().then((d) => {
          for (let i = 0; i < d.length; i++) {
            if (d[i].countryCode === "US") {
              this.setState({
                states: this.getStates(d[i].states),
              });
              break;
            }
          }
        }).catch((e) => {
          //Adding empty catch to prevent webpack unhandled exception msg
        });

        const queryString = "paymentId=";
        let query = window.location.search;
        let rest = query.substring(
          query.indexOf(queryString) + queryString.length
        );
        const paymentId = rest.split("&")[0];

        LMSService.getData(paymentId)
          .then((d) => {
            this.setState((prevState) => {
              let formValues = Object.assign({}, prevState.formValues);
              formValues.billingFirstName = d.firstName;
              formValues.billingLastName = d.lastName;
              formValues.email = d.email;
              return {
                lmsData: d,
                formValues: formValues,
              };
            });
          })
          .catch((e) => {
            this.displayError(e);
          });
      }

      scrollToAlert() {
        HelperUtils.scrollToAlert(this.alertRef);
      }

      handleNext() {
        let func = null;
        switch (this.state.currentStep) {
          case 0:
            func = this.prepareCheckout.bind(this);
            break;
          case 1:
            func = this.placeOrder.bind(this);
            break;
          default:
        }
        if (func) {
          this.setState({
            loading: true,
          });
          func()
            .then(() => {
              this.setState(
                (prevState) => ({
                  currentStep: prevState.currentStep + 1,
                  loading: false,
                }),
                () => HelperUtils.scrollToDomRef(this.topRef)
              );
            })
            .catch((e) => {
              this.displayError(e, this.scrollToAlert);
              this.setState({ loading: false });
            });
        }
      }

      prepareCheckout() {
        return LMSService.prepareCheckout(this.getCheckoutData()).then((d) => {
          this.setState({
            checkoutData: d,
          });
        }).catch((e) => {
          //Adding empty catch to prevent webpack unhandled exception msg
        });
      }

      placeOrder() {
        let request = Object.assign({}, this.state.checkoutData);
        request.sessionId = this.state.paymentInfo.sessionId;
        request.paymentId = this.state.lmsData.paymentId;

        return LMSService.placeOrder(request).then((d) => {
          this.setState({
            confirmationData: d,
          });
        }).catch((e) => {

        });
      }

      getCheckoutData() {
        const { formValues, lmsData } = this.state;
        return {
          paymentId: lmsData.paymentId,
          email: formValues.email,
          billingFirstName: formValues.billingFirstName,
          billingLastName: formValues.billingLastName,
          billingPhoneNumber: formValues.billingPhone,
          currencyCode: "USD",
          billingAddress: {
            addressLine1: formValues.billingAddressLine1,
            addressLine2: formValues.billingAddressLine2,
            city: formValues.billingCity,
            state: formValues.billingState,
            zipCode: formValues.billingZipCode,
            country: "US",
          },
        };
      }

      setPaymentInfo(paymentData) {
        this.setState({
          paymentInfo: paymentData,
        });
      }

      getStates(data) {
        let states = [];
        for (let state of data) {
          states.push({ label: state.name, value: state.stateCode });
        }
        return states.sort(function (a, b) {
          if (a.label < b.label) {
            return -1;
          }

          if (a.label > b.label) {
            return 1;
          }

          return 0;
        });
      }

      handleChange = (name, value, callback) => {
        this.setState((prevState) => {
          let newState = {};
          let formValues = Object.assign({}, prevState.formValues);
          formValues[name] = value;
          newState.formValues = formValues;
          return newState;
        }, callback);
      };

      render() {
        const {
          currentStep,
          states,
          formValues,
          confirmationData,
          checkoutData,
          loading,
          lmsData,
        } = this.state;
        let StepComponent = steps[currentStep].component;
        return (
          <div className="lmsCheckoutPage App">
            <div className="tdsNavbar">
              <ACDelcoLogo imageAlt={this.getTranslation("LABEL_LOGO")} />
            </div>
            <div className="container" ref={this.topRef}>
              <Header level={1}>
                {this.getTranslation("HEADER_CHECKOUT")}
              </Header>

              {this.state.alert.display && (
                <Alert ref={this.alertRef} type={this.state.alert.type}>
                  {this.state.alert.message}
                </Alert>
              )}

              {lmsData && (
                <StepComponent
                  loading={loading}
                  formValues={formValues}
                  onChange={this.handleChange}
                  states={states}
                  setPaymentInfo={this.setPaymentInfo}
                  confirmationData={confirmationData}
                  checkoutData={checkoutData}
                  onNext={this.handleNext}
                />
              )}
            </div>
          </div>
        );
      }
    }
  )
);
