import React from "react";

import { Button } from "tds_shared_ui";
import { LoginForm } from "./LoginForm";
import { WithCommonTranslations } from "./../hocs";
import { TranslationMixin, Panel } from "tds_shared_ui";
import { NimbusLink as Link } from "tds_shared_ui";
import "./Login.css";

export const Login = WithCommonTranslations(
  class extends TranslationMixin(React.Component) {
    constructor(props) {
      super(props);

      this.node = React.createRef();

      this.state = {
        hidden: true,
      };

      this.hide = this.hide.bind(this);
      this.togglePanel = this.togglePanel.bind(this);
    }

    hide() {
      this.setState({ hidden: true });
    }

    togglePanel() {
      this.setState((prevState) => {
        return { hidden: !prevState.hidden };
      });
    }

    render() {
      const { className } = this.props;
      const displayClass = (
        "login-container " + (className ? className : "")
      ).trim();

      return (
        <div className={displayClass} ref={this.node}>
          <Link href="#" onClick={this.togglePanel}>
            {this.getTranslation("LABEL_LOGIN")}
          </Link>
          <Panel
            floating={true}
            hidden={this.state.hidden}
            className="login-panel"
          >
            <div>
              <Button
                type="button"
                variant="link"
                className="float-right close-btn"
                onClick={this.hide}
              >
                <i
                  className="fa fa-times"
                  aria-label={this.getTranslation("BUTTON_CLOSE")}
                ></i>
              </Button>
            </div>
            <LoginForm
              className="login-popover"
              locale={this.props.locale}
              onHideTerms={this.hide}
              onClick={this.hide}
            />
          </Panel>
        </div>
      );
    }
  }
);
