import React from "react";

import { WithCommonTranslations } from "tds_shared_ui";
import {
  Alert,
  Table,
  LoadingIcon,
  Button,
  Header,
  CountDownTimer,
} from "../UI";
import { KeycodesService } from "../../services";
import { WithRegion, WithKeyCodeTranslations } from "../hocs";
import { TranslationMixin, Modal } from "tds_shared_ui";
import { ViewKeycode } from "./ViewKeycode";

export const KeycodesTable = WithRegion(
  WithCommonTranslations(
    WithKeyCodeTranslations(
      class extends TranslationMixin(React.Component) {
        constructor(props) {
          super(props);
          this.state = {
            loading: false,
            alert: {
              display: false,
              type: "info",
              message: "",
            },
            columns: "",
            keyData: "",
            showKeycodesDisplay: false,
            dataKeycode: "",
          };

          this.viewKeycodeDisplay = this.viewKeycodeDisplay.bind(this);
          this.handleCancel = this.handleCancel.bind(this);
          this.handleTimeExpired = this.handleTimeExpired.bind(this);
        }

        componentDidMount() {
          this.setState({ loading: true });
          KeycodesService.getKeycodesRecent()
            .then((data) => {
              this.setState({
                loading: false,
                keyData: data,
              });
            })
            .catch((error) => {
              this.setState({
                loading: false,
                alert: {
                  display: true,
                  type: "error",
                  message: error,
                },
              });
            });
        }

        viewKeycodeDisplay(keys) {
          this.setState({ showKeycodesDisplay: true, loading: true });
          KeycodesService.getKeycodes(keys.id, Intl.DateTimeFormat().resolvedOptions().timeZone)
            .then((data) => {
              this.setState({
                loading: false,
                dataKeycode: data,
              });
            })
            .catch((error) => {
              console.log("Keycode error is ", error);
            })
            .finally(() => {
              this.setState({
                loading: false,
              });
            });
        }

        handleCancel() {
          this.setState({ showKeycodesDisplay: false });
        }

        handleTimeExpired() {
          this.setState({
            showKeycodesDisplay: false,
            timerExpired: true,
          });
        }

        render() {
          const { keyData } = this.state;
          const keycodesData = [];
          for (let keycodeData of keyData) {
            keycodesData.push({
              customerName: keycodeData.customerName,
              vin: keycodeData.vin,
              plateNumber: keycodeData.plateNumber,
              id: keycodeData.id,
              stateRegistration: keycodeData.stateRegistration,
            });
          }

          const cols = [
            {
              header: this.getTranslation("LABEL_OWNER_NAME"),
              accessor: "customerName",
            },
            {
              header: this.getTranslation("LABEL_VIN"),
              accessor: "vin",
            },
            {
              header: this.getTranslation("LABEL_LICENSE_PLATE"),
              accessor: "plateNumber",
            },
            {
              header: this.getTranslation("LABEL_STATE_REGISTERED"),
              accessor: "stateRegistration",
            },
            {
              header: this.getTranslation("LABEL_ACTIONS"),
              accessor: "action",
              render: (data) => {
                return (
                  <Button
                    variant="link"
                    onClick={() => this.viewKeycodeDisplay(data)}
                  >
                    {this.getTranslation("BUTTON_VIEW_KEYCODES")}
                  </Button>
                );
              },
            },
          ];

          return (
            <div>
              {this.state.alert.display && (
                <Alert type={this.state.alert.type}>
                  {this.state.alert.message}
                </Alert>
              )}
              <Table
                columns={cols}
                rows={keycodesData}
                filterId="myKeycodesFilter"
                tableMessage={this.getTranslation(
                  "MESSAGE_VIEW_RECENT_KEYCODES"
                )}
              />

              {this.state.showKeycodesDisplay && (
                <Modal
                  header={
                    <Header level={1}>
                      {this.getTranslation("HEADER_VIEW_KEYCODE")}
                    </Header>
                  }
                  onClose={this.handleCancel}
                  open={this.state.showKeycodesDisplay}
                  onBackdropClick={this.handleCancel}
                >
                  {this.state.loading ? (
                    <LoadingIcon display={this.state.loading} />
                  ) : (
                    <div className="keycode-activate">
                      <Alert
                        type="warning"
                        className="countdown-message"
                        ref={this.timerAlertRef}
                      >
                        <div className="overflow-hidden">
                          {this.getTranslation(
                            "WARNING_KEYCODES_TIMER_VIEWABLE"
                          )}
                          &nbsp;
                          <b>
                            {this.getTranslation("LABEL_TIME_REMAINING")}&nbsp;
                            <CountDownTimer
                              secondsRemaining="60"
                              onTimeExpired={this.handleTimeExpired}
                            />
                          </b>
                        </div>
                      </Alert>

                      <ViewKeycode
                        open={this.state.showKeycodesDisplay}
                        dataKeycode={this.state.dataKeycode}
                      />
                    </div>
                  )}
                </Modal>
              )}
            </div>
          );
        }
      }
    )
  )
);
