import React from 'react';

import {Link as RouterLink} from 'react-router-dom';

export class Link extends React.Component {

  render() {
    const {to, className, ...otherProps} = this.props;

    return (
        <RouterLink to={to} className={((className ? className : '') +
            ' link').trim()} {...otherProps}>{this.props.children}</RouterLink>
    );
  }
}