import React from 'react';

import {
  Alert,
  Button,
  TranslationMixin,
  WithCommonTranslations,
} from 'tds_shared_ui';
import {OrderInvoiceModal} from '../UI';
import {WithLMSTranslations} from '../hocs';

export const Confirmation = WithLMSTranslations(WithCommonTranslations(
    class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);

        this.state = {
          showInvoice: false,
        };
        this.handleShowInvoice = this.handleShowInvoice.bind(this);
        this.handleCloseInvoice = this.handleCloseInvoice.bind(this);
      }

      handleShowInvoice() {
        this.setState({
          showInvoice: true,
        });
      }

      handleCloseInvoice() {
        this.setState({
          showInvoice: false,
        });
      }

      render() {
        const {confirmationData} = this.props;
        return (
            <div>
              <Alert type="success">{this.getTranslation(
                  'MESSAGE_SUCCESSFUL_TRANSACTION')}</Alert>

              <div className="d-flex align-items-center">
                {this.getTranslation(
                    'LABEL_TRANSACTION_ID')}: {confirmationData.confirmationNumber}
                <Button variant='link'
                        onClick={this.handleShowInvoice}>{this.getTranslation(
                    'LABEL_RECEIPT')}</Button>
              </div>

              <div>
                <a href={confirmationData.redirectUrl}
                   className="btn btn-primary mt-2">{this.getTranslation(
                    'BTN_FINISH')}</a>
              </div>

              <OrderInvoiceModal
                  orderDetails={this.props.confirmationData.receipt}
                  open={this.state.showInvoice}
                  onClose={this.handleCloseInvoice}/>

            </div>
        );
      }
    }));