import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { LoadingIcon } from "./components/UI";

import { ErrorPage } from "./containers/ErrorPage";
import { LMSCheckoutPage } from "./containers/LMSCheckoutPage";
import {
  LinkProvider,
  LMSTranslationsProvider,
  RegionProvider,
  UserProvider,
} from "./context";
import { UtilService } from "./services";
import { AppRouter } from "./AppRouter";

import { CommonTranslationsProvider } from "tds_shared_ui";
import "./App.css";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingRegion: true,
      regionData: null,
      error: null,
      locale: null,
      showLmsCheckout: false,
    };

    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  componentDidMount() {
    let tdslang = sessionStorage.getItem("tdslang");

    if (window.location.pathname === "/lms") {
      this.setState({
        showLmsCheckout: true,
        locale: "en",
      });

      //Need region for LMS to grab merchantId for payment widget
      UtilService.getRegion().then((data) => {
        this.setState({
          regionData: data,
        });
      }).catch((e) => {
        //Adding empty catch to prevent webpack unhandled exception msg
      });
    } else {
      this.setState({
        loadingRegion: true,
      });

      UtilService.getRegion().then(
        (data) => {
          const lang = data.locale.split(/[-_]+/)[0];

          this.setState({
            loadingRegion: false,
            regionData: data,
            locale: tdslang || lang,
          });
        },
        (error) => {
          //We don't know their region and therefore don't know their language preferences
          //If backend is down or translations call fails, we can't  pull translations anyway
          let errorMsg =
            "Unknown error occurred. Please refresh page to try again.";

          if ("REGION" === error.category) {
            errorMsg =
              "ACDelco Technical Delivery System is not supported in your region";
          }

          this.setState({
            loadingRegion: false,
            error: errorMsg,
          });
        }
      ).catch((e) => {
        //Adding empty catch to prevent webpack unhandled exception msg
      });
    }
  }

  handleLanguageChange(langCode) {
    sessionStorage.setItem("tdslang", langCode);

    UtilService.updateRegion().then((data) => {
      this.setState({
        regionData: data,
        locale: langCode,
      });
    }).catch((e) => {
      //Adding empty catch to prevent webpack unhandled exception msg
    });

    // this.setState({
    //   locale: langCode,
    // });
  }

  renderNotSupported() {
    return (
      <CommonTranslationsProvider locale={this.state.locale}>
        <ErrorPage error={this.state.error} />
      </CommonTranslationsProvider>
    );
  }

  renderLoading() {
    return (
      <div className="container">
        <LoadingIcon display={true} size="3x" />
      </div>
    );
  }

  renderLmsCheckout() {
    return (
      <RegionProvider regionData={this.state.regionData}>
        <LMSTranslationsProvider locale={this.state.locale}>
          <CommonTranslationsProvider locale={this.state.locale}>
            <LMSCheckoutPage />
          </CommonTranslationsProvider>
        </LMSTranslationsProvider>
      </RegionProvider>
    );
  }

  render() {
    if (this.state.showLmsCheckout) {
      return this.renderLmsCheckout();
    } else if (this.state.loadingRegion) {
      return this.renderLoading();
    } else if (this.state.regionData === null) {
      return this.renderNotSupported();
    } else {
      return this.renderApp();
    }
  }

  renderApp() {
    return (
      <Router>
        <UserProvider
          onLocaleChange={this.handleLanguageChange}
          locale={this.state.locale}
        >
          <RegionProvider
            regionData={this.state.regionData}
            locale={this.state.locale}
          >
            <LinkProvider locale={this.state.locale}>
              <CommonTranslationsProvider locale={this.state.locale}>
                <AppRouter
                  onLanguageChange={this.handleLanguageChange}
                  locale={this.state.locale}
                  regionData={this.state.regionData}
                />
              </CommonTranslationsProvider>
            </LinkProvider>
          </RegionProvider>
        </UserProvider>
      </Router>
    );
  }
}
