import GenericAjaxService from './GenericAjaxService';
import {privateAPI} from './api';

export default class KeycodesService extends GenericAjaxService {

  static purchaseKeycodes(vin, customerName, plateNumber, subtotal){
    const data = {vin: vin, customerName: customerName, plateNumber: plateNumber, subtotal: subtotal};
    return super.postAPI(privateAPI, `keycodes`, data);
  }

  static prepareCheckout(data){
    return super.postAPI(privateAPI, `keycodes/prepareCheckout`, data);
  }

  static getCatalog(){
    return super.getAPI(privateAPI, `keycodes/catalog`);
  }

  static purchase(data){
    return super.postAPI(privateAPI, `keycodes/purchase`, data);
  }

  static getKeycodes(id, data){
    return super.postAPI(privateAPI, `keycodes/${id}`, data);
  }

  static getKeycodesRecent(){
    return super.getAPI(privateAPI, `keycodes/recent`);
  }

  static validateVin(user) {
    return super.postAPI(privateAPI, `keycodes/validate`, user)
  }

  static getSummary(){
    return new Promise((resolve, reject) =>{
      KeycodesService.getCatalog().then(data => {
        if (data.length === 1){
          let catalog = data[0];
          if (catalog.skus.length === 1) {
            let sku = catalog.skus[0];
            let item = {
              id: sku.id,
              name: sku.name,
              itemTotal: sku.priceDisplay,
              originalTotal: sku.priceDisplay,
              quantity: sku.attributeValue
            };
            let summary = {
              items: [item],
              totalAmount: sku.priceDisplay,
              currencyCode: sku.currencyCode
            };
            resolve(summary);
          }
        }
        reject("invalid catalog");
      }).catch(error => {
        reject(error);
      })
    })
  }

  static remainingPurchases(){
      return super.getAPI(privateAPI, `keycodes/remainingPurchases`);
  }
}