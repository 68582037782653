import React from "react";

import { Alert, Button, FormInput, Header, LoadingIcon } from "../UI";
import {
  WithCommonTranslations,
  WithFormValidation,
  WithKeyCodeTranslations,
  WithRegion,
  WithUser,
} from "../hocs";
import { TranslationMixin, Modal } from "tds_shared_ui";

export const LocksmithIDLoginModal = WithRegion(
  WithUser(
    WithKeyCodeTranslations(
      WithCommonTranslations(
        class extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);
            this.state = {
              loading: false,
              disabled: false,

              formValues: {
                locksmithID: "",
                passcode: "",
              },

              alert: {
                display: false,
                type: "default",
                message: "",
              },
            };
            this.handleSubmit = this.handleSubmit.bind(this);
            this.resetForm = this.resetForm.bind(this);
            this.handleSuccess = this.handleSuccess.bind(this);
            this.handleCancel = this.handleCancel.bind(this);
          }

          componentDidMount() {
            if (this.props.user) {
              this.setState({
                formValues: {
                  locksmithID: this.props.user.locksmithID,
                },
              });
            }
          }

          handleCancel() {
            this.setState({
              alert: {
                display: false,
                type: "default",
                message: "",
              },
            });
            this.props.onCancel();
            this.resetForm();
          }

          handleSuccess() {
            this.setState({
              alert: {
                display: false,
                type: "default",
                message: "",
              },
            });
            this.props.onSuccess();
          }

          handleChange = (event) => {
            const target = event.target;
            const value =
              target.type === "checkbox" ? target.checked : target.value;
            const name = target.name;
            this.setState((prevState) => {
              let newState = {};
              let formValues = Object.assign({}, prevState.formValues);
              formValues[name] = value;
              newState.formValues = formValues;
              return newState;
            });
          };

          handleSubmit() {
            this.setState({
              loading: true,
              disabled: true,
              alert: {
                display: false,
              },
            });
            this.props
              .onSubmit(this.state.formValues.passcode)
              .catch((error) => {
                this.setState({
                  loading: false,
                  disabled: false,
                  alert: {
                    display: true,
                    type: "error",
                    message: error.translatedMessage,
                  },
                });
              })
              .finally(() => {
                this.setState({
                  loading: false,
                  disabled: false,
                  alert: {
                    display: false,
                  },
                });
              });
            this.resetForm();
          }

          resetForm() {
            this.setState((prevState) => {
              return {
                formValues: {
                  passcode: "",
                  locksmithID: prevState.formValues.locksmithID,
                },
              };
            });
          }

          render() {
            let regionCode = this.props.regionData.regionCode;
            let headerTitle;
            if (regionCode === "AU") {
              headerTitle = this.getTranslation("LABEL_AASRA_CREDENTIAL");
            } else {
              headerTitle = this.getTranslation(
                "LABEL_VEHICLE_SECURITY_CREDENTIAL"
              );
            }

            return (
              <Modal
                header={<Header level={1}>{headerTitle}</Header>}
                onClose={this.handleCancel}
                open={this.props.open}
                onBackdropClick={this.handleCancel}
              >
                <div className="container">
                  {this.state.alert.display && (
                    <Alert ref={this.alertRef} type={this.state.alert.type}>
                      {this.state.alert.message}
                    </Alert>
                  )}

                  <div className="w-100">
                    <LocksmithLoginForm
                      formValues={this.state.formValues}
                      readOnly={this.state.readOnly}
                      onChange={this.handleChange}
                      onCancel={this.handleCancel}
                      loading={this.state.loading}
                      submit={this.handleSubmit}
                      submitButtonText={this.props.submitButtonText}
                      regionCode={regionCode}
                    />
                  </div>
                </div>
              </Modal>
            );
          }
        }
      )
    )
  )
);

const LocksmithLoginForm = WithFormValidation(
  WithUser(
    WithKeyCodeTranslations(
      WithCommonTranslations(
        class extends TranslationMixin(React.Component) {
          render() {
            const {
              formValues,
              isValidated,
              onChange,
              onCancel,
              loading,
              submitButtonText,
            } = this.props;
            let locksmithLabel;
            let idValue = formValues.locksmithID;

            if (this.props.regionCode === "AU") {
              locksmithLabel = this.getTranslation("LABEL_AASRA_ID");
            } else {
              locksmithLabel = this.getTranslation("LABEL_VSC_ID");
            }
            return (
              <React.Fragment>
                <FormInput
                  name="locksmithID"
                  id="locksmithID"
                  label={locksmithLabel}
                  readOnly={true}
                  value={idValue}
                />

                <FormInput
                  name="passcode"
                  id="passcode"
                  label={this.getTranslation("LABEL_PASSCODE")}
                  type="password"
                  autoComplete="off"
                  value={formValues.passcode}
                  required={true}
                  displayError={isValidated}
                  onChange={onChange}
                />

                <div className="float-right">
                  <Button type="button" variant="link" onClick={onCancel}>
                    {this.getTranslation("BUTTON_CANCEL")}
                  </Button>

                  <Button type="submit" variant="primary" disabled={loading}>
                    <LoadingIcon display={loading} />
                    {submitButtonText}
                  </Button>
                </div>
              </React.Fragment>
            );
          }
        }
      )
    )
  )
);
