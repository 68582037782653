import GenericAjaxService from './GenericAjaxService';
import {publicAPI, privateAPI} from './api';
import moment from 'moment-timezone';

export default class UtilService extends GenericAjaxService {

  static getRegion() {
    const timezone = moment.tz.guess();
    return super.getAPI(publicAPI, `region?zoneId=${timezone}`);
  }

  static updateRegion() {
    const timezone = moment.tz.guess();
    const tdslang = sessionStorage.getItem('tdslang');
    return super.getAPI(publicAPI, `region?zoneId=${timezone}&tdslang=${tdslang}`);
  }

  static setLanguage(language){
    return super.postAPI(privateAPI, `user/language/${language}`);
  }

}