import React from 'react';

export class CountDownTimer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      secondsRemaining: 0
    }
  }

  tick = () => {
    this.setState({
      secondsRemaining: this.state.secondsRemaining - 1
    });
    if (this.state.secondsRemaining <= 0) {
      clearInterval(this.interval);
      this.props.onTimeExpired();
    }
  };

  componentDidMount() {
    this.setState({
      secondsRemaining: this.props.secondsRemaining
    });
    this.interval = setInterval(this.tick, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <span>
        {this.state.secondsRemaining}
      </span>
    )
  }
}