import React from "react";
import { Validators } from "tds_shared_ui";
import { Button, FormInput } from "../UI";
import { WithCommonTranslations } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import "./ShoppingCart.css";

export const EditableCartItem = WithCommonTranslations(
  class extends TranslationMixin(React.Component) {
    constructor(props) {
      super(props);
      this.formRef = React.createRef();
      this.state = {
        buttonDisplay: false,
        loadingUpdate: false,
        displayError: false,
      };
      this.handleRemove = this.handleRemove.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleUpdate = this.handleUpdate.bind(this);
      this.onlyNumbersOnKeyPress = Validators.onlyNumbersOnKeyPress.bind(this);
    }

    handleRemove() {
      return this.props.onRemove(this.props.item.skuid);
    }

    handleInputChange = (event) => {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;

      let updateItem = { ...this.props.item };
      updateItem.quantity = value;
      this.props.onUpdate(updateItem);
    };

    handleUpdate() {
      this.setState({
        loadingUpdate: true,
        displayError: false,
      });
      return this.props
        .onSave()
        .then(() => {
          this.setState({
            loadingUpdate: false,
          });
        })
        .catch((error) => {
          this.setState({
            loadingUpdate: false,
            displayError: true,
          });
        });
    }

    render() {
      let { item } = this.props;
      let form = this.formRef.current;
      let valid = form ? form.checkValidity() : false;

      let displayQuantity = item && item.type.toLowerCase() !== "download";

      return (
        <form noValidate ref={this.formRef}>
          <div>
            <div className="row mb-4">
              <div className="col-sm-12 col-md-7">
                <div>
                  <span className="font-weight-bold">{item.name}</span> -{" "}
                  {item.skuDesc}
                </div>
                <div>{item.shortDescription}</div>
                <Button
                  type="button"
                  onClick={this.handleRemove}
                  variant="link"
                  className="p-0"
                >
                  {this.getTranslation("BUTTON_REMOVE")}
                </Button>
              </div>

              <div className="col-sm-6 col-md-2 col-6">{item.price}</div>

              {displayQuantity && (
                <div className="col-sm-6 col-md-3 col-6">
                  <div className="d-inline-flex align-items-start">
                    {this.getTranslation("LABEL_QUANTITY")}:
                    <div className="pl-3 tds-quantity">
                      <FormInput
                        id=""
                        name={`${item.skuid}-qty`}
                        type="number"
                        disabled={this.state.loadingUpdate}
                        step={1}
                        min={1}
                        max={item.maxQuantity}
                        autoComplete="off"
                        size={2}
                        displayError={true}
                        pattern="^[0-9]{1,2}$"
                        onKeyUp={this.showUpdate}
                        onKeyPress={this.onlyNumbersOnKeyPress}
                        onChange={this.handleInputChange}
                        value={item.quantity}
                      ></FormInput>
                    </div>
                    {this.props.showUpdate && (
                      <Button
                        type="button"
                        onClick={this.handleUpdate}
                        disabled={!valid}
                        variant="link"
                        className="pt-0 pb-0 pr-0"
                      >
                        {this.getTranslation("BUTTON_UPDATE")}
                      </Button>
                    )}
                    {this.state.displayError && (
                      <div className="ml-1">
                        <i className="fa fa-exclamation-triangle"></i>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      );
    }
  }
);
