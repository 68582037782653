import React from "react";
import {
  Header,
  Button,
  PaymentMethod,
  OrderSummary,
  OrderInvoiceModal,
} from "../UI";
import { TranslationMixin, withRouter } from "tds_shared_ui";
import {
  WithUser,
  WithCommonTranslations,
  WithCheckoutTranslations,
} from "../hocs";

export const Confirmation = withRouter(
  WithUser(
    WithCommonTranslations(
      WithCheckoutTranslations(
        class Confirmation extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);
            this.state = {
              loading: false,
              showInvoice: false,
            };

            this.handlePrintInvoice = this.handlePrintInvoice.bind(this);
            this.handleCloseInvoice = this.handleCloseInvoice.bind(this);
            this.handleComplete = this.handleComplete.bind(this);
          }

          handlePrintInvoice() {
            this.setState({
              showInvoice: true,
            });
          }

          handleCloseInvoice() {
            this.setState({
              showInvoice: false,
            });
          }

          handleComplete() {
            this.props.navigate("/subscriptions");
          }

          render() {
            const { orderConfirmation } = this.props;
            const { receipt } = orderConfirmation;
            let showPayment =
              receipt.cardBrand ||
              receipt.cardLast4Digits ||
              receipt.cardHolderName;

            return (
              <div>
                <div className="checkout-confirmation row">
                  <div className="col">
                    <div className="mb-3">
                      <div className="border-bottom mb-3">
                        <div className="row">
                          <Header level={2} className="col">
                            {this.getTranslation("HEADER_CONFIRMATION")}
                          </Header>
                          <Button
                            variant="link"
                            className="pb-0 pt-0"
                            onClick={this.handlePrintInvoice}
                          >
                            {this.getTranslation("LINK_PRINT_RECEIPT")}
                          </Button>
                        </div>
                      </div>

                      <div className="divIcon">
                        <div className="mb-5">
                          <div className="sideIcon">
                            <i className="fas fa-check fa-pull-left text-muted"></i>
                          </div>

                          <div>
                            {this.getTranslation("MESSAGE_THANKS_FOR_ORDER1")}{" "}
                            {orderConfirmation.confirmationNumber}
                          </div>
                        </div>

                        {/*Payment*/}
                        {showPayment && (
                          <div className="mb-5">
                            <div className="border-bottom mb-3">
                              <Header level={2}>
                                {this.getTranslation("LABEL_PAYMENT_METHOD")}
                              </Header>
                            </div>
                            <PaymentMethod
                              cardBrand={receipt.cardBrand}
                              cardLast4Digits={receipt.cardLast4Digits}
                              cardHolderName={receipt.cardHolderName}
                            />
                          </div>
                        )}

                        <div className="mb-5">
                          <div className="border-bottom mb-3">
                            <Header level={2}>
                              {this.getTranslation("HEADER_EMAIL_FOR_ORDER")}
                            </Header>
                          </div>

                          <div className="row">
                            <div className="col-sm-6">
                              {this.props.formValues.email}
                              {this.props.formValues.additionalEmails.map(
                                (e) => (
                                  <React.Fragment key={e}>
                                    <br />
                                    {e}
                                  </React.Fragment>
                                )
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="mb-5">
                          <div className="border-bottom mb-3">
                            <Header level={2}>
                              {this.getTranslation("HEADER_ORDER_SUMMARY")}
                            </Header>
                          </div>
                          <OrderSummary
                            className="basic"
                            receipt={orderConfirmation.receipt}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-right">
                  <Button variant="primary" onClick={this.handleComplete}>
                    {this.getTranslation("BUTTON_GO_TO_SUBSCRIPTIONS")}
                  </Button>
                </div>

                <OrderInvoiceModal
                  orderDetails={this.props.orderConfirmation.receipt}
                  open={this.state.showInvoice}
                  onClose={this.handleCloseInvoice}
                />
              </div>
            );
          }
        }
      )
    )
  )
);
