import React from 'react';

export class PaymentOption extends React.Component {
    render(){
        const {id, value, label, subtext, imgSrc, checked, onChange} = this.props;
        let optionId = `gmpayui-${id}`;
        let imgArray = null;
        if (imgSrc){
            if (Array.isArray(imgSrc)){
                imgArray = imgSrc;
            } else {
                imgArray = [imgSrc];
            }
        }
        return (
            <div className="gmpayui-payment-option">
                <input type="radio" name="gmpayui-payment-option"
                       id={optionId}
                       checked={checked}
                       onChange={onChange}
                       value={value}/>
                <label htmlFor={optionId}>
        <span className="gmpayui-payment-text">
          <span className="gmpayui-payment-label">{label}</span>
            {subtext && <span className="gmpayui-payment-subtext">{subtext}</span>}
        </span>
                    {imgArray &&
                        <span className="gmpayui-payment-images">
          {imgArray.map(
              imgSrc => <img key={imgSrc} className="gmpayui-payment-img"
                             src={imgSrc}/>)}
        </span>
                    }
                </label>
            </div>
        );
    }
}