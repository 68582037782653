import {privateAPI} from './api';

export default class RpoLookupService {
  static validateLogin(){
    return this.getAPI(privateAPI, `rpoLookup/accessRPOLookup`);
  }

  static requestVinRPO(vin){
      return this.getAPI(privateAPI, `rpoLookup/requestVinRPO?vin=${vin}`);
  }

  static checkCaptcha(captchaData){
    return this.postAPI(privateAPI, `rpoLookup/checkCaptcha`, captchaData);
  }

  //Had to add this method from genericAjaxService class to add scenario when 404 is received from backend
  static getAPI(API, url, config) {
    return new Promise((resolve, reject) => {
      API.get(url, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else if (error.message){
            reject(error.message);
          } else {
            reject(error);
          }
        });
    });
  }

  static postAPI(API, url, data, config) {
    return new Promise((resolve, reject) => {
      API.post(url, data, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data);
          } else if (error.message) {
            reject(error.message);
          } else {
            reject(error);
          }
        });
    });
  }
}