import React from "react";
import {HelperUtils, RpoLookupService, } from "../services";
import { Header, Alert, CaptchaModal } from "../components/UI";
import { TranslationMixin } from "tds_shared_ui";
import {
    AlertMixin,
    WithCommonTranslations,
    Table,
} from "tds_shared_ui";
import {RPOLookupForm} from "../components/RPOLookup/RPOLookupForm";

export const RpoLookupPage = WithCommonTranslations(
            class extends AlertMixin(TranslationMixin(React.Component)) {
                constructor(props) {
                    super(props);

                    this.alertRef = React.createRef();


                    this.state = {
                        loading: false,
                        alert: {
                            display: false,
                            type: "default",
                            message: "",
                        },
                        formValues: {
                            vin: "",
                        },
                        results: null,
                        showCaptcha: false,
                    };

                    this.handleOpenCaptcha = this.handleOpenCaptcha.bind(this);
                    this.handleHideCaptcha = this.handleHideCaptcha.bind(this);
                    this.handleSubmit = this.handleSubmit.bind(this);
                }

                getVinFields(formValues) {
                    return {
                        vin: formValues.vin,
                    };
                }


                handleChange = (name, value, callback) => {
                    this.setState((prevState) => {
                        let newState = {};
                        let formValues = Object.assign({}, prevState.formValues);
                        formValues[name] = value;
                        newState.formValues = formValues;
                        return newState;
                    }, callback);
                };

                handleOpenCaptcha() {
                    this.setState({
                        showCaptcha: true,
                    });
                }

                handleHideCaptcha() {
                    this.setState({
                        showCaptcha: false,
                    });
                }

                handleSubmit = () => {
                    this.setState({
                        loading: true,
                        alert: {
                            display: false,
                        },
                        showCaptcha: false,
                    });
                    RpoLookupService.requestVinRPO(this.state.formValues.vin.toUpperCase())
                        .then((data) => {
                            this.setState(
                                {
                                    loading: false,
                                    results: data.optionSet[0].option,
                                    noData: false,
                                },
                            );
                        })
                        .catch((error) => {
                            if (error.status === 404) {
                                this.setState({
                                    loading: false,
                                    results: null,
                                    noData:true,
                                });
                            } else {
                                this.displayError(error.data, this.scrollToAlert);
                                this.setState({
                                    loading: false,
                                    results: null,
                                    noData: false,
                                });
                            }
                        });
                };

                scrollToAlert() {
                    HelperUtils.scrollToAlert(this.alertRef);
                }


                getCols() {
                    return [
                        {
                            header: "RPO Code",
                            accessor: 'optionCode',
                        },
                        {
                            header: "RPO Description",
                            accessor: 'optionDesc',
                        },
                    ];
                }

                render() {
                    const { results, noData } = this.state;
                    const cols = this.getCols();

                    return (
                        <div className="rpoLookupPage">
                            <Header level={1}>
                                {this.getTranslation("LABEL_RPO_LOOKUP")}
                            </Header>
                            <div className="col-sm-12 col-md-6 p-10 pt-sm-2 pt-md-4">
                                {this.state.alert.display && (
                                    <Alert ref={this.alertRef} type={this.state.alert.type}>
                                        {this.state.alert.message}
                                    </Alert>
                                )}

                                <RPOLookupForm
                                    submit={this.handleOpenCaptcha}
                                    loading={this.state.loading}
                                    formValues={this.state.formValues}
                                    onChange={this.handleChange}
                                />
                            </div>
                            {results &&
                                <div className="result w-50">
                                    <Table columns={cols} rows={results}
                                           filterId="filterId"/>
                                </div>}
                            {noData &&
                                <h1 style={{fontSize:'2em', fontWeight: 'bold'}}>
                                    {this.getTranslation("NO_VIN_RPO_DATA_FOUND")}
                                </h1>}
                            <CaptchaModal
                                open={this.state.showCaptcha}
                                onCancel={this.handleHideCaptcha}
                                onSuccess={this.handleSubmit}
                            />
                        </div>

                    );
                }
            }
    );
