import React from "react";

import {
  Alert,
  AlertMixin,
  TranslationMixin,
  WithCommonTranslations,
} from "tds_shared_ui";
import { BillingForm } from "./BillingForm";
import { HelperUtils } from "../../services";
import { WithLMSTranslations } from "../hocs";

export const BillingInfo = WithLMSTranslations(
  WithCommonTranslations(
    class extends AlertMixin(TranslationMixin(React.Component)) {
      constructor(props) {
        super(props);
        this.alertRef = React.createRef();

        this.state = {
          loading: false,
          validationErrors: false,
          alert: {
            display: false,
            type: "default",
            message: "",
          },
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onValidationError = this.onValidationError.bind(this);
      }

      scrollToAlert() {
        HelperUtils.scrollToAlert(this.alertRef);
      }

      onValidationError() {
        this.setState({
          validationErrors: true,
        });
        this.showFormError();
      }

      handleSubmit() {
        this.setState({
          validationErrors: false,
          loading: false,
          alert: {
            display: false,
          },
        });

        this.props.onNext();
      }

      showError(error) {
        this.displayError(error, this.scrollToAlert);
      }

      showFormError() {
        this.setState(
          {
            alert: {
              display: true,
              message: this.getTranslation("ERROR_FORM_VALIDATION"),
              type: "error",
            },
          },
          this.scrollToAlert
        );
      }

      render() {
        return (
          <div>
            <div>
              {this.state.alert.display && (
                <Alert ref={this.alertRef} type={this.state.alert.type}>
                  {this.state.alert.message}
                </Alert>
              )}

              <p>{this.getTranslation("MESSAGE_BILLING_INFO_HELP")}</p>
              <BillingForm
                formValues={this.props.formValues}
                states={this.props.states}
                submit={this.handleSubmit}
                loading={this.state.loading}
                onValidationError={this.onValidationError}
                onCancel={this.props.onCancel}
                {...this.props}
              />
            </div>
          </div>
        );
      }
    }
  )
);
