import React from "react";
import { AlertMixin, TranslationMixin } from "tds_shared_ui";
import { WithCommonTranslations, WithRegion } from "../components/hocs";
import { TermsService } from "../services";

export const TermsAndConditionsPage = WithRegion(
  WithCommonTranslations(
    class TermsAndConditionsPage extends AlertMixin(
      TranslationMixin(React.Component)
    ) {
      constructor(props) {
        super(props);

        this.state = {
          termsData: null,
        };
      }

      componentDidMount() {
        this.handleStaticContent();
      }

      componentDidUpdate(prevProps) {
        if (prevProps.locale !== this.props.locale) {
          this.handleStaticContent();
        }
      }

      handleStaticContent() {
        const tdstermsconditions =
          this.props.regionData.regionCode.toUpperCase() === "AU"
            ? "tdstermsconditionsAU"
            : "tdstermsconditions";
        TermsService.getTerms(tdstermsconditions, this.props.locale).then(
          (data) => {
            this.setState({
              termsData: data.content,
            });
          }
        ).catch((e) => {
          //Adding empty catch to prevent webpack unhandled exception msg
        });
      }

      render() {
        return (
          <div id="termsAndConditionsPage">
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.termsData,
              }}
            ></div>
          </div>
        );
      }
    }
  )
);
