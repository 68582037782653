import React from 'react';

export const WithFormValidation = (WrappedComponent) => {

  class WithFormValidation extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isValidated: false,
      };

      this.validate = this.validate.bind(this);
    }

    validate = () => {
      const formEl = this.formEl;

      return formEl.checkValidity();
    };

    submitHandler = e => {
      e.preventDefault();
      let isValidated = true;
      if (this.validate()) {
        isValidated = false; //clear validation flag after successful submit
        this.props.submit();
      } else if (typeof this.props.onValidationError === 'function') {
        this.props.onValidationError();
      }

      this.setState({isValidated: isValidated});
    };

    render() {
      let classNames = [];
      if (this.props.className) {
        classNames = [...this.props.className];
      }
      if (this.state.isValidated) {
        classNames.push('was-validated');
      }

      return (
          <form noValidate
                className={classNames}
                onSubmit={this.submitHandler}
                ref={form => (this.formEl = form)}>
            <WrappedComponent
                isValidated={this.state.isValidated} {...this.props}/>
          </form>
      );

    }
  }

  WithFormValidation.displayName = `WithFormValidation(${getDisplayName(
      WrappedComponent)})`;
  return WithFormValidation;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}