import {publicAPI} from './api';
import CacheableAjaxService from "./CacheableAjaxService";

export default class TermsService extends CacheableAjaxService {

    static getTerms(terms, locale) {
        if(!locale){
           locale = 'en_US'
        }
        return super.getAPI(publicAPI, `static/${terms}/${locale}`);
    }
}