import React from "react";

import { SubscriptionsItem } from "../components/Subscriptions/SubscriptionsItem";
import { MySubscriptions } from "../components/Subscriptions/MySubscriptions";
import { NotificationAlerts } from "../components/Notifications/NotificationAlerts";
import { Link, Header, Alert, LoadingIcon } from "../components/UI";
import { HelperUtils, SubscriptionService, UtilService } from "../services";
import {
    SubscriptionsHeader,
    SubscriptionsHeader2, SubscriptionsHeader3,
} from "../components/Subscriptions/SubscriptionsHeader";
import { TranslationMixin, withRouter } from "tds_shared_ui";
import {
  WithSubscriptionsTranslations,
  WithCommonTranslations,
  WithUser,
} from "../components/hocs/";
import "./SubscriptionsPage.css";

export const SubscriptionsPage = withRouter(
  WithUser(
    WithSubscriptionsTranslations(
      WithCommonTranslations(
        class SubscriptionsPage extends TranslationMixin(React.Component) {
          constructor(props) {
            super(props);

            this.getSubscriptionsCancelToken = null;
            this.currentRegion = null;
            this.checking = false;
            this.alertRef = React.createRef();
            this.state = {
              dirtyCart: false,
              loading: false,
              showNotification: false,
              alert: {
                display: false,
                type: "default",
                message: "",
              },
              allSubscriptions: null,
              programmingSub: [],
              diagnosticsSub: [],
              serviceInfoSub: [],
              techlineConnectSub: [],
              dataSecuritySub: [],
              showProgrammingSubscription: true,
              showDiagnosticsSubscription: true,
              showServiceInfoSubscription: true,
              showTlcSubscription: true,
              showDataSecuritySubscription: true,
              showEPCOLSubscription: true,
              showSPTools: true,
            };
            this.purchaseSubscribeBtn = this.purchaseSubscribeBtn.bind(this);
            this.linkToKeyCodesBtn = this.linkToKeyCodesBtn.bind(this);
            this.handleDismiss = this.handleDismiss.bind(this);
          }

          componentDidUpdate(prevProps) {
            //Check if user logged in or out
            if (
              (!prevProps.user && this.props.user) ||
              (!this.props.user && prevProps.user)
            ) {
              //clear alert if they login
              this.setState({
                showNotification: true,
                alert: {
                  display: false,
                },
              });
              this.cancelFetch();
              this.fetchSubscriptions();
            } else if (prevProps.locale !== this.props.locale) {
              this.cancelFetch();
              this.fetchSubscriptions();
            }

            if (this.currentRegion === null && this.checking === false) {
              this.checking = true;
              this.setState({
                  loading: true,
              });
              UtilService.getRegion().then((data) => {
                if (data.regionCode) {
                }
                this.currentRegion = data;
                if (
                  this.currentRegion.regionCode.toUpperCase() !== null &&
                  this.currentRegion.regionCode.toUpperCase() !== "US" &&
                  this.currentRegion.regionCode.toUpperCase() !== "CA"
                ) {
                  this.setState({
                    showTlcSubscription: false,
                  });
                }
                if (
                  this.currentRegion.regionCode.toUpperCase() !== null &&
                  this.currentRegion.regionCode.toUpperCase() !== "AU"
                ) {
                  this.setState({
                    showDataSecuritySubscription: false,
                  });
                }
                if (
                  this.currentRegion.regionCode.toUpperCase() !== null &&
                  this.currentRegion.regionCode.toUpperCase() !== "CN"
                ) {
                  this.setState({
                    showEPCOLSubscription: false,
                    showSPTools: false,
                  });
                }
              }).catch((e) => {
                //Adding empty catch to prevent webpack unhandled exception msg
              });
            }
          }

          scrollToAlert() {
            HelperUtils.scrollToAlert(this.alertRef);
          }

          linkToKeyCodesBtn() {
            this.props.navigate("/keycodes");
          }

          purchaseSubscribeBtn(item, productId) {
            return new Promise((resolve, reject) => {
              if (!this.props.user) {
                this.setState(
                  {
                    alert: {
                      display: true,
                      message: (
                        <span>
                          {this.getTranslation("ALERT_LOGIN_TO_CHECKOUT")}{" "}
                          {!this.props.user && (
                            <Link to="/registration">
                              {this.getTranslation("ALERT_LINK_TO_SIGNUP")}
                            </Link>
                          )}
                        </span>
                      ),
                      type: "error",
                    },
                  },
                  this.scrollToAlert,
                  reject({ error: true })
                );
              } else {
                let cartItem = {
                  skuid: item,
                  quantity: 1,
                  productId: productId,
                };
                this.props
                  .addToCart(cartItem)
                  .then((data) => {
                    resolve(data);
                    this.props.navigate("/shoppingcart");
                  })
                  .catch((error) => {
                    this.setState(
                      {
                        loading: false,
                        alert: {
                          display: true,
                          message: this.getTranslation("ERROR_ADD_TO_CART"),
                          type: "error",
                        },
                      },
                      this.scrollToAlert,
                      reject(error)
                    );
                  });
              }
            });
          }

          componentWillUnmount() {
            this.cancelFetch();
          }

          componentDidMount() {
            this.fetchSubscriptions();
            this.setState({
              showNotification: true,
            });
          }

          cancelFetch() {
            if (this.getSubscriptionsCancelToken) {
              this.getSubscriptionsCancelToken.cancel({ unmounted: true });
            }
          }

          fetchSubscriptions() {
            this.setState({
              loading: true,
              allSubscriptions: null,
              programmingSub: [],
              diagnosticsSub: [],
              serviceInfoSub: [],
              techlineConnectSub: [],
              dataSecuritySub: [],
            });
            this.getSubscriptionsCancelToken =
              SubscriptionService.getCancelToken().source();

            SubscriptionService.getSubscriptions({
              cancelToken: this.getSubscriptionsCancelToken.token,
              headers: {
                "Accept-Language": this.props.locale.replace("_", "-"),
              },
            })
              .then((data) => {
                const programming = data.filter(
                  (cat) => cat.category === "PROGRAMMING"
                );
                const diagnostics = data.filter(
                  (cat) => cat.category === "DIAGNOSTICS"
                );
                const serviceInfo = data.filter(
                  (cat) => cat.category === "SERVICE_INFORMATION"
                );
                const techlineConnect = data.filter(
                  (cat) => cat.category === "TECHLINE_CONNECT"
                );
                const dataSecurity = data.filter(
                  (cat) => cat.category === "DATA_SECURITY"
                );

                let i = serviceInfo.length;
                while (i--) {
                  if (
                    serviceInfo[i].name ===
                    "General Motors Service Repair Manual Information (SI)"
                  ) {
                    serviceInfo.splice(i, 1);
                    break;
                  }
                }

                this.setState({
                  loading: false,
                  alert: {
                    display: false,
                  },
                  allSubscriptions: data,
                  programmingSub: programming,
                  diagnosticsSub: diagnostics,
                  serviceInfoSub: serviceInfo,
                  techlineConnectSub: techlineConnect,
                  dataSecuritySub: dataSecurity,
                });
              })
              .catch((error) => {
                //TODO change to translatedMessage when not null
                if (!error.unmounted) {
                  const msg = error.translatedMessage || error.internalMessage;
                  this.setState(
                    {
                      loading: false,
                      alert: {
                        display: true,
                        message: msg,
                        type: "error",
                      },
                    },
                    this.scrollToAlert
                  );
                }
              });
          }

          handleDismiss(notification) {
            if (notification) {
              this.props.dismissNotification(notification);
            }
          }

          render() {
            if (this.state.loading) {
                return (
                    <LoadingIcon
                        display={this.state.loading}
                        size="3x"
                    />
                );
            }
            else {
            return (
              <div className="subscriptionsPage">
                {this.state.showNotification && (
                  <NotificationAlerts onDismiss={this.handleDismiss} />
                )}

                {/*Alert Message*/}
                {this.state.alert.display && (
                  <Alert
                    ref={this.alertRef}
                    type={this.state.alert.type}
                    className="mt-3"
                  >
                    {this.state.alert.message}
                  </Alert>
                )}

                {/*Header*/}
                <SubscriptionsHeader
                  header={this.getTranslation("HEADER_TDS_TITLE")}
                  text={this.getTranslation("MESSAGE_TDS")}
                />
                <SubscriptionsHeader2
                  header={this.getTranslation("LABEL_SUBSCRIPTIONS")}
                  dangerouslySetInnerHTML={{
                    __html: this.getTranslation("MESSAGE_SUBSCRIPTIONS"),
                  }}
                  className="pb-1"
                />

                {/*Start for My Subscriptions part*/}
                {this.props.user && this.state.allSubscriptions && (
                  <MySubscriptions
                    allSubscriptions={this.state.allSubscriptions}
                    onPurchaseClick={this.purchaseSubscribeBtn}
                  />
                )}

                <div className="subscriptionsPage">

                    {/*Programming catalog section*/}
                  {this.state.showProgrammingSubscription && (
                      <Header level={3} className="itemHeader">
                        {this.getTranslation("HEADER_PROGRAMMING")}
                      </Header>
                  )}
                  {this.state.showProgrammingSubscription && (
                  <div>
                    <LoadingIcon
                      display={this.state.loading}
                      text={this.getTranslation("MESSAGE_LOADING")}
                    />
                    {this.state.programmingSub.map((programmingItem) => (
                      <SubscriptionsItem
                        loading={this.state.loading}
                        className="programmingSubItems"
                        subscriptionType={programmingItem}
                        key={programmingItem.id}
                        onClick={this.purchaseSubscribeBtn}
                      />
                    ))}
                  </div>
                  )}

                    {/*Diagnostics catalog section*/}
                  {this.state.showDiagnosticsSubscription && (
                  <Header level={3} className="itemHeader">
                    {this.getTranslation("HEADER_DIAGNOSTICS")}
                  </Header>
                  )}
                  {this.state.showDiagnosticsSubscription && (
                  <div>
                    <LoadingIcon
                      display={this.state.loading}
                      text={this.getTranslation("MESSAGE_LOADING")}
                    />
                    {this.state.diagnosticsSub.map((diagnosticsItem) => (
                      <SubscriptionsItem
                        loading={this.state.loading}
                        className="diagnosticsSubItem"
                        subscriptionType={diagnosticsItem}
                        key={diagnosticsItem.id}
                        onClick={this.purchaseSubscribeBtn}
                      />
                    ))}
                  </div>
                  )}

                    {/*Service Information catalog section*/}
                  {this.state.showServiceInfoSubscription && (
                  <Header level={3} className="itemHeader">
                    {this.getTranslation("HEADER_INFORMATION")}
                  </Header>
                  )}
                  {this.state.showServiceInfoSubscription && (
                  <div>
                    <LoadingIcon
                      display={this.state.loading}
                      text={this.getTranslation("MESSAGE_LOADING")}
                    />
                    {this.state.serviceInfoSub.map((serviceInfoItem) => (
                      <SubscriptionsItem
                        loading={this.state.loading}
                        className="serviceInfoSubItem"
                        subscriptionType={serviceInfoItem}
                        key={serviceInfoItem.id}
                        onClick={this.purchaseSubscribeBtn}
                      />
                    ))}
                  </div>
                    )}

                  {/*  Security Data (Keycodes) catalog section*/}
                  {this.state.showDataSecuritySubscription && (
                    <Header level={3} className="itemHeader">
                      {this.getTranslation("HEADER_DATA_SECURITY")}
                    </Header>
                  )}
                  {this.state.showDataSecuritySubscription && (
                    <div>
                      <LoadingIcon
                        display={this.state.loading}
                        text={this.getTranslation("MESSAGE_LOADING")}
                      />
                      {this.state.dataSecuritySub.map((dataSecurityItem) => (
                        <SubscriptionsItem
                          loading={this.state.loading}
                          className="dataSecuritySubItem"
                          subscriptionType={dataSecurityItem}
                          key={dataSecurityItem.id}
                          onClick={this.linkToKeyCodesBtn}
                        />
                      ))}
                    </div>
                  )}

                  {/*  Techline Connect catalog section*/}
                  {this.state.showTlcSubscription && (
                    <Header level={3} className="itemHeader">
                      {this.getTranslation("HEADER_TECHLINE_CONNECT")}
                    </Header>
                  )}
                  {this.state.showTlcSubscription && (
                    <div>
                      <LoadingIcon
                        display={this.state.loading}
                        text={this.getTranslation("MESSAGE_LOADING")}
                      />
                      {this.state.techlineConnectSub.map(
                        (techlineConnetItem) => (
                          <SubscriptionsItem
                            loading={this.state.loading}
                            className="techlineConnectSubItem"
                            subscriptionType={techlineConnetItem}
                            key={techlineConnetItem.id}
                            onClick={this.purchaseSubscribeBtn}
                          />
                        )
                      )}
                    </div>
                  )}
                </div>

                {/* China's EPCOL Section */}
                {this.state.showEPCOLSubscription && (
                  <Header level={3} className="itemHeader">
                      {this.getTranslation("HEADER_EPCOL")}
                  </Header>
                )}

                {this.state.showEPCOLSubscription && (
                  <div className="text-gray" key="EPCOL">
                      {this.getTranslation("MESSAGE_EPCOL")}
                  </div>
                )}

                {/* China's SP Tools Section */}
                {this.state.showSPTools && (
                  <Header level={3} className="itemHeader">
                      {this.getTranslation("HEADER_SP_TOOLS")}
                  </Header>
                )}

                {this.state.showSPTools && (
                  <div className="text-gray" key="SPTools">
                      {this.getTranslation("MESSAGE_SP_TOOLS")}
                  </div>
                )}

                {!this.state.showSPTools && (
                  <p className="fa-sm mt-2 mb-2">
                    <i>
                        {this.getTranslation("SUBSCRIPTION_CURRENCY") +
                        (this.state.showDataSecuritySubscription ? " and exclude gst" : "")}
                    </i>
                  </p>
                )}
                {this.state.showDataSecuritySubscription ? (
                  <p className="fa-sm mt-2 mb-2">
                    <i>{this.getTranslation("SUBSCRIPTION_TEXT_AU")}</i>
                  </p>
                ) : (
                  ""
                )}
              </div>
            );
            }
          }
        }
      )
    )
  )
);
