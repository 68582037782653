import {publicAPI} from './api';
import CacheableAjaxService from './CacheableAjaxService';

export default class TranslationService extends CacheableAjaxService {

  /**
   * Caches translations in session
   * @param bundle
   * @param locale
   * @returns {Promise<any>}
   */
  static getBundle(bundle, locale) {
    return super.getAPI(publicAPI, `translations/${bundle}/${locale}`);
  }

  static getTranslation(bundle, locale, key) {
    return super.getAPI(publicAPI, `translations/${bundle}/${locale}/${key}`);
  }

}