import React from "react";

import {
  Button,
  OrderInvoiceModal as CommonOrderInvoiceModal,
} from "tds_shared_ui";
import { WithCommonTranslations } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";

export const OrderInvoiceModal = WithCommonTranslations(
  class extends TranslationMixin(React.Component) {
    constructor(props) {
      super(props);

      this.state = {
        disabled: true,
      };

      this.handlePrint = this.handlePrint.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }

    handlePrint() {
      document.body.classList.add("modal-print");
      window.print();
    }

    handleClose() {
      document.body.classList.remove("modal-print");
      this.props.onClose();
    }

    render() {
      const { orderDetails, open } = this.props;
      let rightButton = (
        <Button type="button" variant="link" onClick={this.handlePrint}>
          {this.getTranslation("BUTTON_PRINT")}
        </Button>
      );

      return (
        <CommonOrderInvoiceModal
          open={open}
          onClose={this.handleClose}
          orderDetails={orderDetails}
          rightButton={rightButton}
        />
      );
    }
  }
);
