import React from 'react';

export class FormSelect extends React.Component {

    render() {
        const {id, label, name, required, error, fieldName, ...other} = this.props;
        const display = error ? 'block' : 'none';

        return (

            <div className={`form-group gmpayment-${fieldName}-field`}>
                <label htmlFor={id} className={required ? 'required' : ''}
                       id={`${id}-label`}>{label}</label>
                <div className="form-input">
                    <div className="gm-select">
                        <select id={id}
                                name={name}
                                type="text"
                                className="form-control"
                                required={required}
                                {...other}>
                            {this.props.children}
                        </select>
                        <i className="arrow"></i>
                    </div>
                    <div className="invalid-feedback" id={`${id}-error`}
                         style={{display: display}}>
                        {error}
                    </div>
                </div>
            </div>
        );
    }
}