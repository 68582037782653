import React from "react";

import { MyOrderTable } from "./MyOrdersTable";
import { WithUser, WithKeyCodeTranslations } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import "./MyOrders.css";

export const MyOrders = WithUser(
  WithKeyCodeTranslations(
    class extends TranslationMixin(React.Component) {
      renderMyOrdersLoggedOutMessage() {
        return <div>{this.getTranslation("MESSAGE_NO_ORDERS")}</div>;
      }

      render() {
        return (
          <div className="myOrders">
            <p>{this.getTranslation("MESSAGE_MY_ORDERS")}</p>
            {this.props.user ? (
              <MyOrderTable />
            ) : (
              this.renderMyOrdersLoggedOutMessage()
            )}
          </div>
        );
      }
    }
  )
);
