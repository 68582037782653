import React from "react";
import { Alert } from "../UI";
import { WithCommonTranslations, WithUser } from "../hocs";
import { TranslationMixin, Panel } from "tds_shared_ui";
import { NimbusLink as Link } from "tds_shared_ui";
import { CartList } from "./CartList";
import "./ShoppingCart.css";

export const ShoppingCartIcon = WithCommonTranslations(
  WithUser(
    class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);

        this.node = React.createRef();
        this.state = {
          hidden: true,
          alert: {
            display: false,
            type: "default",
            message: "",
          },
        };

        this.hide = this.hide.bind(this);
        this.togglePanel = this.togglePanel.bind(this);
      }

      componentDidMount() {
        this.toggleClickOutsideEvent(true);
      }

      componentWillUnmount() {
        this.toggleClickOutsideEvent(false);
      }

      toggleClickOutsideEvent(enabled) {
        if (enabled) {
          document.addEventListener("click", this.handleClickOutside);
        } else {
          document.removeEventListener("click", this.handleClickOutside);
        }
      }

      handleClickOutside = (event) => {
        if (this.props.showCart) {
          const node = this.node.current;
          if (!node || !node.contains(event.target)) {
            this.hide();
          }
        }
      };

      hide() {
        this.props.onHide();
      }

      togglePanel() {
        if (!this.props.user) {
          this.props.setCartDisplay(!this.props.showCart);
          this.setState({
            alert: {
              display: true,
              message: this.getTranslation("ERROR_LOGIN_FOR_CART"),
              type: "error",
            },
          });
        } else {
          this.props.setCartDisplay(!this.props.showCart);
        }
      }

      render() {
        const { className } = this.props;
        const displayClass = (
          "cart-container " + (className ? className : "")
        ).trim();

        return (
          <div className={displayClass} ref={this.node}>
            {this.props.cart.length === 0 ? (
              <Link href="#" onClick={this.togglePanel}>
                <div>
                  <i className={"fas fa-shopping-cart"}></i>
                </div>
              </Link>
            ) : (
              <Link href="#" onClick={this.togglePanel}>
                <div>
                  <i className={"fas fa-shopping-cart"}></i>
                  <span className="badge badge-danger rounded-circle">
                    {this.props.cartQuantity}
                  </span>
                </div>
              </Link>
            )}
            {this.props.user ? (
              <Panel
                floating={true}
                hidden={!this.props.showCart}
                className="cart-panel"
              >
                <CartList
                  items={this.props.cart}
                  readOnly={true}
                  locale={this.props.locale}
                  onShowTerms={() => {
                    this.toggleClickOutsideEvent(false);
                  }}
                  onHideTerms={this.hide}
                />
              </Panel>
            ) : (
              <Panel
                floating={true}
                hidden={!this.props.showCart}
                className="cart-panel"
              >
                <div className="font-weight-bold">
                  {this.state.alert.display && (
                    <Alert
                      ref={this.alertRef}
                      type={this.state.alert.type}
                      className="mt-3"
                    >
                      {this.state.alert.message}
                    </Alert>
                  )}
                </div>
              </Panel>
            )}
          </div>
        );
      }
    }
  )
);
