import React from "react";

import { WithCommonTranslations } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import "./OrderTotal.css";

export const OrderSummary = WithCommonTranslations(
  class extends TranslationMixin(React.Component) {
    render() {
      let { receipt, className } = this.props;

      return (
        <div
          className={((className ? className : "") + " orderSummary").trim()}
        >
          <div className="orderSummaryTitle">
            {this.getTranslation("HEADER_SUMMARY")}
          </div>
          <div className="orderSummaryBody">
            {receipt &&
              receipt.receiptItems.map((data, index) => (
                <div className="pb-3" key={index}>
                  <div className="row">
                    <div className="col-5">
                      <span className="font-weight-bold">{data.name}</span>{" "}
                      {data.skuDescription ? (
                        <span>- {data.skuDescription}</span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <div className="col-2">
                      {this.getTranslation("LABEL_ORDER_QTY")}: {data.quantity}
                    </div>
                    <div className="col-5 text-right">
                      {data.subtotalBeforeDiscount !==
                        data.subtotalAfterDiscount && (
                        <s>{data.subtotalBeforeDiscount}</s>
                      )}{" "}
                      {data.subtotalAfterDiscount}
                      {data.displayableDiscount && (
                        <React.Fragment>
                          <br />
                          <span className="promo-row">
                            {data.displayableDiscount}
                          </span>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              ))}

            <div className="mt-3"></div>

            {receipt.subtotalBeforeDiscount && (
              <div className="row">
                <div className="col-4">
                  {this.getTranslation("LABEL_ORDER_SUBTOTAL")}
                </div>
                <div className="offset-3 col-5 text-right">
                  {receipt.subtotalBeforeDiscount}
                </div>
              </div>
            )}

            {receipt.totalDiscount && (
              <div className="row promo-row">
                <div className="col-4">
                  {this.getTranslation("LABEL_TOTAL_ORDER_DISCOUNT")}
                </div>
                <div className="offset-3 col-5 text-right">
                  -{receipt.totalDiscount}
                </div>
              </div>
            )}

            {receipt.tax && (
              <div className="row">
                <div className="col-4">
                  {this.getTranslation("LABEL_ORDER_SALES_TAX")}
                </div>
                <div className="offset-3 col-5 text-right">{receipt.tax}</div>
              </div>
            )}

            <div className="border-top pt-1">
              <div className="row font-weight-bold">
                <div className="col-4">
                  {this.getTranslation("LABEL_ORDER_TOTAL")}
                </div>
                <div className="offset-3 col-5 text-right">
                  {receipt.grandTotal}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);
