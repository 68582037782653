import React from "react";
import { Alert } from "../UI";
import { WithUser } from "../hocs";

export const NotificationAlerts = WithUser(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showNotification: false,
        loading: false,
        displayAlert: true,
      };

      this.handleRemove = this.handleRemove.bind(this);
      this.handleDismiss = this.handleDismiss.bind(this);
    }

    handleDismiss(notification) {
      this.props.onDismiss(notification);
    }

    handleRemove() {
      this.props.onRemove(this.props.item.skuid);
    }

    render() {
      let notificationData =
        this.props.notificationData.length > 0
          ? this.props.notificationData.filter((data) => data.type !== "info")
          : [];
      let display = this.state.displayAlert;

      return (
        <React.Fragment>
          {notificationData
            .filter((data) => data.dismissed !== true)
            .map((notification) => (
              <Alert
                ref={this.alertRef}
                key={notification.id}
                type={notification.type}
                display={display}
                dismissible={true}
                onDismiss={() => this.handleDismiss(notification)}
                dismissComponent={
                  <button type="button" onClick={this.onDismiss}>
                    <i className="fa fa-times"></i>
                  </button>
                }
              >
                <strong>{notification.title} </strong> {notification.message}
              </Alert>
            ))}
        </React.Fragment>
      );
    }
  }
);
