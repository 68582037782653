import {publicAPI} from './api';
import CacheableAjaxService from "./CacheableAjaxService";

export default class StaticImageService extends CacheableAjaxService {

    static getStaticImage(image, locale) {
        if(!locale){
           locale = 'en_US'
        }
        return super.getAPI(publicAPI, `static/${image}/${locale}`);
    }
}