import React from "react";
import { Alert } from "../UI";
import { WithCommonTranslations, WithUser } from "../hocs";
import { TranslationMixin, Panel } from "tds_shared_ui";

export const ResourceLoginCheck = WithCommonTranslations(
  WithUser(
    class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
          hidden: false,
          alert: {
            type: "error",
          },
        };
      }

      componentDidMount() {
        this.toggleClickOutsideEvent(true);
      }

      componentWillUnmount() {
        this.toggleClickOutsideEvent(false);
      }

      toggleClickOutsideEvent(enabled) {
        if (enabled) {
          document.addEventListener("click", this.handleClickOutside);
        } else {
          document.removeEventListener("click", this.handleClickOutside);
        }
      }

      handleClickOutside = (event) => {
        if (this.props.isUserNotLoggedIn) {
          this.props.handleLoginCheck();
        }
      };

      render() {
        return (
          <div className="LoginError" ref={this.node}>
            <div className="font-weight-bold">
              <Panel
                floating={true}
                className="LoginCheck"
                hidden={!this.props.isUserNotLoggedIn}
              >
                <Alert
                  ref={this.alertRef}
                  type={this.state.alert.type}
                  className="mt-3"
                >
                  {this.props.errorMessage}
                </Alert>
              </Panel>
            </div>
          </div>
        );
      }
    }
  )
);
