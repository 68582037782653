import React from 'react';
import {ToolTipIcon} from "./ToolTip";

export class FormField extends React.Component {

    render() {
        const {id, label, name, required, error, placeholder, fieldName, showToolTip, toolTipLabels, cvvIcon, closeIcon, ...other} = this.props;
        const display = error ? 'block' : 'none';

        return (
            <div className={`form-group gmpayment-${fieldName}-field`}>
                <label htmlFor={id} className={required ? 'required' : ''}
                       id={`${id}-label`}>{label}{showToolTip && <ToolTipIcon toolTipLabels={toolTipLabels} cvvIcon={cvvIcon} closeIcon={closeIcon} />}</label>
                <div className="form-input">
                    <input type="text"
                           className="form-control"
                           id={id}
                           name={name}
                           required={required}
                           placeholder={placeholder}
                           {...other}/>
                    <div className="invalid-feedback" id={`${id}-error`}
                         style={{display: display}}>
                        {error}
                    </div>
                </div>
            </div>
        );
    }
}