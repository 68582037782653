import GenericAjaxService from './GenericAjaxService';
import {privateAPI} from './api';

export default class OrderService extends GenericAjaxService {

    static getOrders(){
        return super.getAPI(privateAPI, `orders`);
    }

    static getOrderInvoice(orderNumber){
        return super.getAPI(privateAPI, `orders/${orderNumber}`);
    }

    static getReceipt(orderNumber){
        return super.getAPI(privateAPI, `orders/${orderNumber}/receipt`);
    }
}