import React from "react";
import { ChangePasswordForm } from "../components/ForgotPassword/ChangePasswordForm";
import { HelperUtils, PasswordService } from "../services";
import { Header, Alert } from "../components/UI";
import { TranslationMixin } from "tds_shared_ui";
import {
  WithPasswordTranslations,
  WithCommonTranslations,
  withRouter,
} from "tds_shared_ui";
import { AlertMixin } from "tds_shared_ui";

export const ChangePasswordPage = withRouter(
  WithCommonTranslations(
    WithPasswordTranslations(
      class extends AlertMixin(TranslationMixin(React.Component)) {
        constructor(props) {
          super(props);

          this.alertRef = React.createRef();

          this.state = {
            loading: false,
            alert: {
              display: false,
              type: "default",
              message: "",
            },
            formValues: {
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            },
          };
        }

        handleCancel = () => {
          this.props.navigate(-1);
        };

        handleChange = (name, value, callback) => {
          this.setState((prevState) => {
            let newState = {};
            let formValues = Object.assign({}, prevState.formValues);
            formValues[name] = value;
            newState.formValues = formValues;
            return newState;
          }, callback);
        };

        handleSubmit = () => {
          this.setState({
            loading: true,
            alert: {
              display: false,
            },
          });
          PasswordService.changePassword(
            this.state.formValues.oldPassword,
            this.state.formValues.newPassword
          )
            .then((data) => {
              this.setState(
                {
                  loading: false,
                  alert: {
                    display: true,
                    message: this.getTranslation(
                      "MESSAGE_PASSWORD_CHANGE_SUCCESS"
                    ),
                    type: "success",
                  },
                  formValues: {
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                  },
                },
                this.scrollToAlert
              );
            })
            .catch((error) => {
              this.displayError(error, this.scrollToAlert);
              this.setState({
                loading: false,
              });
            });
        };

        scrollToAlert() {
          HelperUtils.scrollToAlert(this.alertRef);
        }

        onValidationError = () => {
          this.setState(
            {
              alert: {
                display: true,
                message: this.getTranslation("ERROR_FORM_VALIDATION"),
                type: "error",
              },
            },
            this.scrollToAlert
          );
        };

        render() {
          return (
            <div className="changePasswordPage">
              <Header level={1}>
                {this.getTranslation("LABEL_CHANGE_PASSWORD")}
              </Header>
              <div className="col-sm-12 col-md-6 p-0">
                {this.state.alert.display && (
                  <Alert ref={this.alertRef} type={this.state.alert.type}>
                    {this.state.alert.message}
                  </Alert>
                )}

                <ChangePasswordForm
                  submit={this.handleSubmit}
                  loading={this.state.loading}
                  onValidationError={this.onValidationError}
                  formValues={this.state.formValues}
                  onChange={this.handleChange}
                  onCancel={this.handleCancel}
                />
              </div>
            </div>
          );
        }
      }
    )
  )
);
