import React from "react";
import { UserConsumer } from "../../context";

export const WithUser = (WrappedComponent) => {
  class WithUser extends React.Component {
    render() {
      return (
        <UserConsumer>
          {({
            user,
            setUser,
            cart,
            addToCart,
            removeFromCart,
            displayCart,
            setCartDisplay,
            emptyCart,
            updateCart,
            saveCart,
            cartQuantity,
            cartSubTotal,
            notificationData,
            setNotificationDisplay,
            displayNotification,
            dismissNotification,
          }) => (
            <WrappedComponent
              {...this.props}
              user={user}
              setUser={setUser}
              notificationData={notificationData}
              setNotificationDisplay={setNotificationDisplay}
              displayNotification={displayNotification}
              dismissNotification={dismissNotification}
              cart={cart}
              addToCart={addToCart}
              removeFromCart={removeFromCart}
              displayCart={displayCart}
              setCartDisplay={setCartDisplay}
              emptyCart={emptyCart}
              updateCart={updateCart}
              saveCart={saveCart}
              cartSubTotal={cartSubTotal}
              cartQuantity={cartQuantity}
            />
          )}
        </UserConsumer>
      );
    }
  }

  WithUser.displayName = `WithUser(${getDisplayName(WrappedComponent)})`;

  return WithUser;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}
