import React from "react";
import ReactDOM from "react-dom";
import { PasswordUtils } from "tds_shared_ui";
import { WithFormValidation } from "../hocs";
import { FormInput, Button, LoadingIcon } from "../UI";
import { Validators } from "tds_shared_ui";
import { TranslationMixin } from "tds_shared_ui";
import { WithPasswordTranslations, WithCommonTranslations } from "../hocs";

export const ChangePasswordForm = WithPasswordTranslations(
  WithCommonTranslations(
    WithFormValidation(
      class extends TranslationMixin(React.Component) {
        constructor(props) {
          super(props);

          this.state = {
            disabled: true,
            customPasswordError: "",
          };

          this.pwRef = React.createRef();
          this.pw2Ref = React.createRef();
          this.passwordRegex = PasswordUtils.getRegexPattern();

          this.handleInputChange = this.handleInputChange.bind(this);
          this.validatePasswords = this.validatePasswords.bind(this);
        }

        componentDidUpdate(prevProps) {
          if (this.state.customPasswordError) {
            const { translations } = this.props;
            const key = "ERROR_PASSWORDS_DONT_MATCH";

            if (
              !prevProps.translations ||
              prevProps.translations[key] !== translations[key]
            ) {
              this.validatePasswords();
            }
          }
        }

        handleInputChange(event, callback) {
          const target = event.target;
          const value =
            target.type === "checkbox" ? target.checked : target.value;
          const name = target.name;
          const self = this;

          this.props.onChange(name, value, function () {
            self.validatePasswords();

            if (typeof callback === "function") {
              callback();
            }
          });
        }

        validatePasswords() {
          const error = this.getTranslation("ERROR_PASSWORDS_DONT_MATCH");
          const pw = this.getInput(this.pwRef);
          const pw2 = this.getInput(this.pw2Ref);
          let customError = "";
          if (!pw.validity.patternMismatch) {
            pw.setCustomValidity("");
            pw2.setCustomValidity("");
            if (!Validators.validateEqual(pw, pw2, error)) {
              customError = error;
            }
            this.setState({ customPasswordError: customError });
          } else {
            pw2.setCustomValidity("");
            this.setState({ customPasswordError: "" });
          }
        }

        getInput(ref) {
          const dom = ReactDOM.findDOMNode(ref.current);
          return dom.querySelector("input");
        }

        render() {
          return (
            <div>
              <FormInput
                label={this.getTranslation("LABEL_OLD_PASSWORD")}
                name="oldPassword"
                id="oldPassword"
                type="password"
                autoComplete="off"
                maxLength={20}
                required={true}
                value={this.props.formValues.oldPassword}
                displayError={this.props.isValidated}
                onChange={this.handleInputChange}
              />
              <FormInput
                label={this.getTranslation("LABEL_NEW_PASSWORD")}
                name="newPassword"
                id="newPassword"
                maxLength={20}
                type="password"
                autoComplete="off"
                ref={this.pwRef}
                required={true}
                pattern={this.passwordRegex}
                message={this.getTranslation("MESSAGE_PASSWORD_TIP")}
                readOnly={this.props.readOnly}
                displayError={this.props.isValidated}
                value={this.props.formValues.newPassword}
                customError={this.state.customPasswordError}
                onChange={this.handleInputChange}
              />
              <FormInput
                label={this.getTranslation("LABEL_CONFIRM_PASSWORD")}
                name="confirmPassword"
                id="confirmPassword"
                type="password"
                autoComplete="off"
                ref={this.pw2Ref}
                required={true}
                readOnly={this.props.readOnly}
                displayError={this.props.isValidated}
                maxLength={20}
                value={this.props.formValues.confirmPassword}
                customError={this.state.customPasswordError}
                onChange={this.handleInputChange}
              />
              <Button
                type="submit"
                variant="primary"
                disabled={this.props.loading}
              >
                <LoadingIcon display={this.props.loading} />
                {this.getTranslation("BUTTON_SAVE")}
              </Button>
              <Button
                type="button"
                variant="link"
                onClick={this.props.onCancel}
              >
                {this.getTranslation("BUTTON_CANCEL")}
              </Button>
            </div>
          );
        }
      }
    )
  )
);
