import React from "react";

import { LinkService } from "../services";

const Context = React.createContext();

export class LinkProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resourceLinks: [],
      footerLinks: [],
    };
  }

  componentDidMount() {
    this.fetchLinks();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.fetchLinks();
    }
  }

  fetchLinks() {
    LinkService.getLinks(this.props.locale).then((data) => {
      this.setupLinksData(data);
    }).catch((e) => {
      //Adding empty catch to prevent webpack unhandled exception msg
    });
  }

  setupLinksData(links) {
    const footerLinks = [];
    const resourceLinks = [];

    for (let i = 0; i < links.length; i++) {
      if (links[i].type === "FOOTER") {
        footerLinks.push(links[i]);
      } else if (links[i].type === "RESOURCE") {
        resourceLinks.push(links[i]);
      }
    }

    function compare(a, b) {
      if (a.displayOrder < b.displayOrder) {
        return -1;
      }
      if (b.displayOrder < a.displayOrder) {
        return 1;
      }
      return 0;
    }

    footerLinks.sort(compare);
    resourceLinks.sort(compare);

    this.setState({
      footerLinks: footerLinks,
      resourceLinks: resourceLinks,
    });
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const LinksConsumer = Context.Consumer;
