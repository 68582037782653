import React from "react";
import { AlertMixin, TranslationMixin } from "tds_shared_ui";
import { WithCommonTranslations, WithRegion } from "../components/hocs";
import { TermsService } from "../services";

export const EulaPage = WithRegion(
  WithCommonTranslations(
    class EulaPage extends AlertMixin(TranslationMixin(React.Component)) {
      constructor(props) {
        super(props);

        this.state = {
          eulaData: null,
        };
      }

      componentDidMount() {
        this.handleStaticContent();
      }

      componentDidUpdate(prevProps) {
        if (prevProps.locale !== this.props.locale) {
          this.handleStaticContent();
        }
      }

      handleStaticContent() {
        const tdseula =
          this.props.regionData.regionCode.toUpperCase() === "AU"
            ? "tdseulaau"
            : "tdseula";
        TermsService.getTerms(tdseula, this.props.locale).then((data) => {
          this.setState({
            eulaData: data.content,
          });
        }).catch((e) => {
          //Adding empty catch to prevent webpack unhandled exception msg
        });
      }

      render() {
        return (
          <div id="eulaPage">
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.eulaData,
              }}
            ></div>
          </div>
        );
      }
    }
  )
);
