import React from "react";
import { AlertMixin } from "tds_shared_ui";
import { LoadingIcon, Button, OrderInvoiceModal } from "../UI";
import { Table, WithCommonTranslations } from "tds_shared_ui";
import { WithRegion, WithKeyCodeTranslations } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import { HelperUtils, OrderService } from "../../services";

export const MyOrderTable = WithRegion(
  WithCommonTranslations(
    WithKeyCodeTranslations(
      class MyOrderTable extends AlertMixin(TranslationMixin(React.Component)) {
        constructor(props) {
          super(props);
          this.state = {
            loading: false,
            myOrdersData: null,
            showOrderInvoiceModal: false,
            invoiceData: null,
          };

          this.handleViewInvoice = this.handleViewInvoice.bind(this);
          this.handleCancel = this.handleCancel.bind(this);
        }

        componentDidMount() {
          this.setState({ loading: true });
          OrderService.getOrders()
            .then((data) => {
              this.setState({
                loading: false,
                myOrdersData: data,
              });
            })
            .catch((e) => {
              this.displayError(e);
              this.setState({ loading: false });
            });
        }

        handleViewInvoice(orderNumber) {
          return OrderService.getReceipt(orderNumber)
            .then((data) => {
              this.setState(
                {
                  invoiceData: data,
                  showOrderInvoiceModal: true,
                  alert: {
                    display: false,
                  },
                },
                this.scrollToAlert
              );
            })
            .catch((e) =>
              this.displayError(e, () =>
                HelperUtils.scrollToAlert(this.alertRef)
              )
            )
            .finally(() => {
              this.setState({
                loading: false,
              });
            });
        }

        handleCancel() {
          this.setState({ showOrderInvoiceModal: false });
        }

        render() {
          const { myOrdersData } = this.state;

          const cols = [
            {
              header: this.getTranslation("LABEL_ORDER_NUMBER"),
              accessor: "orderNumber",
            },
            {
              header: this.getTranslation("LABEL_ORDER_TYPE"),
              accessor: "orderType",
            },
            {
              header: this.getTranslation("LABEL_ORDER_DATE"),
              accessor: "orderDate",
            },
            {
              header: this.getTranslation("LABEL_ORDER_TOTAL"),
              accessor: "grandTotal",
              render: (rowData) => {
                if (rowData.grandTotalAfterRefunds) {
                  return (
                    <React.Fragment>
                      <s>{rowData.grandTotal}</s>{" "}
                      {rowData.grandTotalAfterRefunds}
                    </React.Fragment>
                  );
                } else {
                  return rowData.grandTotal;
                }
              },
            },
            {
              header: this.getTranslation("LABEL_ACTIONS"),
              accessor: "action",
              render: (rowData) => {
                return (
                  <Button
                    variant="link"
                    onClick={() => this.handleViewInvoice(rowData.orderNumber)}
                  >
                    {this.getTranslation("BUTTON_VIEW_INVOICE")}
                  </Button>
                );
              },
            },
          ];

          return (
            <div>
              {this.state.loading ? (
                <LoadingIcon display={this.state.loading} />
              ) : (
                <Table
                  columns={cols}
                  rows={myOrdersData}
                  filterId="myOrdersFilter"
                  tableMessage={this.getTranslation("MESSAGE_MY_ORDERS_TABLE")}
                />
              )}

              {this.state.showOrderInvoiceModal && (
                <OrderInvoiceModal
                  orderDetails={this.state.invoiceData}
                  open={this.state.showOrderInvoiceModal}
                  onClose={this.handleCancel}
                />
              )}
            </div>
          );
        }
      }
    )
  )
);
