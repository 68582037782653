import React from "react";
import { LoginForm } from "../components/Login/LoginForm";

import "./LoginPage.css";

export class LoginPage extends React.Component {
  render() {
    return (
      <div className="loginPage">
        <LoginForm locale={this.props.locale} />
      </div>
    );
  }
}
