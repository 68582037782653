import React from "react";
import { RegionConsumer } from "../../context";

export const WithRegion = (WrappedComponent) => {
  class WithRegion extends React.Component {
    render() {
      return (
        <RegionConsumer>
          {({ regionData, countries, countryStateMap, languageOptions }) => (
            <WrappedComponent
              {...this.props}
              regionData={regionData}
              countries={countries}
              countryStateMap={countryStateMap}
              languageOptions={languageOptions}
            />
          )}
        </RegionConsumer>
      );
    }
  }

  WithRegion.displayName = `WithRegion(${getDisplayName(WrappedComponent)})`;

  return WithRegion;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}
