import React from "react";

import { Button, Header, Alert } from "../UI";
import { CartItem } from "./CartItem";
import { TranslationMixin } from "tds_shared_ui";
import { WithCommonTranslations, WithUser, WithRegion } from "../hocs";
import { EditableCartItem } from "./EditableCartItem";
import { AlertMixin, withRouter } from "tds_shared_ui";

import { EulaUI } from "../UI";
import { TermsService } from "../../services";

import "./ShoppingCart.css";

export const CartList = WithRegion(
  withRouter(
    WithUser(
      WithCommonTranslations(
        class CartList extends AlertMixin(TranslationMixin(React.Component)) {
          constructor(props) {
            super(props);
            this.state = {
              showTerms: false,
              showUpdate: {},
              alert: {
                display: false,
                type: "default",
                message: "",
              },
            };

            this.handleClick = this.handleClick.bind(this);
            this.handleSave = this.handleSave.bind(this);
            this.handleRemove = this.handleRemove.bind(this);
            this.handleCheckout = this.handleCheckout.bind(this);
            this.handleUpdate = this.handleUpdate.bind(this);
            this.handleContinueShopping =
              this.handleContinueShopping.bind(this);
            this.showTerms = this.showTerms.bind(this);
            this.hideTerms = this.hideTerms.bind(this);
          }

          componentDidMount() {
            this.handleStaticContent();
          }

          componentDidUpdate(prevProps) {
            if (prevProps.locale !== this.props.locale) {
              this.handleStaticContent();
            }
          }

          handleClick(link, newWindow) {
            if (newWindow) {
              window.open(link, "_blank");
            } else {
              this.props.navigate(link);
              this.props.setCartDisplay(false);
            }
          }

          handleSave(item) {
            this.clearError();
            return this.props
              .onSave(item)
              .then(() => {
                this.setState({ showUpdate: {} });
              })
              .catch((e) => {
                this.displayError(e);
                throw e;
              });
          }

          handleUpdate(item) {
            this.setState((prevState) => {
              let showUpdate = { ...prevState.showUpdate };
              showUpdate[item.skuid] = true;
              return { showUpdate: showUpdate };
            });
            this.props.onUpdate(item);
          }

          handleRemove(skuid) {
            this.clearError();
            return this.props.onRemove(skuid).catch((e) => {
              this.displayError(e);
            });
          }

          handleCheckout() {
            this.props.navigate("/checkout");
            this.props.setCartDisplay(false);
          }

          handleContinueShopping() {
            this.props.navigate("/subscriptions");
          }

          showTerms() {
            this.setState(
              {
                showTerms: true,
              },
              this.props.onShowTerms
            );
          }

          hideTerms() {
            this.setState({
              showTerms: false,
            });
            if (this.props.onHideTerms) {
              this.props.onHideTerms();
            }
          }

          handleStaticContent() {
            const tdseula =
              this.props.regionData.regionCode.toUpperCase() === "AU"
                ? "tdseulaau"
                : "tdseula";
            TermsService.getTerms(tdseula, this.props.locale).then((data) => {
              this.setState({
                termsData: data.content,
              });
            }).catch((e) => {
              //Adding empty catch to prevent webpack unhandled exception msg
            });
          }

          render() {
            let cart = this.props.readOnly
              ? this.props.items.slice(0, 3)
              : this.props.items;
            let RenderItem = this.props.readOnly ? CartItem : EditableCartItem;

            return (
              <div className="cart-list">
                {this.state.alert.display && (
                  <Alert
                    ref={this.alertRef}
                    type={this.state.alert.type}
                    className="mt-3"
                  >
                    {this.state.alert.message}
                  </Alert>
                )}

                {this.props.readOnly && (
                  <Header level={2}>
                    {this.getTranslation("HEADER_CART")}
                  </Header>
                )}

                {cart.length > 0 ? (
                  cart.map((cartItem, i) => (
                    <RenderItem
                      item={cartItem}
                      key={i}
                      showUpdate={this.state.showUpdate[cartItem.skuid]}
                      onRemove={this.handleRemove}
                      onUpdate={this.handleUpdate}
                      onSave={() => {
                        return this.handleSave(cartItem);
                      }}
                    />
                  ))
                ) : (
                  <Header level={3} className="mt-2 mb-2">
                    {this.getTranslation("MESSAGES_YOUR_CART_IS_EMPTY")}
                  </Header>
                )}
                <div className="subtotal-wrapper">
                  {cart.length > 0 && (
                    <div className="cart-subtotal">
                      {this.props.readOnly ? (
                        <div className="row font-weight-bold">
                          <div className="col-6">
                            {this.getTranslation("LABEL_SUBTOTAL")}
                          </div>
                          <div className="col-6 text-right">
                            {this.props.cartSubTotal}
                          </div>
                        </div>
                      ) : (
                        <div className="row font-weight-bold">
                          <div className="col-12 text-right">
                            {this.getTranslation("LABEL_SUBTOTAL")}{" "}
                            {this.props.cartSubTotal}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {this.props.readOnly && this.props.cartQuantity > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <Button
                          className="p-0"
                          onClick={() => this.handleClick("/shoppingcart")}
                          type="button"
                          variant="link"
                        >
                          {this.getTranslation("BUTTON_EDIT_CART")} (
                          {this.props.cartQuantity})
                        </Button>
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  {this.props.regionData.regionCode.toUpperCase() === "AU" ? (
                    <p className="fa-sm mt-2 mb-2">
                      <i>
                        {this.getTranslation("CURRENCY_DISPLAY") +
                          " and exclude gst"}
                      </i>
                    </p>
                  ) : (
                    <p className="fa-sm mt-2 mb-2">
                      <i>{this.getTranslation("CURRENCY_DISPLAY")}</i>
                    </p>
                  )}

                  {this.props.readOnly ? (
                    <Button
                      className="w-100"
                      type="button"
                      variant="primary"
                      disabled={!this.props.cartQuantity}
                      onClick={this.showTerms}
                    >
                      {this.getTranslation("BUTTON_CHECKOUT")}
                    </Button>
                  ) : (
                    <div>
                      {cart.length > 0 && (
                        <Button
                          type="button"
                          className="pl-0"
                          onClick={this.props.onEmptyCart}
                          variant="link"
                        >
                          {this.getTranslation("BUTTON_EMPTY_CART")}
                        </Button>
                      )}

                      <div className="float-right">
                        <Button
                          type="button"
                          variant="outline-secondary"
                          onClick={this.handleContinueShopping}
                        >
                          {this.getTranslation("BUTTON_CONTINUE_SHOPPING")}
                        </Button>

                        <Button
                          type="button"
                          variant="primary"
                          disabled={!this.props.cartQuantity}
                          onClick={this.showTerms}
                        >
                          {this.getTranslation("BUTTON_CHECKOUT")}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>

                <EulaUI
                  onSuccess={this.handleCheckout}
                  open={this.state.showTerms}
                  onCancel={this.hideTerms}
                  text={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.termsData,
                      }}
                    ></div>
                  }
                />
              </div>
            );
          }
        }
      )
    )
  )
);
