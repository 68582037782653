import React from "react";

import {
    Header,
    Button,
    FormInput,
    LoadingIcon,
} from "../UI";
import { WithCommonTranslations } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import { WithFormValidation } from "../hocs";

export const RPOLookupForm = WithFormValidation(
    WithCommonTranslations(
        class extends TranslationMixin(React.Component) {
            constructor(props) {
                super(props);

                this.alertRef = React.createRef();

                this.state = {
                    loading: false,
                    states: [],
                };

                this.handleInputChange = this.handleInputChange.bind(this);
            }


            handleSelectChange = (name) => (value) => {
                this.props.onChange(name, value);
            };

            handleInputChange = (event) => {
                const target = event.target;
                const value = target.value;
                const name = target.name;

                this.props.onChange(name, value);
            };

            render() {
                return (
                    <div className="row SearchRow">
                        <div className="col-auto">
                        <FormInput
                            label={this.getTranslation("LABEL_VIN")}
                            name="vin"
                            id="vin"
                            maxLength={17}
                            required={true}
                            displayError={this.props.isValidated}
                            data-pattern-message={this.getTranslation(
                                "MESSAGE_INVALID_VIN"
                            )}
                            autoComplete="off"
                            pattern="^[a-zA-Z0-9]{17}$"
                            value={this.props.formValues.vin}
                            onChange={this.handleInputChange}
                        />
                        </div>

                        <div className="col-auto">
                        <Button
                            variant="primary"
                            type="submit"
                            className="float-right"
                            disabled={this.props.loading || this.state.loading || this.props.formValues.vin.length !== 17}
                        >
                            <LoadingIcon
                                display={this.props.loading || this.state.loading}
                            />
                            {this.getTranslation("BUTTON_FIND_RPO")}
                        </Button>
                        </div>
                    </div>
                );
            }
        }
    )
);
