import React from "react";

import {
  KeycodesInformation,
  PurchaseKeycodes,
  PurchaseKeycodesWizard,
} from "../components/Keycodes";

import { Header } from "../components/UI";
import { TranslationMixin } from "tds_shared_ui";
import {
  WithCommonTranslations,
  WithKeyCodeTranslations,
  WithUser,
} from "../components/hocs";

export const KeycodePage = WithUser(
  WithKeyCodeTranslations(
    WithCommonTranslations(
      TranslationMixin(
        class extends React.Component {
          constructor(props) {
            super(props);

            this.state = {
              showWizard: false,
            };

            this.handleVerificationSuccess =
              this.handleVerificationSuccess.bind(this);
          }

          componentDidUpdate(prevProps) {
            if (prevProps.user !== this.props.user) {
              if (this.props.user == null) {
                this.setState({
                  showWizard: false,
                });
              }
            }
          }

          handleCancel = () => {
            this.setState({
              showWizard: false,
            });
          };

          handleVerificationSuccess() {
            this.setState({
              showWizard: true,
            });
          }

          render() {
            return (
              <div className="text-gray">
                {!this.state.showWizard && (
                  <div>
                    <Header level={1}>
                      {this.getTranslation("HEADER_KEYCODES")}
                    </Header>
                    {this.props.user && this.props.user.locksmithID ? (
                      <PurchaseKeycodes
                        locale={this.props.locale}
                        onVerificationSuccess={this.handleVerificationSuccess}
                      />
                    ) : (
                      <KeycodesInformation user={this.props.user} />
                    )}
                  </div>
                )}

                {this.state.showWizard && (
                  <PurchaseKeycodesWizard onCancel={this.handleCancel} />
                )}
              </div>
            );
          }
        }
      )
    )
  )
);
