import React from 'react';
import PropTypes from 'prop-types';
import './Header.css';

export class Header extends React.Component {

  render() {
    const {level, children, className, ...otherProps} = this.props;
    const CustomTag = `h${level}`;
    let cssClassName = className ? className + ' ' : '';

    cssClassName += `tds_h${level}`;

    return <CustomTag
        className={cssClassName} {...otherProps}>{children}</CustomTag>;
  }
}

Header.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4]).isRequired,
};