import React from "react";

import { Alert } from "../UI";
import { WithKeyCodeTranslations, WithCommonTranslations } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import { HelperUtils } from "tds_shared_ui";
import { BillingAndPaymentForm } from "./BillingAndPaymentForm";

export const BillingAndPayment = WithCommonTranslations(
  WithKeyCodeTranslations(
    class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);
        this.alertRef = React.createRef();

        this.state = {
          loading: false,
          validationErrors: false,
          alert: {
            display: false,
            type: "default",
            message: "",
          },
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onValidationError = this.onValidationError.bind(this);
        this.handlePromoCode = this.handlePromoCode.bind(this);
      }

      scrollToAlert() {
        HelperUtils.scrollToAlert(this.alertRef);
      }

      onValidationError() {
        this.setState({
          validationErrors: true,
        });
        this.showFormError();
      }

      handleSubmit() {
        this.setState({
          validationErrors: false,
          loading: true,
          alert: {
            display: false,
          },
        });

        if (!this.state.validationErrors) {
          this.props
            .onPrepareCheckout()
            .then(this.props.onSubmit)
            .catch((error) => {
              this.setState({
                loading: false,
              });
              this.showError(error);
            });
        } else {
          this.setState({
            loading: false,
          });
        }
      }

      showError(error) {
        const errorMsg = error.translatedMessage || error.internalMessage;
        let promoError = "";
        if (error.category === "PROMOCODE") {
          promoError = errorMsg;

          this.setState({
            promoError: promoError,
          });
          this.onValidationError();
        } else {
          this.setState(
            {
              promoError: "",
              alert: {
                display: true,
                message: errorMsg,
                type: "error",
              },
            },
            this.scrollToAlert
          );
        }
      }

      handlePromoCode() {
        this.setState({
          promoError: "",
          alert: {
            display: false,
          },
        });
        return this.props.onPrepareCheckout().catch((error) => {
          this.showError(error);
        });
      }

      showFormError() {
        this.setState(
          {
            alert: {
              display: true,
              message: this.getTranslation("ERROR_FORM_VALIDATION"),
              type: "error",
            },
          },
          this.scrollToAlert
        );
      }

      render() {
        return (
          <div>
            {this.state.alert.display && (
              <Alert ref={this.alertRef} type={this.state.alert.type}>
                {this.state.alert.message}
              </Alert>
            )}
            <BillingAndPaymentForm
              submit={this.handleSubmit}
              loading={this.state.loading}
              promoError={this.state.promoError}
              onValidationError={this.onValidationError}
              onApplyPromoCode={this.handlePromoCode}
              onCancel={this.props.onCancel}
              {...this.props}
            />
          </div>
        );
      }
    }
  )
);
