import React from "react";
import {
  Header,
  Alert,
  Button,
  OrderSummary,
  PaymentMethod,
  CountDownTimer,
  OrderInvoiceModal,
} from "../UI";
import { WithKeyCodeTranslations, WithCommonTranslations } from "../hocs";
import { HelperUtils } from "tds_shared_ui";
import { TranslationMixin } from "tds_shared_ui";
import KeycodesService from "../../services/KeycodesService";
import { LoadingIcon } from "../UI";
import { ViewKeycode } from "./ViewKeycode";

export const ActivateKeycode = WithCommonTranslations(
  WithKeyCodeTranslations(
    class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);

        this.timerAlertRef = React.createRef();
        this.alertRef = React.createRef();

        this.state = {
          loading: false,
          showInvoice: false,
          keycodeData: null,
          alert: {
            display: true,
            type: "warning",
            message: this.getTranslation("WARNING_ACTIVATE_KEYCODES_PAGE"),
          },
          showKeycodes: false,
          timerExpired: false,
        };
        this.activateKeycodeButton = this.activateKeycodeButton.bind(this);
        this.handlePrintInvoice = this.handlePrintInvoice.bind(this);
        this.handleCloseInvoice = this.handleCloseInvoice.bind(this);
        this.handleTimeExpired = this.handleTimeExpired.bind(this);
      }

      scrollToAlert() {
        HelperUtils.scrollToAlert(this.timerAlertRef);
      }

      handleTimeExpired() {
        this.setState({
          showKeycodes: false,
          timerExpired: true,
          alert: {
            display: true,
            type: "warning",
            message: this.getTranslation("WARNING_KEYCODES_TIMER_EXPIRED"),
          },
        });
      }

      activateKeycodeButton() {
        this.setState({
          loading: true,
        });
        KeycodesService.getKeycodes(
          this.props.orderConfirmation.keycodeRecordId, Intl.DateTimeFormat().resolvedOptions().timeZone
        )
          .then((data) => {
            this.setState(
              {
                keycodeData: data,
                showKeycodes: true,
                alert: {
                  display: false,
                },
              },
              this.scrollToAlert
            );
          })
          .catch((error) => {
            this.setState(
              {
                alert: {
                  display: true,
                  type: "error",
                  message: error.translatedMessage,
                },
              },
              () => HelperUtils.scrollToAlert(this.alertRef)
            );
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      }

      handlePrintInvoice() {
        this.setState({
          showInvoice: true,
        });
      }

      handleCloseInvoice() {
        this.setState({
          showInvoice: false,
        });
      }

      render() {
        const { orderConfirmation } = this.props;
        const { keycodeData } = this.state;
        return (
          <div className="keycode-activate">
            {this.state.alert.display && (
              <Alert ref={this.alertRef} type={this.state.alert.type}>
                {this.state.alert.message}
              </Alert>
            )}

            <div className="row mb-5">
              {/*Confirmation*/}
              <div className="col">
                <div className="border-bottom mb-3">
                  <div className="row">
                    <Header level={2} className="col">
                      {this.getTranslation("HEADER_CONFIRMATION")}
                    </Header>
                    <Button
                      variant="link"
                      className="pb-0 pt-0"
                      onClick={this.handlePrintInvoice}
                    >
                      {this.getTranslation("LINK_PRINT_RECEIPT")}
                    </Button>
                  </div>
                </div>

                <div className="divIcon">
                  <div className="mb-2">
                    <div className="sideIcon">
                      <i className="fas fa-check fa-pull-left text-muted"></i>
                    </div>

                    <div>
                      {this.getTranslation("MESSAGE_THANKS_FOR_ORDER1")}{" "}
                      {orderConfirmation.receipt.orderNumber}
                    </div>
                  </div>

                  <div className="">
                    <OrderSummary
                      className="basic"
                      receipt={orderConfirmation.receipt}
                    />
                  </div>

                  {/*Payment*/}
                  <div className="mb-5">
                    <PaymentMethod
                      cardBrand={orderConfirmation.receipt.cardBrand}
                      cardLast4Digits={
                        orderConfirmation.receipt.cardLast4Digits
                      }
                      cardHolderName={orderConfirmation.receipt.cardHolderName}
                    />
                  </div>
                </div>

                <div className="float-right">
                  <Button variant="link" onClick={this.props.onCancel}>
                    {this.getTranslation("BUTTON_GO_TO_KEYCODES")}
                  </Button>
                  {!this.state.timerExpired && (
                    <Button
                      variant="primary"
                      disabled={this.state.loading || this.state.showKeycodes}
                      onClick={this.activateKeycodeButton}
                    >
                      <LoadingIcon display={this.state.loading} />
                      {this.getTranslation("BUTTON_ACTIVATE_KEYCODE")}
                    </Button>
                  )}
                </div>
              </div>
            </div>

            {this.state.showKeycodes && (
              <div className="row">
                <div className="col">
                  <Alert
                    type="warning"
                    className="countdown-message"
                    ref={this.timerAlertRef}
                  >
                    <div className="overflow-hidden">
                      {this.getTranslation("WARNING_KEYCODES_TIMER_RUNNING")}
                      <b>
                        {this.getTranslation("LABEL_TIME_REMAINING")}&nbsp;
                        <CountDownTimer
                          secondsRemaining="60"
                          onTimeExpired={this.handleTimeExpired}
                        />
                      </b>
                    </div>
                  </Alert>

                  <div className="border-bottom mb-3">
                    <div className="row">
                      <Header level={2} className="col-8">
                        {this.getTranslation("HEADER_ACTIVATE_KEYCODE")}
                      </Header>
                    </div>
                  </div>

                  <ViewKeycode dataKeycode={keycodeData} />
                </div>
              </div>
            )}
            <OrderInvoiceModal
              orderDetails={this.props.orderConfirmation.receipt}
              open={this.state.showInvoice}
              onClose={this.handleCloseInvoice}
            />
          </div>
        );
      }
    }
  )
);
