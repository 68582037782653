import React from 'react';
import StaticImageService from "../services/StaticImageService";

export class HowToBuyPage extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      base64Image: null,
      howtobuyimage: "howtobuyimage",
    };
  }

  componentDidMount() {
    StaticImageService.getStaticImage(this.state.howtobuyimage, this.props.locale)
      .then((response) => {
        this.setState({ base64Image: response.content[0] });
      })
      .catch((error) => {
        console.error('Error fetching image:', error);
      });
  }

  render() {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%"}}>
        {this.state.base64Image && (
          <img src={`data:image/png;base64,${this.state.base64Image}`}
               alt="How to Buy"
               style={{ width: '90%', height: '90%', objectFit: 'contain'}} />
        )}
      </div>
    );
  }
}
