import React from "react";

import {
  Header,
  Button,
  OrderTotal,
  PaymentInputFields,
  LoadingIcon,
  Captcha,
} from "../UI";
import { TranslationMixin } from "tds_shared_ui";
import {
  WithKeyCodeTranslations,
  WithCommonTranslations,
  WithFormValidation,
} from "../hocs";

export const PlaceOrderForm = WithKeyCodeTranslations(
  WithCommonTranslations(
    WithFormValidation(
      class extends TranslationMixin(React.Component) {
        constructor(props) {
          super(props);
          this.state = {
            loading: false,
            values: [],
          };
        }

        render() {
          return (
            <div className="keycode-confirmation row">
              <div className="col">
                <div className="mb-3">
                  <div className="border-bottom mb-3">
                    <Header level={2}>
                      {this.getTranslation("HEADER_ORDER_SUMMARY")}
                    </Header>
                  </div>
                  <div className="">
                    <OrderTotal
                      className="basic"
                      checkoutSummary={this.props.checkoutSummary}
                    />
                  </div>
                </div>

                {/*Payment*/}
                {this.props.checkoutSummary.ccRequired && (
                  <div className="row">
                    <div className="col-sm-6 mb-5">
                      <div className="border-bottom mb-3">
                        <Header level={2}>
                          {this.getTranslation("HEADER_PAYMENT")}
                        </Header>
                      </div>
                      <PaymentInputFields
                        id="keycodes-payment"
                        grandTotalAmount={
                          this.props.checkoutSummary.totalAmount
                        }
                        currencyCode={this.props.checkoutSummary.currencyCode}
                        onInit={this.props.onPaymentInit}
                        onPaymentSuccess={this.props.onPaymentSuccess}
                        onPaymentError={this.props.onPaymentError}
                      />
                    </div>
                  </div>
                )}

                <div className="pb-5">
                  <Captcha captchaRef={this.props.captchaRef} />
                </div>

                <Button
                  variant="outline-secondary"
                  type="button"
                  onClick={this.props.onCancel}
                >
                  {this.getTranslation("BUTTON_CANCEL")}
                </Button>

                <div className="float-right">
                  <Button
                    variant="link"
                    type="button"
                    onClick={this.props.onBack}
                    disabled={this.props.loading}
                  >
                    {this.getTranslation("BUTTON_BACK")}
                  </Button>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={this.props.submit}
                    disabled={this.props.loading}
                  >
                    <LoadingIcon display={this.props.loading} />
                    {this.getTranslation("BUTTON_PLACE_ORDER")}
                  </Button>
                </div>
              </div>
            </div>
          );
        }
      }
    )
  )
);
