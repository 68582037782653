import React from "react";

import { Header, PaymentInputFields } from "../UI";
import { TranslationMixin } from "tds_shared_ui";
import { WithUser, WithCommonTranslations } from "../hocs";

export const PaymentWidget = WithUser(
  WithCommonTranslations(
    class extends TranslationMixin(React.Component) {
      render() {
        return (
          <div className="payment-widget">
            {/*Payment*/}
            <div className="mb-5">
              <div className="border-bottom mb-3">
                <Header level={2}>
                  {this.getTranslation("HEADER_PAYMENT")}
                </Header>
                <PaymentInputFields
                  id="payment"
                  onEnable3DS={this.props.onEnable3DS}
                  onInit={this.props.onPaymentInit}
                  onPaymentSuccess={this.props.onPaymentSuccess}
                  onPaymentError={this.props.onPaymentError}
                />
              </div>
            </div>
          </div>
        );
      }
    }
  )
);
