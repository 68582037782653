
import GenericAjaxService from './GenericAjaxService';
import {publicAPI} from './api';

export default class LMSService extends GenericAjaxService {

  static getData(paymentId) {
    return super.getAPI(publicAPI, `lmscheckout?paymentId=${paymentId}`);
  }

  static prepareCheckout(data){
    return super.postAPI(publicAPI, `lmscheckout/prepareCheckout`, data);
  }

  static placeOrder(data){
    return super.postAPI(publicAPI, `lmscheckout/placeOrder`, data);
  }
}
