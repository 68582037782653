import GenericAjaxService from './GenericAjaxService';
import {privateAPI, publicAPI} from './api';

export default class LinkService extends GenericAjaxService {

  static getSubscriptions(opts) {
    return super.getAPI(publicAPI, `subscriptions`, opts);
  }

  static getAvailableSlotsData(opts) {
    return super.getAPI(privateAPI, `subscriptions/vin`, opts);
  }

  static getMySubscriptionData(opts) {
    return super.getAPI(privateAPI, `subscriptions`, opts);
  }

  static getVinSubscriptionData(id, opts) {
    return super.getAPI(privateAPI, `subscriptions/vin/access/${id}`, opts);
  }

  static getAccessSubscriptionData(id, opts) {
    return super.getAPI(privateAPI, `subscriptions/access/${id}`, opts);
  }

  static setVinHidden(id, value){
    return super.postAPI(privateAPI, `subscriptions/vin/hidden/${id}?value=${value}`);
  }
}