import React from "react";
import {
  WithContactTranslations,
  WithCommonTranslations,
  WithFormValidation,
} from "../hocs";
import { FormInput, Button, Header, LoadingIcon } from "../UI";
import "./ContactUsForm.css";
import { TranslationMixin } from "tds_shared_ui";
import { Captcha } from "../UI";

export const ContactUsForm = WithCommonTranslations(
  WithFormValidation(
    WithContactTranslations(
      class extends TranslationMixin(React.Component) {
        constructor(props) {
          super(props);
          this.handleInputChange = this.handleInputChange.bind(this);
        }

        handleInputChange(event, callback) {
          const target = event.target;
          const value =
            target.type === "checkbox" ? target.checked : target.value;
          const name = target.name;

          const self = this;
          this.props.onChange(name, value, function () {
            self.setState(callback);
          });
        }

        render() {
          const isRegionCN = this.props.regionData.regionCode.toUpperCase() === "CN";

          return (
            <div className="contactus-form pl-2">
              <div className="pt-3">
                <Header level={1}>
                  {this.getTranslation("LABEL_CONTACT_US")}
                </Header>
              </div>
              <div className="d-inline-block">
                <div>
                  <p>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: this.getTranslation("SUBHEADER_CONTACT_US"),
                      }}
                    ></span>
                  </p>
                </div>
              </div>

            {!isRegionCN && (
              <div className="form-inputs">
                <FormInput
                  label={this.getTranslation("LABEL_NAME")}
                  name="contactName"
                  id="contactName"
                  maxLength={100}
                  required={true}
                  displayError={this.props.isValidated}
                  autoComplete="off"
                  value={this.props.formValues.contactName}
                  onChange={this.handleInputChange}
                />

                <FormInput
                  label={this.getTranslation("LABEL_PHONE")}
                  name="contactPhone"
                  id="contactPhone"
                  required={true}
                  type="text"
                  maxLength={15}
                  displayError={this.props.isValidated}
                  autoComplete="off"
                  pattern="\d+"
                  value={this.props.formValues.contactPhone}
                  onChange={this.handleInputChange}
                />

                <FormInput
                  label={this.getTranslation("LABEL_EMAIL")}
                  type="email"
                  name="contactEmail"
                  id="contactEmail"
                  required={true}
                  maxLength={100}
                  displayError={this.props.isValidated}
                  autoComplete="off"
                  value={this.props.formValues.contactEmail}
                  onChange={this.handleInputChange}
                />

                <div className="form-group">
                  <label className="comments-label" htmlFor="comment">
                    {this.getTranslation("LABEL_COMMENTS")}
                  </label>
                  <textarea
                    className="comment form-control"
                    id="comment"
                    name="comment"
                    required={true}
                    maxLength={300}
                    value={this.props.formValues.comment}
                    onChange={this.handleInputChange}
                    cols={60}
                    rows={3}
                  />
                  <div className="invalid-feedback">
                    {this.getTranslation("ERROR_REQUIRED")}
                  </div>
                </div>
              </div>
            )}

            {!isRegionCN && <Captcha captchaRef={this.props.captchaRef} />}

            {!isRegionCN &&(
              <div className="mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  disabled={this.props.loading}
                >
                  <LoadingIcon display={this.props.loading} />
                  {this.getTranslation("BUTTON_SUBMIT")}
                </Button>

                <Button
                  variant="link"
                  type="button"
                  onClick={this.props.onCancel}
                >
                  {this.getTranslation("BUTTON_CANCEL")}
                </Button>
              </div>
            )}

              {isRegionCN ? (
              <div className="d-flex flex-row bd-highlight mb-3">
                  <div className="mt-5 mp-5 bd-highlight">
                      <div className="chinaDelcoInfo">
                          {this.getTranslation("CHINA_CONTACT_NUMBER")}
                      </div>
                      <div className="chinaDelcoInfo">
                          {this.getTranslation("CHINA_EMAIL_ADDRESS")}
                      </div>
                      <div className="chinaDelcoInfo">
                          {this.getTranslation("CHINA_SERVICE_HOURS")}
                      </div>
                  </div>
              </div>
              ) : (
              <div className="d-flex flex-row bd-highlight mb-3">
                <div className="mt-5 mp-5 bd-highlight">
                  <div className="font-weight-bold">
                    {this.getTranslation("LABEL_GET_IN_TOUCH")}
                  </div>
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.getTranslation("SUBHEADER_GET_IN_TOUCH"),
                      }}
                    ></div>

                    <div className="delcoNumber">
                      <div>{this.getTranslation("MESSAGE_ACDELCO_NUMBER")}</div>
                    </div>
                  </div>
                </div>

                <div className="bd-highlight mt-5 mp-5 ml-5 pl-5">
                  <div className="font-weight-bold">
                    {this.getTranslation("LABEL_WRITE_TO_US")}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.getTranslation("SUBHEADER_WRITE_TO_US"),
                    }}
                  ></div>
                </div>
              </div>
              )}
            </div>
          );
        }
      }
    )
  )
);
