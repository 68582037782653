import React from "react";

import {
  Header,
  Button,
  FormInput,
  LoadingIcon,
  Select,
  OrderTotal,
} from "../UI";
import { WithKeyCodeTranslations, WithCommonTranslations } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";
import { WithFormValidation } from "../hocs";

import "./VehicleInformationForm.css";

export const VehicleInformationForm = WithFormValidation(
  WithCommonTranslations(
    WithKeyCodeTranslations(
      class extends TranslationMixin(React.Component) {
        constructor(props) {
          super(props);

          this.alertRef = React.createRef();

          this.state = {
            loading: false,
            states: [],
          };

          this.handleInputChange = this.handleInputChange.bind(this);
          this.handleSelectChange = this.handleSelectChange.bind(this);
        }

        static getDerivedStateFromProps(props, state) {
          let country = props.formValues ? props.formValues.country : null;
          let states = [];

          if (country && props.countryStateMap) {
            states = props.countryStateMap[country];
          }

          return {
            states: states,
          };
        }

        handleSelectChange = (name) => (value) => {
          this.props.onChange(name, value);
        };

        handleInputChange = (event) => {
          const target = event.target;
          const value =
            target.type === "checkbox" ? target.checked : target.value;
          const name = target.name;

          this.props.onChange(name, value);
        };

        render() {
          return (
            <div className="container vehicle-form">
              <div className="row">
                <div className="col-sm-6">
                  <div className="border-bottom mb-3">
                    <div className="row">
                      <Header level={2} className="col-8">
                        {this.getTranslation("HEADER_VEHICLE_INFORMATION")}
                      </Header>
                    </div>
                  </div>

                  <FormInput
                    label={this.getTranslation("LABEL_VIN")}
                    name="vin"
                    id="vin"
                    maxLength={17}
                    required={true}
                    displayError={this.props.isValidated}
                    data-pattern-message={this.getTranslation(
                      "MESSAGE_INVALID_VIN"
                    )}
                    autoComplete="off"
                    pattern="^[a-zA-Z0-9]{17}$"
                    value={this.props.formValues.vin}
                    onChange={this.handleInputChange}
                  />

                  <FormInput
                    label={this.getTranslation("LABEL_OWNER_NAME")}
                    name="vehicleOwnerName"
                    id="vehicleOwnerName"
                    maxLength={200}
                    required={true}
                    displayError={this.props.isValidated}
                    data-pattern-message={this.getTranslation(
                      "MESSAGE_INVALID_NAME"
                    )}
                    autoComplete="off"
                    pattern="^[A-Za-zÀ-ÿ\-' ]{0,200}$"
                    value={this.props.formValues.vehicleOwnerName}
                    onChange={this.handleInputChange}
                  />

                  <FormInput
                    label={this.getTranslation("LABEL_LICENSE_PLATE")}
                    name="licensePlate"
                    id="licensePlate"
                    maxLength={100}
                    required={true}
                    displayError={this.props.isValidated}
                    data-pattern-message={this.getTranslation(
                      "MESSAGE_INVALID_PLATE"
                    )}
                    autoComplete="off"
                    pattern="^[a-zA-Z0-9 -]{0,10}$"
                    value={this.props.formValues.licensePlate}
                    onChange={this.handleInputChange}
                  />

                  <FormInput
                    label={this.getTranslation("LABEL_CITY")}
                    name="city"
                    id="city"
                    maxLength={100}
                    required={true}
                    displayError={this.props.isValidated}
                    data-pattern-message={this.getTranslation(
                      "MESSAGE_INVALID_CITY"
                    )}
                    autoComplete="off"
                    value={this.props.formValues.city}
                    onChange={this.handleInputChange}
                  />

                  <Select
                    label={this.getTranslation("LABEL_STATE")}
                    name="stateRegistration"
                    id="stateRegistration"
                    autoComplete="off"
                    required={true}
                    options={this.state.states}
                    value={this.props.formValues.stateRegistration}
                    readOnly={this.props.readOnly}
                    error={
                      this.props.isValidated &&
                      !this.props.formValues.stateRegistration
                    }
                    errorMessage={this.getTranslation("ERROR_REQUIRED")}
                    onChange={this.handleSelectChange("stateRegistration")}
                  />

                  <Select
                    label={this.getTranslation("LABEL_COUNTRY")}
                    name="country"
                    id="country"
                    autoComplete="off"
                    required={true}
                    options={this.props.countries}
                    value={this.props.formValues.country}
                    readOnly={this.props.readOnly}
                    error={
                      this.props.isValidated && !this.props.formValues.country
                    }
                    errorMessage={this.getTranslation("ERROR_REQUIRED")}
                    onChange={this.handleSelectChange("country")}
                  />
                </div>
                <div className="col-sm-6">
                  {this.props.checkoutSummary && (
                    <OrderTotal
                      className="order-summary sticky-top"
                      checkoutSummary={this.props.checkoutSummary}
                    />
                  )}
                </div>
              </div>
              <div>
                <Button
                  variant="outline-secondary"
                  type="button"
                  onClick={this.props.onCancel}
                >
                  {this.getTranslation("BUTTON_CANCEL")}
                </Button>

                <Button
                  variant="primary"
                  type="submit"
                  className="float-right"
                  disabled={this.props.loading || this.state.loading}
                >
                  <LoadingIcon
                    display={this.props.loading || this.state.loading}
                  />
                  {this.getTranslation("BUTTON_NEXT")}
                </Button>
              </div>
            </div>
          );
        }
      }
    )
  )
);
