import React from "react";

import { Header } from "../components/UI";
import { CheckoutWizard } from "../components/Checkout";
import { TranslationMixin, withRouter } from "tds_shared_ui";
import {
  WithUser,
  WithCommonTranslations,
  WithCheckoutTranslations,
} from "../components/hocs";

export const CheckoutPage = withRouter(
  WithUser(
    WithCommonTranslations(
      WithCheckoutTranslations(
        TranslationMixin(
          class extends React.Component {
            constructor(props) {
              super(props);

              this.state = {
                showCheckoutWizard:
                  this.props.cartQuantity && this.props.cartQuantity !== 0,
                showCheckoutComplete: false,
              };

              this.handleComplete = this.handleComplete.bind(this);
            }

            componentDidUpdate(prevProps) {
              if (prevProps.user !== this.props.user) {
                if (this.props.user == null) {
                  this.setState({
                    showCheckoutWizard: false,
                  });
                }
              }

              if (prevProps.cartQuantity !== this.props.cartQuantity) {
                let showCheckoutWizard = false;
                if (
                  this.props.cartQuantity > 0 ||
                  this.state.showCheckoutComplete
                ) {
                  showCheckoutWizard = true;
                }
                this.setState({
                  showCheckoutWizard: showCheckoutWizard,
                });
              }
            }

            handleCancel = () => {
              this.setState({
                showCheckoutWizard: false,
              });
              this.props.navigate("/subscriptions");
            };

            handleComplete() {
              this.setState({
                showCheckoutComplete: true,
              });
              this.props.emptyCart();
            }

            render() {
              return (
                <div>
                  <Header level={1}>
                    {this.getTranslation("HEADER_CHECKOUT")}
                  </Header>

                  {this.state.showCheckoutWizard ? (
                    <CheckoutWizard
                      onCancel={this.handleCancel}
                      onComplete={this.handleComplete}
                    />
                  ) : (
                    <div>{this.getTranslation("MESSAGE_NO_ITEMS_IN_CART")}</div>
                  )}
                </div>
              );
            }
          }
        )
      )
    )
  )
);
